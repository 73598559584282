import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClickOutsideService {
  public click$: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {
    document.addEventListener('mousedown', event => {
      this.click$.emit(event);
    });
  }
}
