<p-dialog
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [dismissableMask]="true"
  (onHide)="closeDialog()"
  [styleClass]="'border-0 rounded-xl w-[480px]'">
  <ng-template pTemplate="header">
    <div class="text-lg font-semibold text-palette-gray-800">
      {{ 'LANGUAGE_SETTING.LANGUAGE_CURRENCY_SETTING' | translate }}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-col gap-3">
      <!-- TODO: Enable when support change region -->
      <!-- <div>
        <div class="text-palette-gray-700 text-sm font-medium mb-2">
          {{ 'LANGUAGE_SETTING.REGION' | translate }}
        </div>
        <p-dropdown
          [options]="optionRegion"
          [(ngModel)]="selectedRegion"
          [filter]="true"
          [filterPlaceholder]="'LANGUAGE_SETTING.SEARCH_YOUR_REGION' | translate"
          [filterBy]="'label'"
          [styleClass]="'w-full rounded-lg h-11'"
          (onChange)="onChange($event)"
          optionLabel="label">
          <ng-template pTemplate="selectedItem">
            <div class="flex gap-2 items-center" *ngIf="selectedRegion">
              <div class="text-palette-gray-900 font-normal text-base">{{ selectedRegion.label }}</div>
            </div>
          </ng-template>
          <ng-template let-regionItem pTemplate="regionItem">
            <div class="dropdown-list" class="flex items-center justify-between w-full">
              <div class="flex gap-2 items-center text-base font-medium text-gray-900">
                {{ regionItem.label }}
              </div>
              <i class="pi pi-check text-palette-blue-600" *ngIf="selectedRegion.value === regionItem.value"></i>
            </div>
          </ng-template>
        </p-dropdown>
      </div> -->
      <div>
        <div class="text-palette-gray-700 text-sm font-medium mb-2">
          {{ 'LANGUAGE_SETTING.LANGUAGE' | translate }}
        </div>
        <p-dropdown
          [styleClass]="'w-full rounded-lg h-11'"
          [options]="optionLanguage"
          [(ngModel)]="selectedLanguage"
          (onChange)="onChange($event)"
          optionLabel="label">
          <ng-template pTemplate="selectedItem">
            <div class="flex gap-2 items-center" *ngIf="selectedLanguage">
              <img src="{{ selectedLanguage.icon }}" [class]="'text-xl'" />
              <div class="text-palette-gray-900 font-normal text-base">{{ selectedLanguage.label | translate }}</div>
            </div>
          </ng-template>
          <ng-template let-languageItem pTemplate="languageItem">
            <div class="dropdown-list" class="flex items-center justify-between w-full">
              <div class="flex gap-2 items-center text-base font-medium text-gray-900">
                <img [src]="languageItem.icon" />
                <div>{{ languageItem.label | translate }}</div>
              </div>
              <i class="pi pi-check text-palette-blue-600" *ngIf="selectedLanguage.label === languageItem.label"></i>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div>
        <div class="text-palette-gray-700 text-sm font-medium mb-2">
          {{ 'LANGUAGE_SETTING.CURRENCY' | translate }}
        </div>
        <p-dropdown
          [styleClass]="'w-full rounded-lg h-11'"
          [options]="optionCurrency"
          [(ngModel)]="selectedCurrency"
          (onChange)="onChange($event)"
          optionLabel="label">
          <ng-template pTemplate="selectedItem">
            <div class="flex gap-2 items-center" *ngIf="selectedCurrency">
              <div class="text-palette-gray-900 font-normal text-base">{{ selectedCurrency.label | translate }}</div>
            </div>
          </ng-template>
          <ng-template let-currencyItem pTemplate="currencyItem">
            <div class="dropdown-list" class="flex items-center justify-between w-full">
              <div class="flex gap-2 items-center text-base font-medium text-gray-900">
                <div>{{ currencyItem.label | translate }}</div>
              </div>
              <i class="pi pi-check text-palette-blue-600" *ngIf="selectedCurrency.label === currencyItem.label"></i>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="closeDialog()"
      [styleClass]="'text-palette-gray-700 items-center text-base bg-palette-base-white border-palette-gray-300'"
      >{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p-button
    >
    <p-button
      (onClick)="changeData()"
      [disabled]="isEditing"
      [styleClass]="'text-palette-base-white items-center text-base bg-branding-primary-600'">
      {{ 'LANGUAGE_SETTING.APPLY' | translate }}</p-button
    >
  </ng-template>
</p-dialog>

<modal [showDialog]="isShowDialog" (emitOnCloseDialog)="onCloseDialog($event)" [dialogConfig]="dialogConfig">
  <ng-container modal-header>
    <div class="flex justify-center items-center">
      <p>{{ 'COMMON.SOMETHING_ERROR' | translate }}</p>
    </div>
  </ng-container>
  <ng-container modal-content>
    <div class="whitespace-normal">
      {{ 'LANGUAGE_SETTING.ERROR' | translate }}
    </div>
  </ng-container>
  <ng-container modal-footer>
    <div class="flex justify-end items-center">
      <p-button
        styleClass="w-full font-semibold flex justify-center border-none bg-palette-blue-500 hover:bg-palette-blue-600"
        (onClick)="acceptDefaultLang()">
        {{ 'COMMON.BUTTON_ACTION.ACCEPT' | translate }}
      </p-button>
    </div>
  </ng-container>
</modal>
