import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * A custom pipe that transforms an error key into a translated message.
 *
 * @param error The error object containing the error key. If `null` or `undefined`, the function returns `defaultMessage`.
 * @param defaultMessage The default message to display if no translation is found. Defaults to system error.
 * @param errorMap Optional mapping of error codes to error messages for translation fallback (e.g., `new Map([[111, 'ERROR_KEY']])`).
 * @returns A `Promise` resolving to the translated message or a fallback message.
 */

export interface ErrorModel {
  error: { code: number; message: string } | any;
  errorMap?: Map<number | any, string>;
  defaultMessage?: string;
}

const SYSTEM_ERROR = 'COMMON.MESSAGE_SOMETHING_ERROR';

@Pipe({
  name: 'errorPipe'
})
export class ErrorPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform({ error, defaultMessage = SYSTEM_ERROR, errorMap }: ErrorModel): Promise<string> {
    return new Promise(resolve => {
      // Use the error code to translate and return the corresponding message.
      if (!error || !error?.message.startsWith('ERROR.')) {
        const errorMessage = errorMap?.get(error?.code) || defaultMessage;
        this.translateService.get(errorMessage).subscribe(translatedValue => {
          resolve(this.getValidTranslation(errorMessage, translatedValue, defaultMessage));
        });
        return;
      }

      const errorKey = error?.message.split('|')[0];
      this.translateService.get(errorKey).subscribe(translatedValue => {
        const params = this.detectParams(translatedValue);
        // Case with parameters in error message - ERROR.KEY|param1|param2
        if (params.length > 0) {
          const errorParams = error?.message.split('|').slice(1);
          this.checkParam(errorKey, errorParams, params, defaultMessage).then(resolve);
          return;
        }

        // Case without parameters in error message - ERROR.KEY
        this.translateService.get(errorKey).subscribe(translatedValue => {
          resolve(this.getValidTranslation(errorKey, translatedValue, defaultMessage));
        });
      });
    });
  }

  private checkParam(error: string, param: any, detectParams: string[], defaultMessage: string): Promise<string> {
    const paramMap: { [key: string]: string } = {};

    for (let i = 0; i < param.length; i++) {
      paramMap[`${detectParams[i]}`] = param[i];
    }

    return new Promise(resolve => {
      this.translateService.get(error, paramMap).subscribe(translatedValue => {
        resolve(this.getValidTranslation(error, translatedValue, defaultMessage));
      });
    });
  }

  private detectParams(template: string): string[] {
    const regex = /{{\s*(\w+)\s*}}/g;
    const params: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = regex.exec(template)) !== null) {
      params.push(match[1]);
    }
    return params;
  }

  getValidTranslation(originalValue: string, translatedValue: string, defaultMessage: string): string {
    // Case already localized in the JSON file.
    if (originalValue !== translatedValue) {
      return translatedValue;
    }

    const defaultMsgTranslated = this.translateService.instant(defaultMessage);
    const systemError = this.translateService.instant(SYSTEM_ERROR);
    // Use system error message when default message not defined in the JSON file
    return defaultMsgTranslated === defaultMessage ? systemError : defaultMsgTranslated;
  }
}
