import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, retry, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { ApiResponse, Hashtag } from './api.hashtag.models';

export class HashtagApi {
  private apiUrl: string = environment.baseURL;
  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig) { }
  createHashtag(categoryLv1: string, hashTagsCurrently: string[], hashTagsNew: string[]) {
    // Build the query parameters
    const queryParams = {
      category_lv1: categoryLv1,
      hashTagsCurrently: hashTagsCurrently.join(','),
      hashTagsNew: hashTagsNew.join(',')
    };

    // Build the full URL with query parameters
    const fullUrl = `${this.apiUrl}/hashtag/hashtags-action`;

    // Make the HTTP GET request
    return this.httpClient.patch<any>(fullUrl, { params: queryParams }).pipe(
      retry(1),
      map((res: any) => res.data)
    );
  }

  getSuggestHashTag(searchText?: string, limit = 10) {
    const url = this.apiUrl + `/hashtag/hashtags-suggest?categories=Social&subCategory=post&limit=${limit}&hashtag=${searchText ?? ''}`;
    return this.httpClient.get<ApiResponse<Hashtag>>(url).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => res),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
