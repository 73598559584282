import { Component, Input } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { UserProfile } from '@app/lib/api/user-profile/api.user-profile.models';
import { selectMembershipProfiles } from '@app/modules/main/states/loyalty-hub/loyalty-hub.selector';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent {
  @Input() userProfile: UserProfile;
  isPointHistoryTransaction = false;
  membershipList: any[];
  baseUrl = environment.baseURL;
  moduleTypes: string[] = ['', 'shop', 'restaurant', 'hotel', 'tour', 'car', 'flight'];
  activeIndex = 0;
  programId: string;
  visibleDetailRank = false;
  rankDetail: any;
  constructor(
    private store: Store,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.store.select(selectMembershipProfiles).subscribe(res => {
      if (!res) {
        return;
      }
      this.membershipList = res;
      this.getMemberShip(0);
    });
  }

  getPositionMinNumber(arr: any) {
    let min = Math.min.apply(Math, arr);
    return arr.reduce(function (r: any, a: any, i: any) {
      a === min && r.push(i);
      return r;
    }, []);
  }

  allEqual(arr: any) {
    return arr.every((val: any) => {
      return val === arr[0];
    });
  }

  getMaxDiscount(discount: any[]) {
    if (discount) {
      return Math.max.apply(
        null,
        discount.map((value) => {
          return value.discount;
        })
      );
    } else {
      return 0;
    }
  }

  getNeedPoint(earnedPoints: number, point: number) {
    const needPoints = earnedPoints > point ? earnedPoints : point - earnedPoints;

    return Math.floor(needPoints);
  }

  getMaximumDiscount(discount: any[]) {
    if (discount) {
      const maximum_discount = Math.max.apply(
        null,
        discount.map((value) => {
          return value.maximum_discount;
        }));
      const maxDiscountObj = discount.reduce((maxObj, obj) => obj.value > maxObj.value ? obj : maxObj, discount[0]);
      return (maxDiscountObj.maximum_discount)?.toLocaleString('en-US', {
        style: 'currency',
        currency: maxDiscountObj.currency ?? 'USD',
      });
    } else {
      return 0;
    }
  }

  getMemberShip(index: number) {
    if (!this.membershipList[index]) {
      return;
    }
    this.programId = this.membershipList[index].program_id;
  }

  onImageError(event: any) {
    event.target.src = 'assets/images/thumbnail.png';
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  getIcon(item: string) {
    if (!item) {
      return '';
    }
    return `/assets/icons/module-icon/${item.toLowerCase()}.svg`;

  }

  getDiscounts(item: any) {
    if (!item.is_percent) {
      return item
        ? Math.max.apply(null,
          item.map((value: any) => {
            return value.discount;
          }))
        : 0;

    } else {
      return this.getMaximumDiscount(item);
    }

  }

  toggleItem(index: number) {
    this.getMemberShip(index);
    this.activeIndex = index;
  }

  currentPointWidth(rankIndex: number) {
    const currentRangeLength = this.membershipList[this.activeIndex].rank_ranges[rankIndex].point - this.membershipList[this.activeIndex].rank_ranges[rankIndex - 1].point;
    const currentPointData = this.membershipList[this.activeIndex].earned_points - this.membershipList[this.activeIndex].rank_ranges[rankIndex - 1].point;
    return `${(currentPointData / currentRangeLength) * 100}%`;
  }

  getCurrentBenefitByModule(currentModule: string, currentRank: any) {
    const discountsData = currentRank.benefits.find((el: any) => el.module.toLowerCase() === currentModule.toLowerCase())?.discounts;
    return { discount: this.getMaxDiscount(discountsData), maximum_discount: this.getMaximumDiscount(discountsData) };
  }

  getBenefitList(benefit: any[]) {
    let data: any = [];
    benefit.forEach((el: any) => {
      if (this.getDiscounts(el.discounts) > 0) {
        data.push(el);
      }
    });
    return data;
  }

  getRoundPoint(point: number) {
    return Math.ceil(point);
  }
}
