import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CommonActions = createActionGroup({
  source: 'Common',
  events: {
    'On In Tour Guide': props<{ isInTourGuide: boolean }>(),
    'On Handle Current Page': props<{ page: string }>(),
    'On Visible Tour Guide': props<{ visible: boolean }>(),
    'On Completed Tour': emptyProps(),
    'On Unsubscribe Email': props<{ hash: string }>(),
    'On Subscribe Email': props<{ email: string; userId: string }>(),
    'On Get Subscribe Email Status': emptyProps(),
    'On User Status Subscribe': props<{ isSubscribe: boolean }>()
  }
});

export const CommonApiActions = createActionGroup({
  source: 'Common API',
  events: {
    'On Completed Tour Success': emptyProps(),
    'On Completed Tour Fail': props<{ message: string }>(),
    'On Unsubscribe Email Success': emptyProps(),
    'On Unsubscribe Email Fail': props<{ message: string }>()
  }
});
