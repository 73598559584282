import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { FeedsEngineService } from '@app/core/services/feeds-engine.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_ACTION, ADS_CATEGORY } from '@app/lib/api/client/api.client.constant';
import { selectCurrentRegion } from '@app/modules/main/states/users/users.selectors';
import { bannerCode, FILE_EXTENSION, LANGUAGES } from '@app/shared/constant';
import { checkUrl, FILE_TYPE_URL } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

const DEFAULT_AD_BANNERS = {
  sponsored1: {
    id: null,
    imageUrl: '/media/libraries/default/ads_default_sponsored1.png',
    title: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_TITLE',
    description: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_DESCRIPTION',
    destination_url: ''
  },
  sponsored2: {
    id: null,
    imageUrl: '/media/libraries/default/ads_default_sponsored2.png',
    title: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_TITLE',
    description: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_DESCRIPTION',
    destination_url: ''
  }
};

@Component({
  selector: 'ads-banner',
  templateUrl: './ads-banner.component.html',
  styleUrls: ['./ads-banner.component.scss']
})
export class AdsBannerComponent implements OnInit, OnDestroy {
  imgPanel1: string | null = null;
  imgPanel2: string | null = null;
  baseUrl: string = environment.baseURL;
  dataPanel1: any;
  dataPanel2: any;
  dataSponsored1: any;
  dataSponsored2: any;
  isLoadingSponsored = false;
  isLoadingTopRight = false;
  currentYear = new Date().getFullYear();
  companyName = environment.COMPANY_NAME;
  partnerCenter = environment.SOCTRIP_CENTER_URL;
  adsCategory = ADS_CATEGORY;
  languageSub: Subscription;
  @Output() visibleLanguageSetting = new EventEmitter();
  @Input() currentLanguage: string;

  // Event banner properties
  topRightBanner: any[] = [];
  sponsorBanner: any[] = [];
  currentTopRightIndex = 0;
  currentSponsorIndex = 0;
  topRightTimeout: any;
  sponsorTimeout: any;

  constructor(
    private feedsEngineService: FeedsEngineService,
    private analyticsService: AnalyticsService,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private store: Store
  ) { }

  ngOnInit(): void {
    // this.feedsEngineService.adsEnginePanel(0, 1, true).subscribe((res: any) => {
    //   if (res) {
    //     const mediaId = res[0]?.post_info?.media[0]?.id;
    //     this.dataPanel1 = res[0];
    //     if (mediaId === '/media/libraries/default/ads_default_panel1.png') {
    //       this.imgPanel1 = mediaId;
    //     } else if (mediaId) {
    //       this.imgPanel1 = `/storage/files/thumbnail/${mediaId}`;
    //     } else {
    //       this.imgPanel1 = res.length !== 0 ? res[0][0]?.media[0]?.id : null;
    //     }
    //   }
    // });
    // this.feedsEngineService.adsEnginePanel(0, 1, false).subscribe((res: any) => {
    //   const mediaId = res[0]?.post_info?.media[0]?.id;
    //   this.dataPanel2 = res[0];
    //   if (mediaId === '/media/libraries/default/ads_default_panel2.png') {
    //     this.imgPanel2 = mediaId;
    //   } else if (mediaId) {
    //     this.imgPanel2 = `/storage/files/thumbnail/${mediaId}`;
    //   } else {
    //     this.imgPanel2 = res.length !== 0 ? res[0][0]?.media[0]?.id : null;
    //   }
    // });
    // this.isLoadingSponsored = true;
    // this.feedsEngineService.adsEngineSponsored().subscribe({
    //   next: res => {
    //     this.isLoadingSponsored = false;
    //     if (res) {
    //       this.dataSponsored1 =
    //         res[0] && res[0].id
    //           ? { ...res[0], imageUrl: `/storage/files/thumbnail/${res[0].post_info?.media[0]?.id}` }
    //           : DEFAULT_AD_BANNERS.sponsored1;
    //       this.dataSponsored2 =
    //         res[1] && res[1].id
    //           ? { ...res[1], imageUrl: `/storage/files/thumbnail/${res[1].post_info?.media[0]?.id}` }
    //           : DEFAULT_AD_BANNERS.sponsored2;
    //     }
    //   },
    //   error: () => {
    //     this.isLoadingSponsored = false;
    //   }
    // });

    this.languageSub = this.translationService.languageSubject.subscribe(() => {
      this.currentLanguage =
        LANGUAGES.find(el => el.value === this.translateService.currentLang)?.label || 'HEADER.LANGUAGES.EN';
    });

    this.getBanner();
  }

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  handleNavigate(event: any, category: string) {
    event && event.id && this.analyticsService.pushToBuffer(event.id, category, ADS_ACTION.click);
    if (event?.destination_url) {
      window.open(
        `${this.baseUrl}/link-hub/?url=${event.destination_url}&adsId=${event.post_id}&platform=Web&screenSize=${innerWidth}x${innerHeight}&module=soctrip`,
        '_blank'
      );
    }
  }

  onImageLoad(event: any, category: string): void {
    if (event && event.id) {
      this.analyticsService.pushToBuffer(event.id, category, ADS_ACTION.view);
      this.analyticsService.pushToBuffer(event.id, category, ADS_ACTION.distribute);
    }
  }

  showLanguageSetting() {
    this.visibleLanguageSetting.emit(true);
  }

  validUrl(urlString: string) {
    return checkUrl(urlString, FILE_TYPE_URL.web) + FILE_EXTENSION.image;
  }

  // Get event banner
  getBanner() {
    this.isLoadingTopRight = true;
    this.isLoadingSponsored = true;
    this.store.select(selectCurrentRegion).subscribe(el => {
      this.feedsEngineService.getAdsBanner(bannerCode.topRightBanner, el).subscribe((res) => {
        if (res.success && res.data.length) {
          this.topRightBanner = res.data;
          if (res.data.length > 1) this.startIntervalTopRightBanner();
        }
        this.isLoadingTopRight = false;
      }, () => { this.isLoadingTopRight = false });
      this.feedsEngineService.getAdsBanner(bannerCode.sponsorBanner, el).subscribe((res) => {
        if (res.success && res.data.length) {
          this.sponsorBanner = res.data.sort(() => Math.random() - 0.5);          
          if (res.data.length > 2) this.startIntervalSponsor();
        }
        this.isLoadingSponsored = false;
      }, () => { this.isLoadingSponsored = false });
    });
  }

  startIntervalTopRightBanner() {
    // change banner after every 5 seconds
    this.topRightTimeout = setTimeout(() => {
      if (this.currentTopRightIndex + 1 >= this.topRightBanner.length) {
        this.currentTopRightIndex = 0;
      } else {
        this.currentTopRightIndex += 1;
      }
      clearTimeout(this.topRightTimeout);
      this.startIntervalTopRightBanner();
    }, 5000);
  }

  goToSlideTopRightBanner(index: number) {
    if (this.topRightBanner.length < 2) return;
    this.currentTopRightIndex = index;
    clearTimeout(this.topRightTimeout);
    this.startIntervalTopRightBanner();
  }

  startIntervalSponsor() {
    // change banner after every 10 seconds
    this.sponsorTimeout = setTimeout(() => {
      if (this.currentSponsorIndex + 1 >= Math.floor(this.sponsorBanner.length / 2) + (this.sponsorBanner.length % 2)) {
        this.currentSponsorIndex = 0;
      } else {
        this.currentSponsorIndex += 1;
      }
      clearTimeout(this.sponsorTimeout);
      this.startIntervalSponsor();
    }, 10000);
  }

  goToSlideSponsor(index: number) {
    if (this.sponsorBanner.length < 3) return;
    this.currentSponsorIndex = index;
    clearTimeout(this.sponsorTimeout);
    this.startIntervalSponsor();
  }

  currentSponsorImageIndex(isSecondImg = false) {
    return this.currentSponsorIndex * 2 + (isSecondImg ? 1 : 0);
  }

  divideAndRoundUp(value: number) {
    let result = Math.floor(value / 2);
    if (value % 2 !== 0) {
      result += 1;
    }
    return result;
  }
}
