<ng-container *ngIf="membershipList">
  <div class="px-4 py-2">
    <div class="py-2 md:w-[776px] w-[380px] overflow-x-auto flex gap-3" *ngIf="membershipList.length > 1">
      <ng-container *ngFor="let item of membershipList; let i = index">
        <div (click)="toggleItem(i)"
          class="flex min-w-[220px] h-16 py-2 px-3 rounded-lg border-2 hover:bg-palette-gray-200  {{ activeIndex === i ? 'border-palette-blue-600': 'border-palette-text-gray-600' }} gap-2 items-center cursor-pointer">
          <img class="h-10 w-10" [src]="getImgUrl(item?.program_image)" alt="">
          <div>
            <p [pTooltip]="item?.program_name" [tooltipPosition]="'top'" class="text-base font-bold truncate w-[150px]">{{ item?.program_name }}</p>
            <p class="text-sm">{{ 'XU.RANK_TYPE.' + item.rank_type | translate }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngFor="let item of membershipList; let i = index">
    <ng-container *ngIf="activeIndex === i" [ngTemplateOutlet]="membershipData" [ngTemplateOutletContext]="{
      data: item,
      needPoint: getNeedPoint(item.earned_points, item.next_rank?.point)
    }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #membershipData let-data="data" let-needPoint="needPoint">
  <div class="px-4 flex flex-col gap-4 pb-4" [ngClass]="membershipList.length > 1 ? '' : 'pt-4'">
    <!-- badge -->
    <div class="flex gap-3 md:justify-center flex-col md:flex-row items-center">
      <div class="min-w-[220px] relative">
        <img class="w-[220px] h-[340px] rounded-lg object-cover"
          [src]="data?.user_rank?.background ? getImgUrl(data?.user_rank?.background) : 'assets/images/gold.png'"
          alt="background" />
        <div class="absolute flex justify-between flex-col gap-6 inset-0 p-5">
          <div class="flex flex-col gap-3 items-start">
            <img class="h-6" src="assets/images/soctrip-branding/soctrip-logo-full-white.svg">
            <h2 class="text-2xl/10 font-audiowide line-clamp-3 w-[180px] text-white uppercase">{{
              data?.user_rank?.name}}</h2>
          </div>
          <div class="text-sm font-medium text-white truncate uppercase">{{ userProfile.user_object?.full_name}}</div>
        </div>
      </div>
      <div
        class="flex flex-col items-center px-3 pt-6 pb-3 gap-4 rounded-lg h-[340px] w-full bg-cover bg-center bg-no-repeat bg-gradient-to-b from-[#fff]"
        style="--tw-gradient-to: {{ data?.user_rank?.avatar_outline_stroke }} var(--tw-gradient-to-position) !important;">
        <div class="text-center">
          <img class="h-20 w-20 aspect-square" [src]="getImgUrl(data?.user_rank?.image)" (error)="onImageError($event)">
        </div>
        <div class="flex flex-col items-center gap-1">
          <h2 class="text-xl text-black font-semibold"
            [innerHTML]="'XU.TITLE_RANK' | translate: {rank: data?.user_rank?.name ? data?.user_rank?.name :''}"></h2>
          <p class="text-center text-sm">{{ 'XU.DESCRIPTION_RANK' | translate }}</p>
        </div>

        <p-carousel #carousel [value]="getBenefitList(data?.user_rank?.benefits)" [numVisible]="4" [numScroll]="1"
          [showIndicators]="false" class="w-[504px]">
          <ng-template let-product pTemplate="item">
            <div 
              class="h-[118px] w-[108px] flex flex-col items-center bg-white rounded-lg pt-2 px-3 gap-2">
              <div class="flex flex-col gap-1 items-center">
                <img class="h-6 w-6" [src]="getIcon(product?.module)" alt="">
                <div class="font-medium text-xs">{{ 'CHAT.MODULE.' + product?.module.toUpperCase() | translate}}</div>
              </div>
              <div class="flex flex-col gap-0.5 items-center">
                <div class="text-palette-blue-600 whitespace-nowrap font-semibold text-xs">{{ 'XU.GET_DISCOUNT' |
                  translate: { param: getDiscounts(product?.discounts) } }}</div>
                <div *ngIf="getMaximumDiscount(product?.discounts) !== 0 && getMaximumDiscount(product?.discounts) !== -1" class="font-normal text-center text-xs">{{ 'XU.UP_TO' | translate: {param:
                  getMaximumDiscount(product?.discounts)} }}</div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>

    <!-- history -->
    <div
      class="px-6 py-5 flex flex-col gap-3 items-center w-full h-[184px] rounded-xl bg-gradient-to-r from-[#FFF4D7] to-[#DBF5FF]">
      <div class="relative w-full flex justify-center items-center">
        <span class="text-lg font-semibold"
          [innerHTML]="(data.earned_points == 1 ? 'XU.TITLE_POINT_HISTORY' : 'XU.TITLE_POINTS_HISTORY') | translate: { points: getRoundPoint(data.earned_points) }"></span>
        <span (click)="isPointHistoryTransaction = true"
          class="cursor-pointer font-normal text-sm text-palette-blue-600 right-0 absolute">{{
          'XU.POINT_HISTORY' | translate }}</span>
      </div>
      <div class="md:w-[710px] w-[380px] overflow-x-auto h-[67px]">
        <div class="flex items-center w-full h-[61px] pt-3">
          <ng-container *ngFor="let listRank of data.rank_ranges; let i = index">
            <div *ngIf="data.rank_ranges[i-1]" class="h-2 flex-1 min-w-[144px] relative"
              [ngClass]="data?.user_rank.point >= listRank.point ? 'bg-palette-orange-dark-500' : 'bg-white'">
              <div *ngIf="data?.user_rank.point < listRank.point && data?.user_rank.point >= data.rank_ranges[i-1].point"
                class="absolute bg-palette-orange-dark-500 h-2 flex justify-end items-center"
                [ngStyle]="{'width': currentPointWidth(i) }">
                <div class="absolute bottom-5 text-palette-orange-dark-500"
                  [ngClass]="data?.user_rank.point < listRank.point && data?.user_rank.point > data.rank_ranges[i - 1].point ? '-right-3' : '-right-1'">
                  {{getRoundPoint(data.earned_points)}}</div>
                <div *ngIf="data?.user_rank.point < listRank.point && data?.user_rank.point > data.rank_ranges[i-1].point"
                  class="absolute w-4 h-4 bg-palette-orange-dark-500 rounded-full left-0.5"
                  style="box-shadow: rgb(248, 1, 1) 0px 5px 15px;">
                </div>
              </div>
            </div>
            <img class="h-7 w-7 rounded-full bg-white p-1 border"
              [ngClass]="data?.user_rank.point >= listRank.point ? 'border-palette-orange-dark-500' : 'border-white'"
              [src]="getImgUrl(listRank.image)" (error)="onImageError($event)" alt="">
          </ng-container>
        </div>

      </div>
      <div *ngIf="needPoint > 0" class="text-sm font-normal">{{(needPoint == 1 ?
      'XU.DESCRIPTION_POINT_HISTORY' :'XU.DESCRIPTION_POINTS_HISTORY') | translate: {points: needPoint} }}</div>
    </div>

    <!-- list Rank -->
    <div class="md:w-[760px] w-[400px] overflow-x-auto">
      <div class="flex gap-2">
        <div class="flex flex-col gap-1">
          <div *ngFor="let module of moduleTypes" class="w-[26px] rounded-[4px] flex items-center justify-center"
            [ngClass]="module !== '' ? 'bg-palette-gray-50 h-[120px]' : 'h-[190px]'">
            <div class="-rotate-90 font-medium whitespace-nowrap text-xs uppercase">{{ module !== '' ? ('CHAT.MODULE.' +
              module.toUpperCase() | translate) : '' }}
            </div>
          </div>
        </div>
        <ng-container *ngFor="let listRank of data.rank_ranges">
          <div class="flex flex-col min-w-[138px] flex-1 gap-1 border-2"
            [ngClass]="data?.user_rank.id === listRank.id ? 'border-palette-blue-dark-400 rounded-lg' : 'border-transparent'">
            <ng-container *ngFor="let module of moduleTypes; let i = index">
              <ng-container *ngIf="!module">
                <div
                  class="rounded-md flex flex-col items-center justify-center gap-1 px-4 py-3 bg-cover bg-center bg-no-repeat bg-gradient-to-t from-[#fff]"
                  style="--tw-gradient-to: {{listRank.avatar_outline_stroke}} var(--tw-gradient-to-position) !important;">
                  <img class="h-9 w-9" [src]="getImgUrl(listRank?.image)" (error)="onImageError($event)" alt="">
                  <p class="truncate max-w-[120px] font-semibold text-base"
                    [ngStyle]="{'color': listRank.card_text_color}"> {{ listRank?.name }}</p>
                  <p class="font-normal text-xs" [ngStyle]="{'color': listRank.card_text_color}">{{ (listRank?.point == 1 ? 'XU.POINT' : 'XU.POINTS')|
                    translate: { points:listRank?.point} }}</p>
                </div>
                <div class="line-clamp-3 h-[3.6rem]">
                  <div class="font-normal text-sm" [innerHTML]="listRank.description"></div>
                </div>
                <div (click)="rankDetail = listRank; visibleDetailRank = true"
                  class="font-normal w-full text-sm text-palette-blue-600 cursor-pointer hover:underline flex justify-center">
                  {{ 'COMMON.VIEW_MORE' | translate }}
                </div>
              </ng-container>

              <div *ngIf="module" class="flex flex-col items-center justify-center py-10 px-4 gap-1 h-[120px]"
                [ngClass]="moduleTypes.length - 1 !== i ? 'border-b ' : ''">
                <ng-container *ngIf="getCurrentBenefitByModule(module, listRank).discount > 0">
                  <p class="text-sm whitespace-nowrap font-normal">{{ 'XU.GET_DISCOUNT' | translate: {param:
                    getCurrentBenefitByModule(module,
                    listRank).discount ? getCurrentBenefitByModule(module, listRank).discount : 0} }}</p>
                  <p *ngIf="getCurrentBenefitByModule(module,
                    listRank).maximum_discount > 0" class="text-xs font-normal text-center text-palette-gray-500">{{
                    'XU.UP_TO' | translate:
                    {param:getCurrentBenefitByModule(module,
                    listRank).maximum_discount ? getCurrentBenefitByModule(module, listRank).maximum_discount : 0} }}
                  </p>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!membershipList">
  <div class="p-4 flex">
    <p-skeleton width="220px" height="4rem " styleClass="mr-2" />
    <p-skeleton width="220px" height="4rem " styleClass="mr-2" />
  </div>
  <div class="p-4 flex">
    <p-skeleton width="220px" height="340px " styleClass="mr-2" />
    <p-skeleton width="504px" height="340px " styleClass="mr-2" />
  </div>
  <div class="p-2">
    <p-skeleton height="300px " styleClass="w-full mr-2" />
  </div>
  <div class="p-2">
    <p-skeleton height="500px " styleClass="w-full mr-2" />
  </div>
</ng-container>
<p-sidebar [(visible)]="isPointHistoryTransaction" position="right" styleClass="w-[540px]">
  <ng-template pTemplate="header">
    <h2 class="text-gray-900 font-semibold">{{ 'XU.POINT_HISTORY' | translate }}</h2>
  </ng-template>
  <point-history [programId]="programId"></point-history>
</p-sidebar>

<p-dialog *ngIf="visibleDetailRank && rankDetail" [(visible)]="visibleDetailRank" [draggable]="false" [closable]="false"
  [resizable]="false" [style]="{ 'width': '500px', 'heigh': '468px' }" [modal]="true"
  class="detail-rank-dialog relative" [dismissableMask]="true">

  <ng-template pTemplate="header">
    <div
      class="rounded-md flex flex-col items-center justify-center gap-4 px-4 py-3 w-full h-52 bg-cover bg-center bg-no-repeat bg-gradient-to-b from-[#fff]"
      style="--tw-gradient-to: {{rankDetail.avatar_outline_stroke}} var(--tw-gradient-to-position) !important;">
      <img class="h-20 w-20" [src]="getImgUrl(rankDetail?.image)" (error)="onImageError($event)" alt="">
      <p class="font-semibold text-lg" [ngStyle]="{'color': rankDetail.card_text_color}"> {{
        rankDetail?.name }}</p>
    </div>
    <i class="absolute -top-3 -right-3 rounded-full bg-palette-base-white w-7 h-7 sctr-icon-x-close flex justify-center items-center cursor-pointer"
      (click)="visibleDetailRank = false"></i>
  </ng-template>
  <div class="flex flex-col px-6 py-3 gap-3">
    <div class="text-base font-semibold">{{ 'XU.SPECIAL_BENEFITS' | translate }}</div>
    <div>
      <div class="font-normal text-sm" [innerHTML]="rankDetail.description"></div>
    </div>
    <p-button [styleClass]="'flex text-palette-base-black bg-palette-base-white border-palette-gray-300 w-full'"
      label="{{ 'XU.GOT_IT' | translate }}" (onClick)="visibleDetailRank = false">
    </p-button>
  </div>
</p-dialog>