import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiClient } from "@app/lib/api/api-client";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {
  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getLoyaltyInfo(loyalty_id: string) {
    const listRank = localStorage.getItem('loyaltyList');
    if (listRank) {
      const loyaltyArray = JSON.parse(listRank);      
      const loyaltyItem = loyaltyArray.find((item: any) => item.id === loyalty_id);
      return loyaltyItem;
    }
    return;
  }

  getMembershipProfiles(): Observable<any> {
    return this.api.loyaltyAPI.getMembershipProfiles();
  }

  getListRank(): Observable<any> {
    return this.api.loyaltyAPI.getListRank();
  }

  getListRankId(id: string): Observable<any> {
    return this.api.loyaltyAPI.getListRankId(id);
  }

  getPointHistory(program_id: string, pageNum: number): Observable<any> {
    return this.api.loyaltyAPI.getPointHistory(program_id, pageNum);
  }
}