import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MediaService } from '@app/core/services/media.service';
import { FILE_EXTENSION } from '@app/shared/constant';
import Hls from 'hls.js';

@Directive({
  selector: '[appHlsVideo]'
})
export class HlsVideoDirective implements OnInit, OnChanges, OnDestroy {
  @Input('appHlsVideo') src = ''; // Video source URL
  @Input() videoId = '';
  @Output() isLoading = new EventEmitter<boolean>(); // Emit loading state

  private hls: Hls | null = null;
  private previousSrc: string | null = null;
  isFirstFragment = true;
  retryCount = 0;

  constructor(
    private el: ElementRef<HTMLVideoElement>,
    private mediaService: MediaService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src']?.currentValue && changes['src'].currentValue !== this.previousSrc) {
      this.previousSrc = changes['src'].currentValue;
      this.initHls();
    }
  }

  async ngOnInit(): Promise<void> {
    this.initHls();
  }

  private initHls(): void {
    const videoElement = this.el.nativeElement;
    this.isLoading.emit(true);

    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }

    const hlsConfig = {
      fragLoadingMaxRetry: 3,
      fragLoadingRetryDelay: 1000,
      enableWorker: true
    };

    if (Hls.isSupported()) {
      if (this.src.startsWith('blob:')) {
        videoElement.src = this.src;
        return;
      }

      this.mediaService.checkHlsVideo(this.videoId).subscribe(res => {
        if (res.success && res.data) {
          this.hls = new Hls(hlsConfig);
          this.hls.loadSource(this.src);
          this.hls.attachMedia(videoElement);

          this.hls.on(Hls.Events.FRAG_LOADED, () => {
            this.isFirstFragment = false;
          });

          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.isLoading.emit(false);
          });

          this.hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR && this.isFirstFragment) {
              if (this.retryCount <= 3) {
                this.retryFragment(data);
                this.retryCount += 1;
              } else {
                this.fallbackToMp4(videoElement);
              }
              return;
            }

            if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR) {
              this.retryFragment(data);
              return;
            }

            this.fallbackToMp4(videoElement);
          });

          videoElement.addEventListener('play', () => {
            this.isLoading.emit(false);
          });
        } else {
          this.fallbackToMp4(videoElement);
        }
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = this.src;
    } else {
      this.fallbackToMp4(videoElement);
    }
  }

  private fallbackToMp4(videoElement: HTMLVideoElement) {
    this.hls?.stopLoad();
    this.hls?.detachMedia();
    if (this.src.startsWith('blob:')) {
      videoElement.src = this.src;
    } else {
      videoElement.src = this.src + FILE_EXTENSION.video;
    }
    this.isLoading.emit(false);
  }

  private retryFragment(data: any) {
    setTimeout(() => {
      if (this.hls) this.hls.startLoad(data.frag.sn);
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.hls) {
      this.hls.destroy();
    }
  }
}
