import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PixelAnalyticsService } from '@app/core/services/pixel-analytics.service';
import { PixelService } from '@app/core/services/pixel.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { UserService } from '@app/core/services/user.service';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { UserAPI } from '@app/lib/api/user/api.user.model';
import { OTP_REQUIRED_STATUS, SIGNIN_METHOD, USER_STATUS } from '@app/shared';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'thirdparty-sign-up',
  templateUrl: './thirdparty-sign-up.component.html',
  styleUrls: ['./thirdparty-sign-up.component.scss']
})
export class ThirdPartySignUpComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;
  @Input() credentials: UserAPI.SocialUser;
  @Input() isPopupLogin = false;
  @Output() moveToVerificationChanged = new EventEmitter();
  @Output() userInformation = new EventEmitter();
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Output() credentialsChange = new EventEmitter();
  @Output() loginTypeChange = new EventEmitter();

  private destroy$ = new Subject<void>();

  registrationId: string;
  userProfile: string;
  userStatus: string;
  deviceInfo: AuthAPI.DeviceInfo;
  errorMessage = '';

  constructor(
    private socAuthenService: SocialAuthService,
    private readonly userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private readonly authService: AuthService,
    private pixelAnalyticsService: PixelAnalyticsService,
    private pixelService: PixelService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.resetInforInit();
    this.authService.getDeviceInfo().subscribe(data => {
      this.deviceInfo = {
        ...data,
        device_type: 'W'
      };
    });
    this.authService.detectWindows11().subscribe(data => {
      if (data) {
        this.deviceInfo.device = data;
      }
    });
    this.subcribeAuthen();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subcribeAuthen(): void {
    this.socAuthenService.authState.pipe(takeUntil(this.destroy$)).subscribe((user: any) => {
      if (user != undefined && user != null) {
        user = {
          ...user,
          ...this.deviceInfo
        };
        this.onLoginSubmit(user);
      }
    });
  }

  facebookSignin() {
    this.socAuthenService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  onLoginSubmit(user: any): void {
    this.setLoadingState(true);
    const credentials = {
      ...user,
      language: this.translateService.currentLang,
      authenticator_otp: '',
      ...this.deviceInfo,
      ip: this.deviceInfo.device_id
    };
    this.credentialsChange.emit(credentials);
    this.userService.thirdPartySignUp(credentials).subscribe({
      next: response => {
        if (response.success) {
          this.userStatus = response.data?.user?.status;
          this.userProfile = JSON.stringify(response.data.user);

          localStorage.setItem(environment.TOKEN_KEY, JSON.stringify({ accessToken: response.data.access_token }));
          localStorage.setItem(
            environment.REFRESH_TOKEN_KEY,
            JSON.stringify({ refreshToken: response.data.refresh_token })
          );

          const deviceSignInInfo: AuthAPI.DeviceInfo = {
            device_id: response?.data?.device_id || '',
            device: response?.data?.device || '',
            device_type: response?.data?.device_type || '',
            city: response?.data?.city || '',
            country: response?.data?.country || '',
            city_code: this.deviceInfo?.city_code || '',
            regions: this.deviceInfo?.regions || '',
            country_code: this.deviceInfo?.country_code || '',
            currency: this.deviceInfo?.currency || '',
            language_code: this.deviceInfo?.language_code || ''
          };
          localStorage.setItem('device_sign_in', JSON.stringify(deviceSignInInfo || {}));
          localStorage.setItem('signin_method', SIGNIN_METHOD.GOOGLE);
          localStorage.setItem('requires_guidance', response.data.user?.requires_guidance);
          localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));
          this.loginSuccess();
        } else {
          this.setLoadingState(false);
          if (response?.error?.code === OTP_REQUIRED_STATUS) {
            this.loginTypeChange.emit(SIGNIN_METHOD.GOOGLE);
            this.moveToVerificationChanged.emit(true);
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
          } else {
            this.toastMessageService.handleErrorFromAPI({
              error: response?.error,
              defaultMessage: 'SIGNIN_SIGNUP.ERROR_MESSAGES.FAILED_TO_LOGIN_BY_GG'
            });
          }
        }
      },
      error: error => {
        this.setLoadingState(false);
        localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
        this.toastMessageService.error('SIGNIN_SIGNUP.ERROR_MESSAGES.FAILED_TO_LOGIN_BY_GG');
      }
    });
    this.pixelAnalyticsService.onSignIn('Google');
  }

  loginSuccess() {
    let returnUrl = this.authService.getReturnUrl();
    localStorage.setItem(
      'is_scheduled_deleted',
      JSON.stringify({ is_scheduled_deleted: this.userStatus === USER_STATUS.SCHEDULED_DELETED })
    );
    localStorage.setItem('credentials', JSON.stringify(this.credentials));
    localStorage.setItem(environment.USER_PROFILE, this.userProfile);
    this.pixelService.updatePxId(this.userProfile);
    setTimeout(() => {
      this.setLoadingState(false);
      if (this.isPopupLogin) {
        window.location.reload();
      } else if (returnUrl) {
        window.location.href = returnUrl;
      } else {
        this.router.navigate(['/']);
      }
    }, 2000);
  }

  resetInforInit() {
    this.userStatus = '';
    this.userProfile = '';
  }

  setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.isLoadingChange.emit(this.isLoading);
  }
}
