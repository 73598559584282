import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, retry } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';

export class ReelsApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getReelsItem(id: string) {
    return this.http.get<any>(`${this.apiUrl}/reels/reels/${id}`).pipe(
      retry(1),
      map((res: any) => {
        return res;
      })
    );
  }

  getReelsForYou(pageNum: number, pageSize: number) {
    return this.http
      .get<any>(
        `${this.apiUrl}/feeds-engine/feeds-engine/reels?pageNum=${pageNum}&pageSize=${pageSize}&tabName=FOR_YOU`
      )
      .pipe(
        retry(1),
        map((res: any) => {
          return res;
        })
      );
  }

  getSuggestedReels(pageNum: number, pageSize: number) {
    return this.http
      .get<any>(`${this.apiUrl}/feeds-engine/feeds-engine/suggested-reels?pageNum=${pageNum}&pageSize=${pageSize}`)
      .pipe(
        retry(1),
        map((res: any) => {
          return res;
        })
      );
  }
}
