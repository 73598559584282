import { Injectable } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { TOAST_MESSAGE_LIFETIME_DURATIONS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { ErrorModel, ErrorPipe } from '@app/shared/pipes/error.pipe';
import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';

export interface ToastConfig {
  isTranslate?: boolean;
  params?: any;
  life?: number;
}

interface ErrorObject extends ErrorModel {
  lifeTime?: number;
}
@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  constructor(
    private messageService: MessageService,
    private translationService: TranslationService,
    private errorPipe: ErrorPipe
  ) {}

  addToastMessage(
    severity: string,
    detail: string,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    if (detail) {
      this.messageService.add({
        severity: severity,
        detail: isTranslate ? this.translationService.getTranslation(detail) : detail,
        life: lifeTime
      });
    }
  }

  addToastMessageDev(
    severity: string,
    detail: string,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    if (!environment.production && detail) {
      this.messageService.add({
        severity: severity,
        detail: isTranslate ? this.translationService.getTranslation(detail) : detail,
        life: lifeTime
      });
    }
  }

  addToastMessageApiResponse(
    isSuccess: boolean,
    successDetail: string,
    failureDetail = '',
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    const severity = isSuccess ? TOAST_MESSAGE_SEVERITY_LEVELS.success : TOAST_MESSAGE_SEVERITY_LEVELS.error;
    const detail = isSuccess ? successDetail : failureDetail;
    this.addToastMessage(severity, detail, lifeTime, isTranslate);
  }

  addMessageWithTranslateParams(
    severity: string,
    key: string,
    params: any,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    const detail = this.translationService.getTranslationWithParams(key, params);
    this.addToastMessage(severity, detail, lifeTime, isTranslate);
  }

  success(key: string, config?: ToastConfig): void {
    this.addMessageWithTranslateParams(
      TOAST_MESSAGE_SEVERITY_LEVELS.success,
      key,
      config?.params,
      config?.life ?? TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
      config?.isTranslate ?? true
    );
  }

  error(key: string, config?: ToastConfig): void {
    this.addMessageWithTranslateParams(
      TOAST_MESSAGE_SEVERITY_LEVELS.error,
      key,
      config?.params,
      config?.life ?? TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
      config?.isTranslate ?? true
    );
  }

  info(key: string, config?: ToastConfig): void {
    this.addMessageWithTranslateParams(
      TOAST_MESSAGE_SEVERITY_LEVELS.info,
      key,
      config?.params,
      config?.life ?? TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
      config?.isTranslate ?? true
    );
  }

  warn(key: string, config?: ToastConfig): void {
    this.addMessageWithTranslateParams(
      TOAST_MESSAGE_SEVERITY_LEVELS.warn,
      key,
      config?.params,
      config?.life ?? TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
      config?.isTranslate ?? true
    );
  }

  clearAllToastMessages(key?: string): void {
    this.messageService.clear(key);
  }

  handleErrorFromAPI({
    error,
    defaultMessage,
    errorMap,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium
  }: ErrorObject): void {
    this.errorPipe.transform({ error, defaultMessage, errorMap }).then((errorMessage: string) => {
      this.messageService.add({
        severity: TOAST_MESSAGE_SEVERITY_LEVELS.error,
        detail: errorMessage,
        life: lifeTime
      });
    });
  }
}
