import { Location } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { PostsService } from '@app/core/services/posts.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_CATEGORY } from '@app/lib/api/client/api.client.constant';
import { DetailPostActions } from '@app/modules/main/states/detail-post/detail-post.actions';
import { selectUserProfile } from '@app/modules/main/states/user-profiles/user-profiles.selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import {
  FILE_EXTENSION,
  KEYBOARD_EVENT_CODES,
  MEDIA_TYPE,
  POST_TYPE,
  SKIP_TO_NEXT_PREVIOUS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { DROPDOWN_POST, FILE_TYPE_URL, Media, Post, checkUrl } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Subscription, fromEvent } from 'rxjs';
import { CommentContentComponent } from '../comment-tree/comment-content/comment-content.component';
import { CustomVideoDirective } from '../custom-video/custom-video.directive';
import { CustomVideoService } from '../custom-video/custom-video.service';
import { PostFooterComponent } from '../post/post-footer/post-footer.component';
import { ADS_ACTION } from './../../../lib/api/client/api.client.constant';

@Component({
  selector: 'photo-view',
  templateUrl: './photo-view.component.html',
  styleUrls: ['./photo-view.component.scss']
})
export class PhotoViewComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() activeIndex = 0;
  @Input() post: Post | any;
  @Input() mediaList: Media[] = [];
  @Input() isShowView = false;
  @Input() isPostGroup = false;
  @Input() userPosts: Post[];
  @Input() isDetailPostView = false;
  @Input() isComment = false;
  @Output() isShowViewChange = new EventEmitter<boolean>();
  @Output() deletePost = new EventEmitter();
  @Output() archivePost = new EventEmitter();
  @Output() hidePost = new EventEmitter();
  @ViewChild(PostFooterComponent) postFooterComponent: PostFooterComponent;
  @ViewChild(CommentContentComponent) commentContentComponent: CommentContentComponent;
  @ViewChild('elDialog') elDialog: ElementRef;
  @ViewChild(CustomVideoDirective) videoDirective: CustomVideoDirective;

  userInfo$ = this.store.select(selectUserInfo);
  userProfile$ = this.store.select(selectUserProfile);
  displayFullImage = false;
  isLoading = false;
  MEDIA_TYPE = MEDIA_TYPE;
  FILE_EXTENSION = FILE_EXTENSION;
  FILE_TYPE_URL = FILE_TYPE_URL;
  DROPDOWN_POST = DROPDOWN_POST;
  optionPin: string;
  actionType = {
    next: 'next',
    previous: 'previous'
  };
  baseURL = environment.baseURL;
  loadingDownload = false;
  private backEventSub: Subscription;
  imageData: Post;
  parentPostId: string;

  constructor(
    private store: Store,
    private analyticsService: AnalyticsService,
    private customVideoService: CustomVideoService,
    private postsService: PostsService,
    private location: Location,
    private cdRef: ChangeDetectorRef,
    private toastMessageService: ToastMessageService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.parentPostId = this.post.id;
    if (this.post && this.post.id && !this.isDetailPostView) {
      if (this.isComment) this.post = { ...this.post, user_object: this.post.created_by, posted_date: this.post.created_at };
      if (!this.mediaList.length) {
        this.mediaList = this.getMediaList(this.post);
      }
    }

    if ((this.post.media_urls ? this.post.media_urls.media : this.post.medias).length > 1) {
      if ((this.post.media_urls ? this.post.media_urls.media : this.post.medias)[this.activeIndex].original.startsWith('blob')) {
        this.postsService.getPostById(this.parentPostId).subscribe(res => {
          if (res.success) {
            this.post = res.data;
            this.getMediaInfo();
          }
        })
      } else {
        this.getMediaInfo();
      }
    } else {
      this.isLoading = false;
    }
    if (this.commentContentComponent) {
      this.commentContentComponent.appendTo = this.elDialog;
    }
    if (this.postFooterComponent) {
      this.postFooterComponent.appendTo = this.elDialog;
    }
    this.backEventSub = fromEvent(window, 'popstate').subscribe(() => {
      this.detachVideo();
      if (this.mediaList[this.activeIndex].type === MEDIA_TYPE.video) {
        const componentRef = this.customVideoService.getComponentRef(this.mediaList[this.activeIndex].original);
        componentRef?.location.nativeElement.querySelector('video').play();
        componentRef?.setInput('isPostView', false);
        componentRef?.setInput('isShowScaleIcon', this.mediaList.length === 1 ? true : false);
      }
      this.isShowViewChange.emit(false);
    });
  }

  getMediaInfo() {
    const imageId = (this.post.media_urls ? this.post.media_urls.media : this.post.medias)[this.activeIndex].original;
    this.postsService.getMediaInfo(imageId).subscribe((res) => {
      if (res.success) {
        this.post = { ...res.data.post_object, id: res.data.entity_id, total_reactions: res.data.total_reactions, total_comments: res.data.total_comments, total_shares: res.data.total_shares, list_reactions: res.data.list_reactions };
        this.isLoading = false;
        this.cdRef.detectChanges();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.videoDirective) {
      this.videoDirective.viewContainerRef.element.nativeElement.parentElement
        .querySelector('.sctr-icon-solid-play-circle')
        ?.click();
      const componentRef = this.customVideoService.getComponentRef(this.mediaList[this.activeIndex].original);
      componentRef?.setInput('isShowScaleIcon', false);
    }
  }

  ngAfterViewChecked(): void {
    if (this.isShowView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('p-overflow-hidden');
    }
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('p-overflow-hidden');
    if (this.backEventSub) {
      this.backEventSub.unsubscribe();
    }
  }

  @HostListener('window:keyup', ['$event']) keyUp(e: KeyboardEvent): void {
    switch (e.code) {
      case KEYBOARD_EVENT_CODES.ArrowRight:
        this.changeImage(this.actionType.next);
        break;
      case KEYBOARD_EVENT_CODES.ArrowLeft:
        this.changeImage(this.actionType.previous);
        break;
      case KEYBOARD_EVENT_CODES.Escape:
        this.closePostView();
        break;
      default:
        break;
    }
  }

  getMediaList(post: Post): Media[] {
    if (post.post_pattern === POST_TYPE.normal || post.post_pattern === POST_TYPE.album) {
      return this.getMediaListFromPost(post);
    } else if (post.post_pattern === POST_TYPE.share) {
      return post.parent ? this.getMediaListFromPost(post.parent) : [];
    } else {
      return [];
    }
  }

  getMediaListFromPost(post: Post): Media[] {
    const mediaFromPost = post.media_urls && post.media_urls.media ? post.media_urls.media : [];
    const mediaFromLibraryObject =
      post.library_object && post.library_object.files
        ? post.library_object?.files.map(file => {
          return {
            type: file.type.substring(0, file.type.indexOf('/')).toUpperCase(),
            thumbnail: file.uris.thumbnail,
            original: file.uris.web || file.uris.thumbnail
          };
        })
        : [];
    return mediaFromLibraryObject.length ? mediaFromLibraryObject : mediaFromPost;
  }

  validUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    if (urlString === null || urlString === 'string') return '';
    if (urlString.includes('blob:')) return urlString;
    return checkUrl(this.removeFileTypeDot(urlString), style) + FILE_EXTENSION.image;
  }

  removeFileTypeDot(filename: string) {
    const dotIndex = filename.lastIndexOf('.');
    return dotIndex > 0 ? filename.slice(0, dotIndex) : filename;
  }

  closePostView(isHide = false) {
    if (isHide) {
      this.hidePost.emit({ ...this.post, id: this.parentPostId });
      this.store.dispatch(DetailPostActions.onHidePost({ id: this.post.id }));
    } else {
      this.store.dispatch(DetailPostActions.onReloadComments({ isReload: true }));
    }
    this.location.back();
  }

  detachVideo() {
    if (this.mediaList[this.activeIndex].type === MEDIA_TYPE.video) {
      this.customVideoService.detach(this.videoDirective.viewContainerRef, this.mediaList[this.activeIndex].original);
    }
  }

  changeImage(type: string) {
    const isNext = type === this.actionType.next;
    let newActiveIndex = (this.activeIndex + (isNext ? 1 : -1) + this.mediaList.length) % this.mediaList.length;
    this.detachVideo();
    this.activeIndex = newActiveIndex;
    let url = `/photo?postId=${this.parentPostId}&activeIndex=${this.activeIndex}`;
    if (this.isComment) {
      url = `/photo?commentId=${this.parentPostId}&activeIndex=${this.activeIndex}`;
    }
    this.location.replaceState(url);
    const isValidIndex = this.activeIndex >= 0 && this.activeIndex < this.mediaList.length;
    if (isValidIndex) {
      this.analyticsService.pushToBuffer(
        this.mediaList[this.activeIndex].thumbnail,
        ADS_CATEGORY.image,
        type === SKIP_TO_NEXT_PREVIOUS.next ? ADS_ACTION.skipToNext : ADS_ACTION.skipToPrevious
      );
    }
    this.isLoading = true;
    this.getMediaInfo();
  }

  handleFullScreen(): void {
    this.displayFullImage = !this.displayFullImage;
    if (this.activeIndex >= 0 && this.activeIndex < this.mediaList.length) {
      this.analyticsService.pushToBuffer(
        this.mediaList[this.activeIndex].thumbnail,
        ADS_CATEGORY.image,
        ADS_ACTION.fullScreen
      );
    }
  }

  onDeletePost(event: Post) {
    this.deletePost.emit({ ...event, id: this.parentPostId });
  }

  confirmArchive(event: any) {
    this.archivePost.emit({ ...event, id: this.parentPostId });
  }

  handleDownload() {
    if (this.isComment) {
      if (this.mediaList[this.activeIndex]?.type === MEDIA_TYPE.image) {
        this.downloadContent(
          this.validUrl(this.mediaList[this.activeIndex].original),
          this.mediaList[this.activeIndex].original
        );
        return;
      }
      if (this.mediaList[this.activeIndex]?.type === MEDIA_TYPE.video) {
        this.downloadContent(
          checkUrl(this.mediaList[this.activeIndex].original, FILE_TYPE_URL.streamVideo),
          this.mediaList[this.activeIndex].original
        );
        return;
      }
    }
  }

  async downloadContent(imageSrc: string, fileName: string) {
    if (!this.loadingDownload) {
      try {
        this.loadingDownload = true;
        const image = await fetch(imageSrc);
        const imageBlob = await image.blob();
        const imageURL = URL.createObjectURL(imageBlob);

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileName || 'Downloaded file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(imageURL);
        this.loadingDownload = false;
      } catch (error) {
        this.toastMessageService.addToastMessageDev(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          `Error downloading the image: ${error}`
        );
      }
    } else {
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.warn,
        'LIBRARY.INFO_MESSAGES.WAIT_UNTIL_CURRENT_DOWNLOAD_FINISHES'
      );
    }
  }
}
