import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { CommentsService } from '@app/core/services/comments.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { ADS_ACTION } from '@app/lib/api/client/api.client.constant';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { DetailPostActions } from '@app/modules/main/states/detail-post/detail-post.actions';
import { selectComments } from '@app/modules/main/states/detail-post/detail-post.selectors';
import { selectCommentList } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import { getReportCategories } from '@app/modules/main/states/report/report.actions';
import { selectReportCategories } from '@app/modules/main/states/report/report.selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { OWNER_TYPE, PAGE_NUM_DEFAULT, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { ENTITY_TYPE, Post } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { CommentFormComponent } from '../comment-form/comment-form.component';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements AfterViewInit, AfterViewChecked {
  @Input() postOwnerId: string;
  @Input() totalCommentsAndReplies = 0;
  @Input() totalComments = 0;
  @Input() pageSize = 3;
  @Input() isPostView = false;
  @Input() isPhotoView = false;
  @Input() post: Post;
  @Input() isPostDetail = false;
  @Input() hideCommentBox = false;
  @Input() comments: Comment[] = [];
  @Input() showSwitchProfileOption = false;
  @Input() isAllowComment = true;
  @Input() userInfo: UserInfo;
  @Input() pageReactionInfo = [];
  @Output() totalCommentsAndRepliesChange = new EventEmitter<number>();
  @Output() isShowComment = new EventEmitter<boolean>();
  @Output() createdComment = new EventEmitter();
  @Output() switchProfileEvent = new EventEmitter();
  @ViewChild(CommentFormComponent) commentFormComponent: CommentFormComponent;
  @ViewChild('commentContainer') commentContainer: ElementRef;

  userInfo$ = this.store.select(selectUserInfo);
  reportCategories$ = this.store.pipe(select(selectReportCategories));

  loginUser: UserInfo;
  isFirstExpand = true;
  pageNum = 0;
  isLoading = false;
  language = '';
  isGettingComment = false;
  selectedProfile: any;

  constructor(
    private store: Store,
    private commentsService: CommentsService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService,
    private cdRef: ChangeDetectorRef
  ) {
    this.reportCategories$.pipe(filter(res => !res)).subscribe(() => {
      this.store.dispatch(getReportCategories());
    });
  }

  ngAfterViewChecked(): void {
    this.setHeightForComments();
  }

  ngAfterViewInit(): void {
    if (this.isPostDetail) {
      this.getComment();
      if (!this.isPostView) {
        this.store.select(selectComments).subscribe(data => {
          if (data.postId && data.postId === this.post.id && data.isReload) {
            this.getComment();
            this.store.dispatch(DetailPostActions.onUpdateComments({ data: {} }));
          }
        });
      }
      return;
    }
    this.store.select(selectCommentList).subscribe(res => {
      if (res.length) {
        const commentData = res.find(el => el.post_id === this.post.id)?.comments;
        if (commentData) {
          this.comments = commentData.filter((item: any) => item.created_by || item?.page_object);
        }
      }
      if (!this.isPostView) {
        this.store.select(selectComments).subscribe(data => {
          if (data.postId && data.postId === this.post.id && data.isReload) {
            this.getComment();
            this.store.dispatch(DetailPostActions.onUpdateComments({ data: {} }));
          }
        });
      }
      this.getTotalComments();
    });
  }

  getComment() {
    this.isLoading = true;
    
    const ownerId = this.isSelectedPageProfile() ? this.selectedProfile?.page_id : '';
    this.commentsService.getCommentsByPostId(this.post.id, this.pageNum, this.pageSize, ownerId).subscribe(res => {
      if (res) {
        this.comments = res.filter(item => item.created_by || item.page_object);
        if (this.route.snapshot.queryParams['comment-id']) {
          const commentId = this.route.snapshot.queryParams['comment-id'];
          const index = this.comments.findIndex(item => item.id === commentId);
          if (index !== -1) {
            const commentData = this.comments[index];
            this.comments.splice(index, 1);
            this.comments.unshift(commentData);
          }
        }
        this.getTotalComments();
      }
      this.isLoading = false;
    });
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setHeightForComments();
  }

  getTotalComments(): void {
    // Only calculate the number of comments (excluding replies)
    // if it's a user profile; otherwise, use the value passed from the post-footer.
    if (!this.isSelectedPageProfile()) {
      this.totalComments = this.totalCommentsAndReplies;
      this.comments.forEach(el => {
        this.totalComments -= el.total_replies;
      });
    }
  }

  addComment(data: any) {
    const userProfile = localStorage.getItem('user_profile');
    if (userProfile) {
      const userInfo = JSON.parse(userProfile);
      this.language = userInfo.language;
    }
    let param = {
      object_id: this.post.id,
      object_type: this.post.isReel ? ENTITY_TYPE.reel : this.isPostView ? ENTITY_TYPE.media : ENTITY_TYPE.post,
      parent_id: this.post.id,
      content: data.content,
      message_ranges: data.messageRanges,
      medias: data.medias,
      language: this.language,
      preview_data: data.preview_data,
      owner_id: this.userInfo?.id,
      owner_type: OWNER_TYPE.user
    };

    if (this.isSelectedPageProfile()) {
      param = { ...param, owner_id: this.selectedProfile?.page_id, owner_type: OWNER_TYPE.page };
    }

    this.commentsService.postComment(param).subscribe(res => {
      if (res) {
        this.analyticsService.pushPostAction(this.post, ADS_ACTION.comment);
        if (data.mediaLocal !== '') {
          res.medias[0].id = data.mediaLocal;
        }
        this.comments.unshift(res);
        this.createdComment.emit();
        this.onIncreaseTotal();
      } else {
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'FRIEND.USER_BLOCKED');
      }
    });
  }

  onIncreaseTotal(parent_id: string = '') {
    this.comments = this.comments.map(el => {
      if (el.id === parent_id) {
        el.total_replies += 1;
      }
      return el;
    });
    this.calculateTotalComment(1);
  }

  calculateTotalComment(number: number) {
    this.totalCommentsAndReplies += number;
    if (this.isPostView) {
      this.store.dispatch(
        DetailPostActions.onUpdateComments({
          data: { postId: this.post.id, total_comments: number, isReload: false }
        })
      );
    }
    this.totalCommentsAndRepliesChange.emit(this.totalCommentsAndReplies);
    this.getTotalComments();
  }

  showMoreComments() {
    const container = this.commentContainer.nativeElement;
    const scrollHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const offsetHeight = container.offsetHeight;
    const ownerId = this.isSelectedPageProfile() ? this.selectedProfile?.page_id : '';
    if (scrollTop + offsetHeight + 10 >= scrollHeight) {
      this.isGettingComment = true;
      this.isLoading = true;
      this.commentsService.getCommentsByPostId(this.post.id, this.pageNum, 10, ownerId).subscribe(res => {
        if (res.length) {
          this.isGettingComment = false;
        }
        if (this.isFirstExpand) {
          this.comments = [
            ...this.comments,
            ...res.slice(this.comments.length).filter(item => item.created_by || item?.page_object)
          ];
          this.isFirstExpand = false;
        } else {
          this.comments = [...this.comments, ...res.filter(item => item.created_by || item?.page_object)];
        }
        this.pageNum += 1;
        this.getTotalComments();
        this.isLoading = false;
      });
    }
    this.cdRef.detectChanges();
  }

  deleteComment(id: string) {
    const indexToRemove = this.comments.findIndex(item => {
      return item.id === id;
    });
    if (indexToRemove !== -1) {
      const totalReplies = this.comments[indexToRemove].total_replies;
      this.comments.splice(indexToRemove, 1);
      this.calculateTotalComment(-(1 + totalReplies));
    } else {
      // Comment id deleted is a reply
      this.calculateTotalComment(-1);
    }
    // Hide comments if comments length < 1
    if (this.comments.length < 1 && !this.isPostView) {
      this.isShowComment.emit(false);
    }
  }

  setHeightForComments() {
    if (this.isPostView) {
      const postView = document.getElementById('postView');
      let otherHeight = 0;
      ['p-card-title', 'p-card-content', 'divTopFooter', 'commentForm', 'heightComments'].forEach(key => {
        const el = postView?.getElementsByClassName(key)[0] as HTMLElement;
        if (!el) return;
        if (key !== 'heightComments') {
          otherHeight += el.getBoundingClientRect().height;          
        } else {
          otherHeight += 107; // 45 is padding and margin of comments
          
          el.style.setProperty('height', `calc(100vh - ${otherHeight}px)`);
        }
      });
    }
  }

  focusCommentForm() {
    this.commentFormComponent.onClick();
  }

  isHideComment(commentId: string): boolean {
    let arrHideCommentId = localStorage.getItem('arrHideCommentId');
    let newArrHideCommentId = JSON.parse(arrHideCommentId || '[]');
    return newArrHideCommentId.includes(commentId);
  }

  isSelectedPageProfile(): boolean {
    // Check if the selected profile is a page profile.
    return !!this.selectedProfile?.page_id;
  }

  onSwitchProfile(profile: any): void {
    this.selectedProfile = profile;
    this.switchProfileEvent.emit(profile);
    // Fetch the comment list again after each profile change.
    this.pageNum = PAGE_NUM_DEFAULT;
    this.isGettingComment = false;
    this.getComment();
  }
}
