import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { CommentsService } from '@app/core/services/comments.service';
import { ReactionsService } from '@app/core/services/reactions.service';
import { SharesService } from '@app/core/services/shares.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_ACTION } from '@app/lib/api/client/api.client.constant';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import {
  getALLGroupToJoin,
  getMyGroup
} from '@app/modules/main/personal-profile/components/personal-profile-groups/store/actions';
import { DetailPostActions } from '@app/modules/main/states/detail-post/detail-post.actions';
import { selectDetailPost } from '@app/modules/main/states/detail-post/detail-post.selectors';
import {
  selectNewsFeedFollowFanpageBloomFilter,
  selectNewsFeedListFriendBloomFilter
} from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import { selectBloomStringComment, selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import {
  MAX_QTY_REACTIONS_STORAGE,
  OWNER_TYPE,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  POST_TYPE,
  PRIVACY,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import {
  ENTITY_TYPE,
  EmojiList,
  MyReaction,
  OwnerObjectModel,
  Post,
  Reaction,
  ReactionInfo,
  ReactionType,
  ReactionZIndex,
  SHARE_TYPE
} from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Subscription, take } from 'rxjs';
import { CommentComponent } from '../../comment/comment.component';

@Component({
  selector: 'post-footer',
  templateUrl: './post-footer.component.html',
  styleUrls: ['./post-footer.component.scss']
})
export class PostFooterComponent implements OnChanges, OnInit, OnDestroy {
  userInfo$ = this.store.select(selectUserInfo);
  pageBloomFilter$ = this.store.select(selectNewsFeedFollowFanpageBloomFilter);

  @Input() data: Post;
  @Input() userInfo: UserInfo;
  @Input() isPostView = false;
  @Input() isShowCreateAdsBtn = false;
  @Input() groupsJoined: any;
  @Input() isShowComment = false;
  @Input() isWatchScreen = false;
  @Input() isPhotoView = false;
  @Input() myGroup: any;
  @Input() isPostViewComment = false;
  @Input() isPostDetail = false;
  @Input() isPostReview = false;
  @Input() showSwitchProfileOption = false;
  @Output() shareClicked = new EventEmitter();
  @Output() isShowGiftCoin = new EventEmitter();
  @Output() switchProfileEvent = new EventEmitter();
  @ViewChild('menu') menu: any;
  @ViewChild(CommentComponent) commentComponent: CommentComponent;

  reactionList: Reaction[] = [];
  timeOutData: any;
  selectedEmoji: any;
  overlayVisible = false;
  shareOverlayVisible = false;
  emojiList = EmojiList;
  totalReactions = 0;
  // The number of comments and total comments with replies may vary for each profile.
  // Two separate values are created: for page profiles, totalComments will be fetched via the API.
  // For user profiles, totalComments will be calculated by excluding replies, as in comment.component.ts.
  totalComments = 0;
  totalCommentsAndReplies = 0;
  totalShares = 0;
  totalCoins = 0;
  reactedInfo: ReactionInfo;
  translateResult: any;
  reactId: string;
  isLoggedIn = false;
  hideCommentBox = false;
  buttonList = [
    {
      label: 'POST.LOVE',
      type: 1,
      icon: 'assets/icons/Icon - Reaction Default.svg'
    },
    {
      label: 'POST.COMMENT',
      type: 2,
      icon: 'assets/icons/message-dots-square.svg',
      tooltip: 'FANPAGE.COMMENT_AS'
    },
    {
      label: 'POST.SHARE',
      type: 3,
      icon: 'assets/icons/repeat-01.svg',
      tooltip: 'FANPAGE.SHARE_THIS_POST_AS'
    },
    {
      label: 'Ziichat',
      type: 5,
      icon: 'assets/icons/ziichat.svg'
    },
    {
      label: 'POST.GIFT',
      type: 4,
      icon: 'assets/icons/coin-default.svg'
    }
  ];
  shareOptions: any;
  isShowSharePost = false;
  reactionUser: {
    [key: string]: {
      id: string;
      full_name: string;
      reaction_type?: string;
      created_by?: UserInfo | OwnerObjectModel;
    }[];
  };
  visible = false;
  itemsTabMenu: MenuItem[] = [];
  activeItemTabMenu: MenuItem;
  pageSizeComment = 3;
  appendTo: ElementRef;
  isOpenPopupLogin = false;
  isHold = false;
  sub: Subscription;
  loadingBtnReaction = false;
  commenterList: string;
  shareList: string;
  hasCommented = false;
  hasGiftedCoin = false;
  comment: Comment;
  selectedProfile: any;
  hoveredButtonItem: any;
  isFriend = false;
  pageReactionInfo = [];

  constructor(
    private reactionsService: ReactionsService,
    private TranslationService: TranslationService,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private toastMessageService: ToastMessageService,
    private store: Store,
    private commentService: CommentsService,
    private sharesService: SharesService
  ) {}

  ngOnInit() {
    const authStatus = JSON.parse(localStorage.getItem('auth_status') || '[]');
    this.isLoggedIn = authStatus.isLoggedIn;
    this.hideCommentBox = this.isPostReview;
    if (
      this.data.post_type !== POST_TYPE.suggestion &&
      this.data.post_type !== POST_TYPE.ads &&
      this.data.total_comments &&
      !this.isWatchScreen
    ) {
      this.isShowComment = true;
    }
    this.store.select(selectBloomStringComment).subscribe(data => {
      if (data?.bloomStringComment) {
        this.hasCommented = this.handleBloomFilter(data.bloomStringComment, this.data.id);
      }
    });

    this.store.select(selectBloomStringComment).subscribe(data => {
      if (data?.bloomStringComment) {
        this.hasCommented = this.handleBloomFilter(data.bloomStringComment, this.data.id);
      }
      if (data?.bloomStringCoin) {
        this.hasGiftedCoin = this.handleBloomFilter(data.bloomStringCoin, this.data.id);
      }
    });

    if (!this.isPostView) {
      this.store.select(selectDetailPost).subscribe(item => {
        if (item.postId === this.data.id) {
          let dataChange = {};
          if (item.hasOwnProperty('total_reactions')) {
            dataChange = { total_reactions: item.total_reactions, list_reactions: item.list_reactions };
          } else if (item.hasOwnProperty('total_shares')) {
            dataChange = { total_shares: item.total_shares };
          } else if (item.hasOwnProperty('total_comments')) {
            dataChange = { total_comments: (this.data.total_comments || 0) + item.total_comments };
          }
          this.data = { ...this.data, ...dataChange };
          this.initPost();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['data']) {
      this.initPost();
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  get postOwnerId(): string {
    return this.data?.page_id ?? this.data?.user_object?.id ?? '';
  }

  initPost() {
    if (this.isPostViewComment) {
      this.buttonList = [
        {
          label: 'POST.LOVE',
          type: 1,
          icon: 'assets/icons/Icon - Reaction Default.svg'
        }
      ];
      this.comment = { ...this.data } as any;
      let totalReactions = 0;
      this.comment.reactions = this.comment.reactions.filter(item => item.count > 0);
      this.comment.reactions.forEach(item => (totalReactions += item.count));
      this.data.total_reactions = totalReactions;
      this.data.list_reactions = this.comment.reactions;
    }
    this.selectedEmoji = null;
    this.totalReactions = this.data.total_reactions || 0;
    this.totalCommentsAndReplies = this.data.total_comments || 0;
    this.totalCoins = this.data.coin_object?.total_amount || 0;
    this.totalShares = this.data.total_shares || 0;
    this.translateResult = this.TranslationService.getTranslation('POST');
    this.getReactionList();
    this.onLanguageChanged();
    this.getCurrentReaction();
    this.setItemTabMenu();
    this.activeItemTabMenu = this.itemsTabMenu[0];
    if (this.isPostView || this.route.snapshot.queryParams['comment-id']) {
      this.isShowComment = true;
      this.pageSizeComment = 10;
    }
    if (
      this.data.post_type !== POST_TYPE.suggestion &&
      this.data.post_type !== POST_TYPE.ads &&
      this.data.total_comments &&
      !this.isWatchScreen
    ) {
      this.isShowComment = true;
    } else if (!this.data.total_comments && !this.isPostView) {
      this.isShowComment = false;
    }
    this.handleCheckPrivacyComment();
    this.checkShowShareBtn();
  }

  getTotalCommentsAndReplies(): void {
    this.commentService
      .getTotalCommentsByOwnerId(this.selectedProfile?.page_id, this.data.id)
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.totalComments = res?.total_comments;
          this.totalCommentsAndReplies = res?.total_reply_comments_and_comments;
        }
      });
  }

  executeReactionList(type?: string, previousEmoji?: any) {
    let arrDelHandle: string[] = [];
    let arrAddHandle: string[] = [];
    switch (type) {
      case 'delete':
        this.deletePreviousEmoji(previousEmoji);
        [previousEmoji.type, ReactionType.all].forEach((key: string) => {
          if (this.checkExistKey(key)) {
            arrDelHandle.push(key);
          }
        });
        break;
      case 'put':
        this.deletePreviousEmoji(previousEmoji);
        this.addNewEmoji();
        if (this.checkExistKey(previousEmoji.type)) {
          arrDelHandle = [previousEmoji.type];
        }
        if (this.checkExistKey(this.selectedEmoji.type)) {
          arrAddHandle = [this.selectedEmoji.type];
        }
        break;
      default:
        this.addNewEmoji();
        [this.selectedEmoji.type, ReactionType.all].forEach((key: string) => {
          if (this.checkExistKey(key)) {
            arrAddHandle.push(key);
          }
        });
        break;
    }
    this.reactionList = this.reactionList.sort((a, b) => (a.count < b.count ? 1 : -1));
    if (arrDelHandle.length > 0) {
      arrDelHandle.forEach(itemDel => {
        // Update correct property to get id if it's a page profile
        const idToCompare = this.isSelectedPageProfile() ? this.selectedProfile?.page_id : this.userInfo.id;
        const index = this.reactionUser[itemDel].findIndex((res: any) => res.id === idToCompare);

        if (index !== -1) {
          this.reactionUser[itemDel].splice(index, 1);
        }
      });
    }
    if (arrAddHandle.length > 0) {
      arrAddHandle.forEach(itemAdd => {
        let data = {
          id: this.userInfo.id,
          full_name: this.userInfo.full_name
        };
        // Update data if it's page profile
        if (this.isSelectedPageProfile()) {
          data = {
            id: this.selectedProfile?.page_id,
            full_name: this.selectedProfile?.page_name
          };
        }
        this.reactionUser[itemAdd].unshift(data);
      });
    }
  }

  deletePreviousEmoji(previousEmoji?: any) {
    this.reactionList.map((ele, index) => {
      if (ele.type === previousEmoji.type) {
        ele.count === 1 ? this.reactionList.splice(index, 1) : (ele.count -= 1);
      }
    });
  }

  addNewEmoji() {
    const existReact = this.reactionList.find(el => el.type === this.selectedEmoji.type);
    if (existReact) {
      this.reactionList.map(el => {
        if (el.type === this.selectedEmoji.type) {
          el.count += 1;
        }
      });
    } else {
      this.reactionList.push({
        icon: this.emojiList.find(el => el.type === this.selectedEmoji.type)?.icon || '',
        type: this.selectedEmoji.type,
        count: 1
      });
    }
  }

  showHideOverlayIcons(flag: boolean, isHold = false) {
    clearTimeout(this.timeOutData);
    if (this.isLoggedIn) {
      if (isHold) {
        this.timeOutData = setTimeout(() => {
          this.isHold = true;
          this.overlayVisible = true;
        }, 200);
      } else {
        this.timeOutData = setTimeout(() => {
          this.overlayVisible = flag;
        }, 100);
      }
    }
  }

  executedEmojiChanged(type: string, isClickedBtn = false) {
    if (type === this.emojiList[0].type && this.overlayVisible && this.isHold) {
      this.isHold = false;
      return;
    }
    const myReactionsStorage = localStorage.getItem('myReactions');
    clearTimeout(this.timeOutData);
    if (myReactionsStorage) {
      const myReactionsInfo = JSON.parse(myReactionsStorage);
      const previousEmoji = { ...this.selectedEmoji };
      this.overlayVisible = false;
      const setLoadingState = (loading: boolean) => {
        this.loadingBtnReaction = loading;
        if (!loading && this.isPostView) {
          const listReactions = this.reactionList.map(item => {
            return {
              type: item.type,
              count: item.count
            };
          });
          if (this.isPostViewComment) {
            const data = { ...this.comment };
            data.reactions = listReactions;
            this.store.dispatch(DetailPostActions.onUpdateReactionComment({ info: data }));
          } else {
            this.store.dispatch(
              DetailPostActions.onUpdatePostDetail({
                infoPost: { postId: this.data.id, list_reactions: listReactions, total_reactions: this.totalReactions }
              })
            );
          }
        }
      };
      // Delete reaction
      if (type === '') {
        setLoadingState(true);
        this.reactionsService.deleteReactions(this.reactId).subscribe(() => {
          this.totalReactions -= 1;
          this.selectedEmoji = null;
          this.executeReactionList('delete', previousEmoji);
          // Use the unique React ID to ensure accuracy in all scenarios
          // related to updating reactions.
          myReactionsInfo.reactionList = myReactionsInfo.reactionList.filter(
            (el: MyReaction) => el.id !== this.reactId
          );
          localStorage.removeItem('myReactions');
          localStorage.setItem('myReactions', JSON.stringify(myReactionsInfo));
          setLoadingState(false);
        });
        return;
      }
      let param = {
        entity_id: this.data.id,
        entity_type: this.data.isReel ? ENTITY_TYPE.reel
          : this.isPostViewComment
          ? ENTITY_TYPE.comment
          : this.isPostView
          ? ENTITY_TYPE.media
          : ENTITY_TYPE.post,
        reaction_type: type,
        owner_type: OWNER_TYPE.user,
        owner_id: this.userInfo.id
      };
      // If a page profile is selected, update the page id instead of the user id.
      if (this.isSelectedPageProfile()) {
        param = { ...param, owner_type: OWNER_TYPE.page, owner_id: this.selectedProfile?.page_id };
      }
      setLoadingState(true);
      this.reactionsService.postReactions(param).subscribe((res: ReactionInfo) => {
        if (res) {
          this.analyticsService.pushPostAction(this.data, ADS_ACTION.reaction);
          if (!this.selectedEmoji) {
            // Add new reaction
            this.loadingBtnReaction = true;
            this.totalReactions += 1;
            myReactionsInfo.reactionList.push({
              id: res.id,
              entity_id: this.data.id,
              reaction_type: type,
              updated_at: res.updated_at,
              owner_id: res?.owner_id,
              owner_type: res?.owner_type
            });

            this.getSelectedEmoji(res.reaction_type);
            this.executeReactionList();
          } else {
            // Update reaction
            this.loadingBtnReaction = true;
            myReactionsInfo.reactionList.map((el: MyReaction) => {
              if (el.id === res.id) {
                el.reaction_type = type;
              }
            });
            this.getSelectedEmoji(res.reaction_type);
            this.executeReactionList('put', previousEmoji);
          }
          this.reactId = res.id;
          localStorage.removeItem('myReactions');
          localStorage.setItem('myReactions', JSON.stringify(myReactionsInfo));
        }
        setLoadingState(false);
      });
    }
  }

  executeButton(type: number, ev: any) {
    if (!this.isLoggedIn) {
      if (type === 2) {
        this.isOpenPopupLogin = false;
        this.showComment(true);
      }
      if (type === 3) {
        this.isOpenPopupLogin = false;
        this.menu.toggle(ev);
        this.initShareData();
      }
      if (type === 4) {
        this.isOpenPopupLogin = true;
      }
    } else {
      switch (type) {
        case 2:
          this.showComment(true);
          break;
        case 3:
          this.menu.toggle(ev);
          this.initShareData();
          break;
        case 4:
          this.isShowGiftCoin.emit(true);
      }
    }
    if (type === 5) {
      window.open(
        `https://zii.chat/send?content=${btoa(environment.SOCIAL_APP_URL + '/post/' + this.data.id)}`,
        '_blank'
      );
    }
  }

  getReactionList() {
    if (this.data.list_reactions && this.data.total_reactions) {
      this.totalReactions = this.data.total_reactions;
      this.reactionList = [];
      this.data.list_reactions.forEach((el: Reaction) => {
        this.reactionList.push({
          icon: this.emojiList.find(ele => ele.type === el.type)?.icon || '',
          type: el.type,
          count: el.count
        });
        this.reactionList = this.reactionList.sort((a, b) => (a.count < b.count ? 1 : -1));
      });
    } else {
      this.reactionList = [];
    }
  }

  getCurrentReaction() {
    const myReactionsStorage = localStorage.getItem('myReactions');
    if (myReactionsStorage) {
      const myReactionsInfo = JSON.parse(myReactionsStorage);
      const oldestTime = new Date(myReactionsInfo.oldestTime);
      const postedDate = new Date(this.data.posted_date);
      // Check if postedDate < oldestTime and if localStorage contain all reactions
      if (postedDate < oldestTime && myReactionsInfo.reactionList.length >= MAX_QTY_REACTIONS_STORAGE) {
        this.reactionsService.getMyReactionsByEntityId(this.data.id).subscribe(res => {
          this.getSelectedEmoji(res?.reaction_type);
        });
      } else {
        // Subscribe to the userInfo observable to ensure data is always available,
        // and immediately unsubscribe to avoid memory leaks.
        let userInfoSubscription: Subscription;
        userInfoSubscription = this.userInfo$.subscribe(res => {
          const reactData =
            myReactionsInfo.reactionList.find((el: MyReaction) => {
              return el.entity_id === this.data.id && el.owner_id === res.id;
            }) || null;

          if (reactData) {
            this.reactId = reactData.id;
            this.getSelectedEmoji(reactData.reaction_type);
          } else {
            this.selectedEmoji = null;
          }
          userInfoSubscription?.unsubscribe();
        });
      }
    }
  }

  getPageCurrentReactions(): void {
    this.reactionsService.getPageReaction(this.selectedProfile?.page_id).subscribe(res => {
      if (res?.success) {
        this.pageReactionInfo = res?.data?.data;
        const reactData = this.pageReactionInfo?.find((el: MyReaction) => el.entity_id === this.data.id) as any;
        this.reactId = reactData?.id;
        this.getSelectedEmoji(reactData?.reaction_type);
      } else {
        this.selectedEmoji = null;
      }
    });
  }

  getSelectedEmoji(type: string) {
    this.selectedEmoji = this.emojiList.find(el => el.type === type);
  }

  getZIndexReaction(i: number) {
    return ReactionZIndex(i);
  }

  showComment(event: boolean) {
    this.isShowComment = event;
    if (this.isPostView && event) {
      this.commentComponent.focusCommentForm();
    }
  }

  onLanguageChanged() {
    this.TranslationService.language$.subscribe(() => {
      this.translateResult = this.TranslationService.getTranslation('POST');
    });
  }

  initShareData() {
    this.shareOptions = [
      {
        icon: 'sctr-icon-edit-05',
        label: this.translateResult.DROPDOWN_SHARE.SHARE_TO_FEED,
        command: () => {
          if (!this.isLoggedIn) {
            this.isOpenPopupLogin = true;
          } else {
            if (this.selectedProfile) {
              this.switchProfileEvent.emit(this.selectedProfile);
            }
            this.shareClicked.emit(SHARE_TYPE.feed);
          }
        }
      },
      {
        icon: 'sctr-icon-users-01',
        label: this.translateResult.DROPDOWN_SHARE.SHARE_TO_GROUP,
        command: () => {
          if (!this.isLoggedIn) {
            this.isOpenPopupLogin = true;
          } else {
            if (!this.myGroup || this.myGroup.length === 0) {
              this.store.dispatch(
                getMyGroup({ pageSize: PAGE_SIZE_DEFAULT, pageNum: PAGE_NUM_DEFAULT, textSearch: '' })
              );
            }
            if (!this.groupsJoined || !this.groupsJoined.data?.length) {
              this.store.dispatch(
                getALLGroupToJoin({ pageSize: PAGE_SIZE_DEFAULT, pageNum: PAGE_NUM_DEFAULT, textSearch: '' })
              );
            }
            this.shareClicked.emit(SHARE_TYPE.group);
          }
        }
      },
      {
        icon: 'sctr-icon-copy-06',
        label: this.translateResult.DROPDOWN_SHARE.COPY_LINK,
        command: () => {
          navigator.clipboard.writeText(environment.SOCIAL_APP_URL + '/post/' + this.data.id);
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.success,
            'POST.DROPDOWN_SHARE.LINK_COPIED'
          );
        }
      }
    ];
    if (this.isSelectedPageProfile()) {
      // Remove the "share to group" option as this is a page profile.
      this.shareOptions = this.shareOptions.filter(
        (option: any) => option.label !== this.translateResult.DROPDOWN_SHARE.SHARE_TO_GROUP
      );
    }
  }

  getReactedUsers(type: string = ReactionType.all) {
    let userList = '';
    if (this.checkExistKey(type)) {
      const label = type !== ReactionType.all ? EmojiList.find(el => el.type === type)?.label : '';
      const labelTranlate = label ? this.TranslationService.getTranslation(label) : '';
      userList = type !== ReactionType.all ? `<p class="text-base mb-2">${labelTranlate}</p>` : '';
      const reaction = this.reactionList.filter(item => item.type === type);
      for (let i = 0; i < this.reactionUser[type].length; i++) {
        const el = this.reactionUser[type][i];
        // Check and show max 9 reacted users in list
        if (i <= 8) {
          userList = userList + '<p class="text-sm">' + el.full_name + '</p>';
        }
      }
      if ((type !== ReactionType.all ? reaction[0].count : this.totalReactions) > 10) {
        const total = ((type !== ReactionType.all ? reaction[0].count : this.totalReactions) - 9).toString();
        userList =
          userList +
          `<p class="text-sm">${this.TranslationService.getTranslation(
            'COMMON.AND'
          ).toLowerCase()} ${total} ${this.TranslationService.getTranslation('POST.OTHER_PEOPLE')}...</p>`;
      }
    }
    return userList;
  }

  getReactionsByEntityId(type: string = ReactionType.all) {
    if (!this.checkExistKey(type)) {
      this.reactionsService
        .getReactionsByEntityId(this.data.id, type === ReactionType.all ? '' : type)
        .subscribe(reactions => {
          const data = reactions.map(el => {
            if (el?.owner_type === OWNER_TYPE.page) {
              // Update the reaction user list based on the owner_object if it belongs to a fanpage reaction.
              return {
                id: el?.owner_object?.page_id ?? '',
                full_name: el?.owner_object?.page_name ?? '',
                reaction_type: el.reaction_type,
                created_by: el.owner_object
              };
            }
            return {
              id: el.created_by.id,
              full_name: el.created_by.full_name,
              reaction_type: el.reaction_type,
              created_by: el.created_by
            };
          });
          this.reactionUser = { ...this.reactionUser, [type]: data };
        });
    }
  }

  getCommenterListByEntityId() {
    if (!this.commenterList?.length) {
      this.commentService.getCommenterListByEntityId(this.data.id).subscribe(data => {
        this.commenterList = this.getCommenterList(data.data, data.totalElement);
      });
    }
  }

  getShareListByEntityId() {
    if (!this.shareList?.length) {
      this.sharesService.getShareListByEntityId(this.data.id).subscribe(data => {
        this.shareList = this.getShareList(data.data);
      });
    }
  }

  getShareList(data: any) {
    let userList = '';
    if (data.length > 0) {
      let users = data.slice(0, 8).map((item: any) => `<p class="text-sm">${item.user_object.full_name}</p>`);
      userList = users.join('');

      if (data.length > 8) {
        const totalShares = Number.isFinite(this.totalShares) ? this.totalShares : 0;
        const remainingCount = Math.max(totalShares - 8, 0);
        userList += `<p class="text-sm">${this.TranslationService.getTranslation(
          'COMMON.AND'
        ).toLowerCase()} ${remainingCount} ${this.TranslationService.getTranslation('POST.OTHER_PEOPLE')}...</p>`;
      }
    }
    return userList;
  }

  getCommenterList(data: string[], totalCommentsAndReplies: number) {
    let userList = '';
    if (data?.length) {
      let users = data.map(item => `<p class="text-sm">${item}</p>`);
      userList = users.join('');
      if (totalCommentsAndReplies > data.length) {
        userList =
          userList +
          `<p class="text-sm">${this.TranslationService.getTranslation('COMMON.AND').toLowerCase()} ${
            totalCommentsAndReplies - data.length
          } ${this.TranslationService.getTranslation('POST.OTHER_PEOPLE')}...</p>`;
      }
    }
    return userList;
  }

  checkExistKey(key: string) {
    return this.reactionUser?.hasOwnProperty(key);
  }

  displayPopupViewReaction() {
    this.visible = true;
    this.reactionUser = {};
    this.setItemTabMenu();
    this.activeItemTabMenu = this.itemsTabMenu[0];
  }

  getIconReaction(type: string) {
    return EmojiList.find(el => el.type === type)?.icon;
  }

  getCountReaction(type: string) {
    return (
      type === ReactionType.all ? this.totalReactions : this.reactionList?.find(s => s.type === type)?.count || '0'
    ) as string;
  }

  handleCreateAds(): void {
    this.router.navigate(['/advertisement/create-ads'], {
      queryParams: { post: JSON.stringify(this.data) }
    });
  }

  setItemTabMenu() {
    this.itemsTabMenu = [
      {
        id: ReactionType.all,
        icon: '',
        label: this.getCountReaction(ReactionType.all),
        title: this.translateResult.POPUP.REACTION_LIST.ALL
      }
    ];
    this.reactionList.map(item => {
      this.itemsTabMenu.push({
        id: item.type,
        icon: item.icon,
        label: item.count.toString(),
        title: this.translateResult[item.type]
      });
    });
  }

  checkShowShareBtn() {
    // Remove this.isPostView when BE support share single image/video
    if (this.data.post_privacy !== PRIVACY.public || this.isPostView) {
      this.buttonList = this.buttonList.filter(item => item.type !== 3);
    }
  }

  updateTotalCommentsAndReplies(total: number) {
    this.totalCommentsAndReplies = total;
  }

  openPopup(event: any) {
    this.isOpenPopupLogin = event;
  }

  handleBloomFilter(bloomString: string, userId: string) {
    if (bloomString === '') return false;
    const { BloomFilter } = require('soctrip-algorithm/dist/api.js');
    const bloomFilter = BloomFilter.load(bloomString);
    return bloomFilter.has(userId);
  }

  handleCheckPrivacyComment(): void {
    this.store.select(selectNewsFeedListFriendBloomFilter).subscribe(data => {
      this.isFriend = this.handleBloomFilter(data, this.data.user_id || '');
      if (!this.checkShowCommentPost(this.isFriend)) {
        this.buttonList = [
          {
            label: 'POST.LOVE',
            type: 1,
            icon: 'assets/icons/Icon - Reaction Default.svg'
          },
          {
            label: 'POST.SHARE',
            type: 3,
            icon: 'assets/icons/repeat-01.svg'
          },
          {
            label: 'Ziichat',
            type: 5,
            icon: 'assets/icons/ziichat.svg'
          },
          {
            label: 'POST.GIFT',
            type: 4,
            icon: 'assets/icons/coin-default.svg'
          }
        ];
        this.hideCommentBox = true;
      }
    });
  }

  checkShowCommentPost(isFriend: boolean): boolean {
    const privacyCommentPost = this.data.user_object?.comment_post_privacy;
    if (this.data.user_id === this.userInfo.id) return true;
    if (!privacyCommentPost) return true;
    if (privacyCommentPost === PRIVACY.public) return true;
    if (privacyCommentPost === PRIVACY.friends && isFriend) return true;
    return false;
  }

  isSelectedPageProfile(): boolean {
    // Check if the selected profile is a page profile.
    return !!this.selectedProfile?.page_id;
  }

  onSwitchProfile(profile: any): void {
    this.selectedProfile = profile;
    // Re-render the selected emoji whenever the profile is switched.
    if (this.isSelectedPageProfile()) {
      this.getPageCurrentReactions();
      // API call will be made to fetch accurate totalComments and totalCommentsAndReplies for the selected page profile.
      this.getTotalCommentsAndReplies();
    } else {
      this.getCurrentReaction();
      this.totalCommentsAndReplies = this.data.total_comments || 0;
      this.totalComments = 0;
    }
  }
}
