<div class="grid place-items-center w-full gap-3">
  <div class="flex gap-2">
    <div class="h-8">
      <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
    </div>
    <!-- TODO: I'll enable Facebook login later. -->
    <!-- <button
      (click)="facebookSignin()"
      type="button"
      data-te-ripple-init
      data-te-ripple-color="light"
      class="mb-2 inline-block rounded px-2 py-2 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
      style="background-color: #1877f2">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
      </svg>
    </button> -->
  </div>
  <div class="demo-container w-full" *ngIf="isLoading">
    <div class="progress-bar">
      <div class="progress-bar-value"></div>
    </div>
  </div>
</div>
