import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { LoyaltyService } from '@app/core/services/loyalty.service';

@Component({
  selector: 'point-history',
  templateUrl: './point-history.component.html',
  styleUrls: ['./point-history.component.scss']
})
export class PointHistoryComponent {
  @Input() programId: string;
  pointHistory: any[] = [];
  pageNum = 0;
  pageSize = 25;
  totalElement: number;
  loyaltyProfile: any;
  isLoading = false;
  isShowEvent = false;
  @ViewChild('eventsView') eventsView: ElementRef;
  constructor(private loyaltyService: LoyaltyService){}
  ngOnInit(): void {
    const listRank = localStorage.getItem('loyaltyProfile');
    if (listRank) {
      this.loyaltyProfile = JSON.parse(listRank).data;
    }    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['programId'] && changes['programId'].currentValue) {
      if (!this.programId) {
        return;
      }
      this.handleRefresh();
    }
  }

  onScroll(){
    const container = this.eventsView.nativeElement;
    const scrollHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const offsetHeight = container.offsetHeight;
    
    if (scrollTop + offsetHeight >= scrollHeight - 2) {
      this.pageNum += 1;
      if ((this.pageNum) * this.pageSize < this.totalElement) {
        this.getPointHistory();
      }
    }
  }

  handleRefresh(){
    this.isLoading = true;
    this.pointHistory = [];
    this.pageNum = 0;
    this.getPointHistory();
  }

  getPointHistory(){
    this.loyaltyService.getPointHistory(this.programId, this.pageNum).subscribe({
      next: item => {
        if (item.totalElement === 0) {
          this.isShowEvent = true;
        } else {
          this.isShowEvent = false;
          this.totalElement = item.totalElement;
          if(item.data && !this.pointHistory.some(el => el.id === item.data.id)){
            this.pointHistory = this.pointHistory.concat(item.data);
          }
        }
        this.isLoading = false;
      },
      error: () => {
          this.isLoading = false;
      }
    }
  );
  }
}
