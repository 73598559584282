import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, delay, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  AdsCreateResponse,
  AdsGroup,
  Advertisement,
  AdvertisingAPI,
  Campaigns,
  CampaignsResponse,
  CountriesResponse,
  InterestsBehaviorResponse,
  LanguageResponse
} from './api.advertising.model';

export class AdvertisingApi {
  private apiUrl: string = environment.baseURL;

  CGA = '/cga-ids';
  DELETE = '/delete';
  SEARCH = '/search';
  CONTROL = '/control';
  DOWNLOAD = '/download';
  CAMPAIGNS = '/campaigns';
  GROUP_ADS = '/group-ads';
  LANGUAGES = '/languages';
  DUPLICATE = '/duplicate';
  ADVERTISES = '/advertises';
  ADVERTISING = '/advertising';
  CHECK_AD_EXISTENCE = '/check-ads-existence';
  SHIPMENT = '/shipment';
  ADDRESS = '/address';
  COUNTRIES = '/countries';
  INTERESTS = '/interests';
  INTERESTS_BEHAVIOR = '/interests-behavior';
  ADS = '/ads';
  LIST = '/list';
  ESTIMATE_AUDIENCE = '/estimate-audience';
  ACCOUNT = '/account';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  updateUrlWithParameter(baseUrl: string, campaignListParamValues: any): string {
    const endpoint = `${baseUrl}?${Object.entries(campaignListParamValues)
      .filter(([key, value]) => key !== 'type' && value !== '')
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`;
    return endpoint;
  }

  getCgaInfo(tabCurrent: string, cga_ids: string, excludePost = false): Observable<AdvertisingAPI.CgaInfo> {
    const params = new HttpParams().set('tabCurrent', tabCurrent).set('excludePost', excludePost);

    return this.http.get<AdvertisingAPI.CgaInfo>(`${this.apiUrl}${this.ADVERTISING}/${cga_ids}`, { params }).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getRelatedList(params: { type: string }, id: string): Observable<AdvertisingAPI.CgaListResponse> {
    const endpoint = `${this.apiUrl}${this.ADVERTISING}${this.LIST}/${id}?type=${params.type}`;

    return this.http.get<AdvertisingAPI.CgaListResponse>(endpoint).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  viewDashboard(id: string, type: string, startTime: number, endTime: number) {
    const endpoint = `${this.apiUrl}${this.ADVERTISING}/dashboard/stats?object_id=${id}&object_type=${type}&start_date_time=${startTime}&end_date_time=${endTime}`;
    return this.http.get<AdvertisingAPI.ViewDashboardRespone>(endpoint).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCampaignList(
    campaignListParamValues: AdvertisingAPI.GetCampaignList | any,
    cga_ids: string[] = []
  ): Observable<AdvertisingAPI.CampaignListResponse> {
    const params = new HttpParams().appendAll(campaignListParamValues);

    return this.http
      .post<AdvertisingAPI.CampaignListResponse>(
        `${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}${this.CGA}`,
        { cga_ids },
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        delay(300),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAvailableCGAList(
    requestParams: AdvertisingAPI.GetAvailableCGAList
  ): Observable<AdvertisingAPI.CampaignListResponse> {
    let params = new HttpParams()
      .set('tabRequest', requestParams.tabRequest)
      .set('pageNum', requestParams.pageNum)
      .set('pageSize', requestParams.pageSize);

    if (requestParams.objectId) {
      params = params.set('object_id', requestParams.objectId);
    }

    return this.http
      .get<AdvertisingAPI.CampaignListResponse>(
        `${this.apiUrl}${this.ADVERTISING}/available/${requestParams.accountId}`,
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        delay(300),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  searchCampaignList(
    searchParamValues: AdvertisingAPI.SearchCampaignList | any
  ): Observable<AdvertisingAPI.CampaignListResponse> {
    const params = new HttpParams().appendAll(searchParamValues);

    return this.http
      .get<AdvertisingAPI.CampaignListResponse>(`${this.apiUrl}${this.ADVERTISING}${this.SEARCH}`, { params })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  exportCampaignList(exportParamValues: AdvertisingAPI.ExportCampaignListParams): void {
    const baseUrl = `${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}${this.DOWNLOAD}`;
    const endpoint = this.updateUrlWithParameter(baseUrl, exportParamValues);
    window.open(endpoint, '_blank');
  }

  controlCampaignList(
    controlParamValues: AdvertisingAPI.ControlCampaignListParams | any,
    id: string
  ): Observable<AdvertisingAPI.ControlCampaignListResponse> {
    const params = new HttpParams().appendAll(controlParamValues);

    return this.http
      .put<AdvertisingAPI.ControlCampaignListResponse>(
        `${this.apiUrl}${this.ADVERTISING}${this.CONTROL}/${id}`,
        {},
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  duplicateCampaignList(
    controlParamValues: AdvertisingAPI.ControlCampaignListParams | any,
    id: string
  ): Observable<AdvertisingAPI.ControlCampaignListResponse> {
    const params = new HttpParams().appendAll(controlParamValues);

    return this.http
      .post<AdvertisingAPI.ControlCampaignListResponse>(
        `${this.apiUrl}${this.ADVERTISING}${this.DUPLICATE}/${id}`,
        {},
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteCampaignList(
    deleteParamValues: AdvertisingAPI.ControlCampaignListParams,
    ids: string[]
  ): Observable<AdvertisingAPI.ControlCampaignListResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ids }
    };
    const baseUrl = `${this.apiUrl}${this.ADVERTISING}${this.DELETE}`;
    const endpoint = this.updateUrlWithParameter(baseUrl, deleteParamValues);

    return this.http.delete<AdvertisingAPI.ControlCampaignListResponse>(endpoint, options).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  checkAdExistence(
    checkAdParams: AdvertisingAPI.CheckAdParams | any,
    cga_ids: string[]
  ): Observable<AdvertisingAPI.CheckAdResponse> {
    const params = new HttpParams().appendAll(checkAdParams);

    return this.http
      .post<AdvertisingAPI.CheckAdResponse>(
        `${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}${this.CHECK_AD_EXISTENCE}`,
        { cga_ids },
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getLanguages = () =>
    this.http.get<LanguageResponse>(`${this.apiUrl}${this.ADVERTISING}${this.GROUP_ADS}${this.LANGUAGES}`);

  saveCampaign = (params: Campaigns) =>
    this.http.post<CampaignsResponse>(`${this.apiUrl}${this.ADVERTISING}${this.CAMPAIGNS}`, params);

  saveAdsGroup = (params: AdsGroup) =>
    this.http.post<CampaignsResponse>(`${this.apiUrl}${this.ADVERTISING}${this.GROUP_ADS}`, params);

  getAdvertisementInfo(id: string): Observable<AdvertisingAPI.AdvertisementResponse> {
    const endpoint = `${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}/${id}`;

    return this.http.get<AdvertisingAPI.AdvertisementResponse>(endpoint).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  saveAdvertisement = (params: Advertisement) =>
    this.http.post<AdsCreateResponse>(`${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}`, params);

  editAdvertisement = (params: Advertisement) =>
    this.http.put<AdsCreateResponse>(`${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}`, params);

  saveAdvertisementObject = (params: Advertisement) =>
    this.http.post<AdsCreateResponse>(
      `${this.apiUrl}${this.ADVERTISING}${this.ADVERTISES}/${params.object_id}`,
      params
    );

  getCountries = () =>
    this.http.get<CountriesResponse>(`${this.apiUrl}${this.SHIPMENT}${this.ADDRESS}${this.COUNTRIES}`);

  getCountriesProvinces = (id: string) =>
    this.http.get<CountriesResponse>(`${this.apiUrl}${this.SHIPMENT}${this.ADDRESS}/provinces?countryId=${id}`);

  getCountriesProvincesDetail = (id: string) =>
    this.http.get<CountriesResponse>(`${this.apiUrl}${this.SHIPMENT}${this.ADDRESS}/provinces/${id}/detail`);

  getInterestsBehavior = (id?: string) =>
    this.http.get<InterestsBehaviorResponse>(
      `${this.apiUrl}${this.ADVERTISING}${this.GROUP_ADS}${this.INTERESTS_BEHAVIOR}?parentId=${id ? id : ''}`
    );

  editCampaign = (params: Campaigns) =>
    this.http.put<CampaignsResponse>(`${this.apiUrl}${this.ADVERTISING}${this.CAMPAIGNS}`, params);

  editAdsGroup = (params: Campaigns) =>
    this.http.put<CampaignsResponse>(`${this.apiUrl}${this.ADVERTISING}${this.GROUP_ADS}`, params);

  updateActionAds = (id: string, type: string) =>
    this.http.put<any>(`${this.apiUrl}${this.ADVERTISING}${this.CONTROL}/${id}?tabCurrent=${type}`, {});

  estimateAudience = (param: AdvertisingAPI.EstimateAudience) =>
    this.http.post<any>(`${this.apiUrl}${this.ADS}${this.ESTIMATE_AUDIENCE}`, param).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );

  estimateAudiencePixel = (param: AdvertisingAPI.EstimateAudiencePixelParams) =>
    this.http.post<any>(`${this.apiUrl}${this.ADS}${this.ESTIMATE_AUDIENCE}/pixel`, param).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
}
