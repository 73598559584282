import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { TranslateParamModel } from '@app/lib/api/translate/api.translate.model';
import { CHARACTER_TRANSLATION_LIMIT } from '@app/shared';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  languageSubject = new BehaviorSubject<any>({});
  language$: Observable<string> = this.languageSubject.asObservable();

  private api: ApiClient;

  constructor(
    private translateService: TranslateService,
    public readonly httpClient: HttpClient,
    private config: PrimeNGConfig
  ) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageSubject.next(event.lang);
    });
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  initAnonymousLanguage(): string {
    let initLang = environment.languages[0];
    this.translateService.addLangs(environment.languages);
    const browserLang = this.translateService.getBrowserLang() || initLang;
    initLang = localStorage.getItem('unauthLang') || (environment.languages.includes(browserLang) ? browserLang : initLang);
    this.translateService.use(initLang.toLowerCase());
    return initLang;
  }

  setAnonymousLanguage(lang: string): void {
    if (lang && lang.length) {
      localStorage.setItem('unauthLang', lang.toLowerCase());
      this.translateService.use(lang.toLowerCase());
    }
  }

  setLanguage(lang: string) {
    this.translateService.use(lang.toLowerCase());
  }

  getTranslation(key: string) {
    return this.translateService.instant(key);
  }

  getTranslationWithParams(key: string, params: any): string {
    return this.translateService.instant(key, params);
  }

  translateText(params: TranslateParamModel): Observable<any> {
    return this.api.translate.translateText(params);
  }

  updateLocalCurrencyAndLanguage(putData: any) {
    const user_profileString = localStorage.getItem('user_profile');
    if (user_profileString !== null) {
      let user_profile: { [key: string]: string } = JSON.parse(user_profileString);
      Object.keys(putData).forEach((key: string) => {
        if (putData.hasOwnProperty(key)) {
          user_profile[key] = putData[key];
        }
      });
      localStorage.setItem('user_profile', JSON.stringify(user_profile));
    }
  }

  getKeyLocalUserProfile(key: string) {
    const user_profileString = localStorage.getItem('user_profile');
    if (user_profileString !== null) {
      let user_profile: { [key: string]: string } = JSON.parse(user_profileString);

      return user_profile[key];
    }
    return '';
  }

  isOnlyLink(content: any) {
    if (content?.trim()?.length > CHARACTER_TRANSLATION_LIMIT) return true; //check limit 5000 character
    const urlRegex = /(https?:\/\/[^\s]+)/g; //check link in content
    const iconRegex = /\p{Extended_Pictographic}/gu; // check icon in content
    const symbolRegex = /[0-9\W|_]/g; // check symbol in content
    content = content.replace(urlRegex, '');
    content = content.replace(iconRegex, '');
    content = content.replace(symbolRegex, '');
    content = content.replace(/\n/g, '').trim();
    return content === '';
  }

  urlify(text: string) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<a target="_blank" class="text-sm font-semibold text-branding-primary-700 hover:cursor-pointer hover:underline" href="${url}">${url}</a>`;
    });
  }

  checkLanguage(content: string, language: string) {
    if (this.isOnlyLink(content)) return false;
    if (!language) return false;
    const selectedLangCode = this.getKeyLocalUserProfile('language')?.toLocaleLowerCase();
    return selectedLangCode !== language;
  }

  replaceHashtagTranslation(content: string) {
    const regex = /<([a-zA-Z0-9_]+)_name><\/\1_name>|<([a-zA-Z0-9_]+)_tag><\/\2_tag>/g;

    return content.replace(regex, (match, p1, p2) => {
      if (p1) {
        const name = p1.replace('_', ' ').split('_').join(' ');
        return ` @${name} `;
      } else if (p2) {
        return ` #${p2} `;
      } else {
        return match;
      }
    });
  }

  setTranslateCalendar() {
    const translatedCalendar = {
      dayNames: this.translateService.instant('COMMON.DATE_TIME.DAY_NAMES'),
      dayNamesShort: this.translateService.instant('COMMON.DATE_TIME.DAY_NAMES_SHORT'),
      dayNamesMin: this.translateService.instant('COMMON.DATE_TIME.DAY_NAMES_MIN'),
      monthNames: this.translateService.instant('COMMON.DATE_TIME.MONTH_NAMES'),
      monthNamesShort: this.translateService.instant('COMMON.DATE_TIME.MONTH_NAMES_SHORT'),
      today: this.translateService.instant('COMMON.DATE_TIME.TODAY'),
      clear: this.translateService.instant('COMMON.BUTTON_ACTION.CLEAR'),
      weekHeader: this.translateService.instant('COMMON.DATE_TIME.WEEK_HEADER'),
      dateFormat: this.translateService.instant('COMMON.DATE_TIME.DATE_FORMAT')
    };
    this.config.setTranslation(translatedCalendar);
  }
}
