import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { LoyaltyService } from '@app/core/services/loyalty.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { FanpageService } from '@app/modules/main/fanpage/service/fanpage.service';
import { getPageProfiles, updateLoadingState } from '@app/modules/main/fanpage/store/actions';
import {
  selectHasPageProfiles,
  selectLoadingProfiles,
  selectPageProfiles
} from '@app/modules/main/fanpage/store/selector';
import { LoyaltyActions } from '@app/modules/main/states/loyalty-hub/loyalty-hub.action';
import { selectLoyaltyList } from '@app/modules/main/states/loyalty-hub/loyalty-hub.selector';
import { selectNewsFeedListFriendBloomFilter } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import {
  AVATAR_DEFAULT_SIZE,
  AVATAR_TO_PROFILE_RATIO,
  FANPAGES,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  PRIVACY,
  PROFILE_PAGE_SIZE_DEFAULT,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, finalize, Subject, Subscription, take, takeUntil } from 'rxjs';

const DEFAULT_PAGE_PROFILES = { data: [], totalElement: 0, page: 0 };

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  hasPageProfiles$ = this.store.select(selectHasPageProfiles);
  pageProfile$ = this.store.select(selectPageProfiles);
  isLoadingProfile$ = this.store.select(selectLoadingProfiles);

  @Input() userInfo?: UserInfo | any;
  @Input() isSmallThumbnail = false;
  @Input() isDisableNavigate = false;
  @Input() avatarSize = AVATAR_DEFAULT_SIZE;
  @Input() isUseLogoSoctrip = false;
  @Input() showSwitchProfileOption = false;
  @Input() selectedProfile: any;
  @Output() switchProfileEvent = new EventEmitter();
  @ViewChild('switchProfileOption') switchProfileOptionRef: ElementRef;

  private destroy$ = new Subject<void>();
  scrollSub: Subscription;
  userAvatarUrl: string = '';
  userFrameUrl: string = '';
  baseUrl: string = environment.baseURL;
  isModerate = false;
  avatarToProfileRatio = AVATAR_TO_PROFILE_RATIO;
  avatarDefaultSize = AVATAR_DEFAULT_SIZE;
  showSwitchProfileMenu = false;
  profileSearchData: any = DEFAULT_PAGE_PROFILES;

  profileSearchText = new FormControl('');
  viewMoreBtnRef: any;
  colorRing: string;
  iconRank: string;
  isLoyalty = true;
  isFriend = false;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private loyaltyService: LoyaltyService,
    private store: Store,
    private toastMessageService: ToastMessageService,
    private translateService: TranslationService,
    private fanpageService: FanpageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['userInfo'] && changes['userInfo'].currentValue) {
      setTimeout(() => {
        const userInfo = changes['userInfo'].currentValue;        
        this.getUserRank(userInfo);
        if (!this.selectedProfile) {
          this.getAvatar(userInfo);
        }
        this.getFrame(userInfo);
      }, 500);
    }
  }

  ngOnInit(): void {
    this.isModerate = this.route.snapshot.queryParams['admin'] === 'true';
    // Update the correct avatar URL corresponding to the selected profile.
    if (this.selectedProfile) {
      const imgUrl = this.isSelectedPageProfile()
        ? this.selectedProfile?.avatar
        : this.selectedProfile?.avatar_thumbnail_url;
      this.userAvatarUrl = this.getImageUrl(imgUrl);
    } else {
      this.getAvatar(this.userInfo);
    }
    this.getFrame(this.userInfo);
    if (this.isUseLogoSoctrip) {
      this.userAvatarUrl = 'assets/images/soctrip-branding/soctrip-logo-blue.svg';
    }
    this.profileSearchText.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(searchText => {
        if (searchText) {
          this.onSearchPageProfile();
        } else {
          // Reset to a default value if there is no search text.
          this.profileSearchData = DEFAULT_PAGE_PROFILES;
        }
      });

    this.store.select(selectLoyaltyList).subscribe((res) => {
      if (!res.length && (this.iconRank || this.colorRing)) {
        return;
      }
      this.getUserRank(this.userInfo);
    });
  }
  handleBloomFilter(bloomString: string, userId: string) {
    if (bloomString === '') return false;
    const { BloomFilter } = require('soctrip-algorithm/dist/api.js');
    const bloomFilter = BloomFilter.load(bloomString);
    return bloomFilter.has(userId);
  }

  getUserRank(userInfo: UserInfo) {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    const userProfile = JSON.parse(localStorage.getItem('user_profile') || '{}');

    this.store.select(selectNewsFeedListFriendBloomFilter).subscribe(data => {
      this.isFriend = this.handleBloomFilter(data, userInfo?.id || '');
      if (isLoggedIn && userInfo?.id === userProfile?.id) {
        const loyaltyProfile = JSON.parse(localStorage.getItem('loyaltyProfile')|| '{}');
        this.iconRank = loyaltyProfile.data?.user_rank?.image;
        this.colorRing = loyaltyProfile.data?.user_rank?.avatar_outline_stroke;
      } else if (userInfo?.loyalty_id) {
        if (userInfo.loyalty_membership === PRIVACY.public ||
          (userInfo.loyalty_membership === PRIVACY.friends && this.isFriend) ||
          (isLoggedIn && userInfo.id === userProfile?.id)
        ) {
          this.getLoyaltyInfo(userInfo.loyalty_id, userInfo);
        }
      }
    });
  }

  getLoyaltyInfo(loyalty_id: string, userInfo: UserInfo){
    const item = this.loyaltyService.getLoyaltyInfo(loyalty_id);
    if (!item && userInfo.program_id) {
      this.store.dispatch(LoyaltyActions.onGetLoyaltyListByProgramId({ id: userInfo.program_id }));
      return;
    }
    this.iconRank = item?.image;
    this.colorRing = item?.avatar_outline_stroke;
  }

  navigateToProfile(): void {
    if (this.isModerate) return;
    if (!this.isDisableNavigate) {
      if (this.isUseLogoSoctrip) {
        window.open(environment.ECOMMERCE_URL, '_blank');
      } else {
        this.router.navigate(['/personal-profile', this.userInfo?.username || this.userInfo?.id]);
      }
    }
  }

  navigateToPage(): void {
    // Navigate to the fanpage instead of the user profile.
    localStorage.setItem('fanpageSelectedType', FANPAGES.switchIntoPageRole);
    this.router.navigate(['/fanpage', this.selectedProfile?.page_id]);
  }

  getAvatar(userInfo: any) {
    this.userAvatarUrl = this.commonService.getImageUrl(userInfo && userInfo.avatar_thumbnail_url);
  }

  getFrame(userInfo: any) {
    this.userFrameUrl = this.commonService.getImageUrl(userInfo && userInfo.frame_url);
  }

  get showPageAvatar(): boolean {
    // Display the page avatar as initials when the selected profile is not a user profile
    // and the page has no avatar.
    return !this.userAvatarUrl && this.isSelectedPageProfile();
  }

  onChangeShowProfileMenu(): void {
    if (!this.selectedProfile) {
      this.selectedProfile = this.userInfo;
    }
    this.showSwitchProfileMenu = !this.showSwitchProfileMenu;
  }

  isSelectedPageProfile(): boolean {
    return !!this.selectedProfile?.page_id;
  }

  getImageUrl(url: string | undefined): string {
    return this.commonService.getImageUrl(url);
  }

  onChangeProfile(profile: any): void {
    this.selectedProfile = profile;
    const imgUrl = this.isSelectedPageProfile() ? profile?.avatar : profile?.avatar_thumbnail_url;
    const profileName = this.isSelectedPageProfile()
      ? this.selectedProfile?.page_name
      : this.selectedProfile?.full_name;
    const profileType = this.isSelectedPageProfile()
      ? this.translateService.getTranslation('LIVESTREAM.PAGE')
      : this.translateService.getTranslation('ROLES.USER');
    this.userAvatarUrl = this.getImageUrl(imgUrl);
    this.toastMessageService.addMessageWithTranslateParams(
      TOAST_MESSAGE_SEVERITY_LEVELS.success,
      'FANPAGE.SWITCH_PROFILE_SUCCESSFULLY',
      {
        profileName,
        profileType: (profileType || '').toLowerCase()
      }
    );
    // Emit the selected profile for the create post dialog whenever this value changes.
    this.switchProfileEvent.emit(this.selectedProfile);
    this.showSwitchProfileMenu = false;
  }

  onViewMorePage(): void {
    if (this.profileSearchText.value) {
      this.onViewMoreSearchPageProfile();
      return;
    }
    this.pageProfile$.pipe(take(1)).subscribe(res => {
      this.store.dispatch(
        getPageProfiles({
          pageNum: res?.data?.length <= PROFILE_PAGE_SIZE_DEFAULT ? PAGE_NUM_DEFAULT : res?.page + 1,
          pageSize: PAGE_SIZE_DEFAULT,
          searchText: this.profileSearchText.value
        })
      );
    });
  }

  onSearchPageProfile(): void {
    this.store.dispatch(updateLoadingState({ isLoading: true }));
    const searchText = this.profileSearchText?.value ?? '';

    this.fanpageService
      .getPageProfiles(PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, searchText)
      .pipe(finalize(() => this.store.dispatch(updateLoadingState({ isLoading: false }))))
      .subscribe({
        next: res => {
          this.profileSearchData = {
            data: res?.data?.data ?? [],
            totalElement: res?.data?.totalElement ?? 0,
            page: res?.data?.page ?? PAGE_NUM_DEFAULT
          };
        }
      });
  }

  onViewMoreSearchPageProfile(): void {
    this.store.dispatch(updateLoadingState({ isLoading: true }));
    const { page = 0, data = [] } = this.profileSearchData ?? {};
    const searchText = this.profileSearchText?.value ?? '';

    this.fanpageService
      .getPageProfiles(page + 1, PAGE_SIZE_DEFAULT, searchText)
      .pipe(finalize(() => this.store.dispatch(updateLoadingState({ isLoading: false }))))
      .subscribe({
        next: res => {
          if (res?.data?.data) {
            // Update the existing profile list when the user clicks "View more".
            this.profileSearchData = {
              ...this.profileSearchData,
              data: [...data, ...res.data.data],
              page: res.data.page,
              totalElement: res.data.totalElement
            };
          }
        }
      });
  }

  showVerifiedBadge(profile: any): boolean {
    return profile?.id === this.userInfo?.id ? profile?.is_verified_user : profile?.is_verified;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
