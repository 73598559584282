import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { UserProfile } from '@app/lib/api/user-profile/api.user-profile.models';
import { UserInfoActions } from '@app/modules/main/states/users/users.actions';
import { HOBBIES_KEY_LIST } from '@app/shared';
import { UserProfileResponse } from '@app/shared/models/user-profiles.model';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { TranslationService } from './translation.service';

export const LIMIT_IMAGE_TYPES = '.jpg, .jpeg, .png';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private api: ApiClient;
  private editState = new BehaviorSubject<boolean>(false);
  editStateChanged$ = this.editState.asObservable();
  HOBBY_VIEW_LIMIT = 5;

  apiUrl = environment.baseURL;
  userProfile: UserProfile;
  needUpdate: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private messageService: MessageService,
    private TranslationService: TranslationService
  ) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getUserProfile(userId: string): Observable<UserProfile> {
    return this.api.userProfile.getUserProfile(userId).pipe(
      map((userProfile: UserProfile) => {
        this.userProfile = userProfile;
        return userProfile;
      })
    );
  }

  updateUserProfile(newProfile: UserProfile): Observable<UserProfileResponse> {
    return this.api.userProfile.updateUserProfile(this.userProfile.id, newProfile).pipe(
      map(res => {
        if (res?.success) {
          const { first_name, last_name } = res?.data?.user_object || { first_name: '', last_name: '' };
          this.store.dispatch(UserInfoActions.onUpdateUserName({ first_name, last_name }));
          this.userProfile = res?.data;
        }
        return res;
      })
    );
  }

  uploadUserProfileAvatar(path: string): Observable<any> {
    return this.api.user.uploadUserAvatar(path).pipe(
      map(avatar_thumbnail_url => {
        this.store.dispatch(UserInfoActions.onUpdateAvatarUserInfo({ avatar_thumbnail_url }));
        return avatar_thumbnail_url;
      })
    );
  }

  uploadUserProfileCover(path: string): Observable<any> {
    const percentage_of_personal_profile = this.userProfile.percentage_of_personal_profile;
    return this.api.userProfile.uploadUserCover(this.userProfile, path).pipe(
      map(res => {
        this.userProfile = {
          ...this.userProfile,
          percentage_of_personal_profile,
          cover_url: res
        };
        return this.userProfile;
      })
    );
  }

  uploadUserProfileFrame(path: string): Observable<any> {
    return this.api.user.uploadUserFrame(path).pipe(
      map(res => {
        const frame_url = res;
        this.store.dispatch(UserInfoActions.onUpdateFrameUserInfo({ frame_url }));
        return res;
      })
    );
  }

  deleteUserProfileFrame(): Observable<string> {
    return this.api.user.deleteUserFrame().pipe(
      map(() => {
        const frame_url = '';
        this.store.dispatch(UserInfoActions.onUpdateFrameUserInfo({ frame_url }));
        return frame_url;
      })
    );
  }

  uploadUserAvatarOrCoverMedia(place: 'avatar' | 'cover', file: FormData): Observable<any> {
    return this.api.media.uploadUserAvatarOrCoverMedia(place, file);
  }

  uploadUserFrameMedia(file: FormData): Observable<any> {
    return this.api.media.uploadUserFrameMedia(file);
  }

  updateGroupAvatarOrCover(target_id: string, files: FormData): Observable<any> {
    return this.api.media.updateGroupAvatarOrCover(target_id, files);
  }

  uploadGroupAvatarOrCover(place: 'avatar' | 'cover', file: FormData, groupId: string | null): Observable<any> {
    return this.api.media.uploadGroupAvatarOrCover(place, file, groupId);
  }

  getHobbies(): Observable<string[]> {
    return of(HOBBIES_KEY_LIST);
  }

  setEditState(editState: boolean) {
    this.editState.next(editState);
  }

  fetchImageAsBase64(imageUrl: string): Promise<any> {
    return fetch(imageUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        return response.blob();
      })
      .then(blob => {
        return this.readFileAsBase64(blob);
      })
      .catch(error => {
        throw error;
      });
  }

  readFileAsBase64(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}
