<div *ngIf="data && data.poll_mode === pollMode.presentation" class="flex flex-col px-2 gap-1 pb-2">
  <span *ngIf="data.title" class="font-bold text-base">{{ data.title }}</span>
  <span *ngIf="data.description" class="text-sm">{{ data.description }}</span>
</div>
<div class="flex flex-col gap-3 pb-2 relative background-container" *ngIf="data">
  <ng-lottie
    *ngIf="data.background_image && isEndedPoll()"
    class="absolute z-[1] w-full"
    [options]="options"></ng-lottie>
  <ng-container *ngIf="!isEndedPoll()" [ngTemplateOutlet]="data.background_image ? voteWithBg : voteOptions">
  </ng-container>
  <ng-container
    *ngIf="isEndedPoll()"
    [ngTemplateOutlet]="data.background_image ? endVoteWithBg : endVoteView"></ng-container>
  <ng-container *ngIf="!isFullScreen">
    <div class="flex justify-between px-4">
      <ng-container *ngIf="data.poll_mode === pollMode.default">
        <div *ngIf="!isStartedPoll() && data.status === pollStatus.INCOMING">
          {{
            'POLL.START_TIME'
              | translate
                : {
                    time: getStartTime().time,
                    day: getStartTime().day,
                    month: getStartTime().month,
                    year: getStartTime().year
                  }
          }}
        </div>
        <div *ngIf="isStartedPoll() && !isEndedPoll() && isExpiredToday()">
          {{ 'POLL.EXPIRE_TODAY_TIME' | translate: { time: getEndTime().time } }}
        </div>
        <div *ngIf="isStartedPoll() && !isEndedPoll() && !isExpiredToday()">
          {{
            'POLL.EXPIRE_TIME'
              | translate
                : { time: getEndTime().time, day: getEndTime().day, month: getEndTime().month, year: getEndTime().year }
          }}
        </div>
      </ng-container>
      <ng-container *ngIf="data.poll_mode === pollMode.presentation && !isEndedPoll()">
        <div *ngIf="!data.priority_answer_id">{{ 'POLL.POLL_WILL_START_SOON' | translate }}</div>
        <div *ngIf="data.priority_answer_id">{{ answerInfo(pollResult, data.priority_answer_id)?.name }}</div>
      </ng-container>
      <div *ngIf="isEndedPoll()">{{ 'POLL.ENDED_POLL' | translate }}</div>
      <div class="flex gap-2 items-center" *ngIf="isAllowViewVoteResult">
        <div>
          {{
            (data.total_votes > 1 ? 'POLL.NUMBER_VOTES' : 'POLL.NUMBER_VOTE')
              | translate: { number: data.total_votes || 0 }
          }}
        </div>
        <ng-container *ngIf="data.voting_type === votingType.COINS">
          <i class="sctr-icon-solid-circle font-normal text-palette-gray-300 text-[4px]"></i>
          <span class="flex gap-[1px] items-center">
            <img src="/assets/icons/coin.svg" class="w-4 h-4" />
            <span class="font-normal text-palette-gray-500 text-sm">{{
              'XU.NUMBER_XU' | translate: { number: data.total_value }
            }}</span>
          </span>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!isSharedPost">
      <div class="px-4 flex flex-row w-full gap-2">
        <p-button
          class="w-full"
          checkAuthentication
          (openLoginPopup)="isOpenPopupLogin = $event"
          (onClick)="showOverviewPopup()"
          styleClass="w-full bg-palette-base-white text-palette-base-black border-palette-gray-400"
          [label]="'COMMON.BUTTON_ACTION.VIEW_DETAILS' | translate"></p-button>
        <p-button
          *ngIf="(data.poll_mode !== pollMode.presentation || data.start_date) && isPollOwner && !isEndedPoll()"
          class="w-full"
          (onClick)="isShowDownLoadQrCode = true"
          styleClass="w-full bg-palette-base-white text-palette-base-black border-palette-gray-400"
          [label]="'POLL.DOWNLOAD_QR_CODE' | translate"></p-button>
        <p-button
          *ngIf="data.poll_mode !== pollMode.presentation && data.voting_type === votingType.NORMAL && !isEndedPoll()"
          class="w-full"
          (onClick)="isShowConfirmPollPopup = true"
          [disabled]="!isStartedPoll() || this.totalSelectedVote() === 0"
          styleClass="w-full bg-palette-blue-600 text-palette-base-white border-palette-blue-600"
          [label]="'POLL.VOTE' | translate"></p-button>
        <p-button
          *ngIf="data.poll_mode === pollMode.presentation && isPollOwner && !isEndedPoll()"
          (onClick)="visiblePresentationControl = true"
          class="w-full"
          styleClass="w-full bg-palette-blue-600 text-palette-base-white border-palette-blue-600"
          [label]="'POLL.PRESENTATION_MODE' | translate"></p-button>
      </div>
      <p-button
        *ngIf="(data.poll_mode !== pollMode.presentation || !data.start_date) && isPollOwner && !isEndedPoll()"
        class="w-full px-4"
        (onClick)="isShowEndPollDialog = true"
        styleClass="w-full bg-palette-red-600 text-palette-base-white border-palette-red-600"
        [label]="'POLL.END_POLL' | translate"></p-button>
    </ng-container>
  </ng-container>
</div>

<ng-template #voteWithBg>
  <i
    *ngIf="!this.isPollView"
    (click)="expandPollView()"
    class="sctr-icon-maximize-01 z-30 cursor-pointer text-xl/[20px] text-palette-base-white icon-expand"></i>
  <i
    *ngIf="this.isPollView"
    (click)="expandPollView(true)"
    class="sctr-icon-minimize-01 z-30 cursor-pointer text-xl/[20px] text-palette-base-white icon-expand"></i>
  <div
    *ngIf="backgroundStyle"
    class="pb-4 flex items-end aspect-[588/946] w-full h-auto relative poll-container"
    [ngClass]="{ 'px-4': !isPostDetail }">
    <ng-container *ngIf="data.poll_mode === pollMode.default" [ngTemplateOutlet]="voteOptions"></ng-container>
    <ng-container
      *ngIf="data.poll_mode === pollMode.presentation && data.priority_answer_id"
      [ngTemplateOutlet]="presentationVoteOptions"></ng-container>
    <div
      [ngStyle]="backgroundStyle"
      class="aspect-[588/946] w-full h-auto absolute top-0 left-0 z-0 flex items-center justify-center">
      <div
        *ngIf="data.poll_mode === pollMode.presentation && !data.priority_answer_id"
        class="text-palette-base-white font-semibold text-2xl">
        {{ 'POLL.WAITING_START_POLL' | translate }}
      </div>
      <div
        *ngIf="data.poll_mode === pollMode.presentation && data.priority_answer_id"
        class="w-full aspect-[588/946] h-auto bg-palette-base-black opacity-70"></div>
    </div>
  </div>
</ng-template>

<ng-template #endVoteWithBg>
  <i
    *ngIf="!this.isPollView"
    (click)="expandPollView()"
    class="sctr-icon-maximize-01 z-30 cursor-pointer text-xl/[20px] text-palette-base-white icon-expand"></i>
  <i
    *ngIf="this.isPollView"
    (click)="expandPollView(true)"
    class="sctr-icon-minimize-01 z-30 cursor-pointer text-xl/[20px] text-palette-base-white icon-expand"></i>
  <div
    *ngIf="backgroundStyle"
    class="px-4 pb-4 flex flex-col gap-32 items-end aspect-[588/946]"
    [ngClass]="pollResult.length > 3 ? 'justify-end' : 'justify-center'">
    <post-content-poll-leader
      class="w-full z-[1]"
      [data]="data"
      *ngIf="backgroundStyle"
      [isAllowViewVoteResult]="isAllowViewVoteResult"></post-content-poll-leader>
    <ng-container *ngIf="pollResult.length > 3" [ngTemplateOutlet]="endVoteView"></ng-container>
    <div [ngStyle]="backgroundStyle" class="aspect-[588/946] w-full h-auto absolute top-0 left-0 z-0">
      <div class="aspect-[588/946] w-full h-auto bg-palette-base-black opacity-70"></div>
    </div>
  </div>
</ng-template>

<ng-template #voteOptions>
  <div class="flex flex-col justify-center items-center gap-2" *ngIf="!data.background_image">
    <div class="flex font-semibold text-lg" [ngClass]="{ 'text-center': data.background_image }">{{ data.title }}</div>
    <div *ngIf="noticeText" class="flex px-5 text-palette-red-600 italic max-w-[500px] text-center">
      {{ noticeText }}
    </div>
    <div
      class="flex flex-row gap-1 items-center w-fit mx-5 px-2 py-1 bg-palette-gray-300 rounded-full"
      [ngClass]="!isEndTimeCountdown ? 'bg-palette-gray-300' : 'bg-palette-orange-50 text-orange-600 '">
      <i class="sctr-icon-clock"></i>
      <ng-container [ngTemplateOutlet]="!isEndTimeCountdown ? intervalStartTime : intervalEndTime"></ng-container>
    </div>
  </div>
  <div
    class="flex flex-col rounded-lg gap-2 bg-palette-base-white w-full z-[1]"
    [ngClass]="{
      'p-5': data.background_image,
      'px-5 poll-container': !data.background_image,
      'mt-[500px]': data.background_image && isPostDetail
    }">
    <div
      *ngIf="data.background_image"
      class="font-semibold text-lg"
      [ngClass]="{ 'text-center': data.background_image }">
      {{ data.title }}
    </div>
    <div *ngIf="data.background_image && noticeText" class="px-5 text-palette-red-600 italic text-center">
      {{ noticeText }}
    </div>
    <div
      *ngIf="data.background_image"
      class="flex flex-row gap-1 items-center w-fit mx-auto px-2 py-1 bg-palette-gray-300 rounded-full"
      [ngClass]="!isEndTimeCountdown ? 'bg-palette-gray-300' : 'bg-palette-orange-50 text-orange-600 '">
      <i class="sctr-icon-clock"></i>
      <ng-container [ngTemplateOutlet]="!isEndTimeCountdown ? intervalStartTime : intervalEndTime"></ng-container>
    </div>
    <div *ngIf="data.description">{{ data.description }}</div>
    <div class="flex flex-col gap-2" [ngClass]="{ 'max-h-[325px] overflow-y-auto ': !isPostDetail }">
      <div
        class="flex border rounded-lg p-3 relative"
        [ngClass]="{
          'border-palette-blue-600 bg-palette-blue-50': item.is_voted,
          'border-palette-gray-300': !item.is_voted,
          'cursor-pointer': data.voting_type === votingType.COINS || !isStartedPoll(),
          'justify-between': !isStartedPoll(),
          'justify-evenly': isStartedPoll()
        }"
        *ngFor="let item of pollResult; let i = index"
        (click)="onClickOption(item, i)"
        checkAuthentication
        (openLoginPopup)="isOpenPopupLogin = $event">
        <div
          class="flex gap-3 items-center z-[1]"
          [ngClass]="{
            'w-4/5': data.voting_type === votingType.NORMAL && isAnyImageInList(),
            'w-full':
              data.voting_type === votingType.COINS || (data.voting_type === votingType.NORMAL && !isAnyImageInList())
          }">
          <div class="w-[52px] h-[52px]" *ngIf="isAnyImageInList()">
            <img
              class="h-full w-full object-cover"
              [src]="validUrl(item.image || '')"
              (error)="handleImageError($event, item.image)" />
          </div>
          <div
            class="flex flex-col gap-1"
            [ngClass]="{
              'w-[80%]': data.voting_type === votingType.NORMAL && isAnyImageInList(),
              'w-[384px]': data.voting_type === votingType.NORMAL && !isAnyImageInList(),
              'w-[87%]': data.voting_type === votingType.COINS && isAnyImageInList(),
              'w-full': data.voting_type === votingType.COINS && !isAnyImageInList()
            }">
            <div
              class="font-semibold text-base"
              [ngClass]="{
                'text-palette-blue-600': item.is_voted
              }">
              {{ item.name }}
            </div>
            <ng-container *ngIf="isStartedPoll()">
              <div class="flex gap-4 w-full items-center">
                <p-progressBar [showValue]="false" [value]="item.rate * 100" styleClass="h-1.5 w-full" class="w-full" />
                <span *ngIf="data.voting_type === votingType.COINS" class="text-sm font-medium w-10 text-end"
                  >{{ item.rate * 100 | roundedDecimalNumber: 1 }}%</span
                >
              </div>
              <div class="flex items-center gap-1">
                <ng-container *ngIf="isAllowViewVoteResult">
                  <div class="text-sm">
                    {{
                      (item.votes > 1 ? 'POLL.NUMBER_VOTES' : 'POLL.NUMBER_VOTE')
                        | translate: { number: quantityView(item.votes) }
                    }}
                  </div>
                  <i class="sctr-icon-solid-circle font-normal text-palette-gray-300 text-[4px]"></i>
                  <ng-container *ngIf="data.voting_type === votingType.COINS">
                    <span class="flex gap-[1px] items-center">
                      <img src="/assets/icons/coin.svg" class="w-4 h-4" />
                      <span class="font-normal text-palette-gray-500 text-sm">{{
                        'XU.NUMBER_XU' | translate: { number: quantityView(item.value || 0) }
                      }}</span>
                    </span>
                  </ng-container>
                </ng-container>
                <span *ngIf="data.voting_type === votingType.NORMAL" class="text-sm"
                  >{{ item.rate * 100 | roundedDecimalNumber: 1 }}%</span
                >
              </div>
            </ng-container>
          </div>
        </div>
        <!-- <div class="flex items-center gap-3 z-[1]" *ngIf="data.voting_type === votingType.COINS">
          <div class="flex" *ngIf="item.latest_votes.length">
            <ng-container *ngFor="let latestVote of item.latest_votes">
              <div class="-ml-2">
                <img class="w-5 h-5 rounded-full border border-palette-gray-50"
                  [src]="validUrl(latestVote.user_data ? latestVote.user_data.avatar_thumbnail_url : '')"
                  (error)="handleImageError($event)" />
              </div>
            </ng-container>
          </div>
        </div> -->
        <div
          *ngIf="isStartedPoll() && data.voting_type === votingType.NORMAL"
          class="flex items-center w-[100px] justify-center">
          <p-button
            *ngIf="currentVoteQuantity(item.answer_id) === 0"
            [disabled]="isDisabledVoteButton()"
            styleClass="text-palette-base-white bg-palette-blue-600 border-palette-blue-600 rounded-lg h-7"
            (onClick)="updateVote(item.answer_id, true)"
            >{{ 'COMMON.BUTTON_ACTION.CHOOSE' | translate }}</p-button
          >
          <div class="flex items-center gap-2" *ngIf="currentVoteQuantity(item.answer_id) !== 0">
            <p-button
              (onClick)="updateVote(item.answer_id, false)"
              styleClass="bg-palette-base-white border-palette-gray-300 flex justify-center items-center rounded-lg h-7 w-7"
              ><i class="sctr-icon-minus text-palette-base-black"></i
            ></p-button>
            <div class="text-sm font-medium w-5 text-center">{{ currentVoteQuantity(item.answer_id) }}</div>
            <p-button
              (onClick)="updateVote(item.answer_id, true)"
              [disabled]="totalSelectedVote() === totalVotesPerTime"
              styleClass="bg-palette-base-white border-palette-gray-300 flex justify-center items-center rounded-lg h-7 w-7 disabled:bg-palette-gray-300"
              ><i class="sctr-icon-plus text-palette-base-black"></i
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #presentationVoteOptions>
  <div class="flex flex-col justify-center items-center w-full gap-20 px-16 pb-6 z-[1]">
    <div class="w-full flex flex-col justify-center items-center gap-4">
      <div class="aspect-video flex items-center justify-center w-full h-[240px]">
        <img
          [src]="validUrl(answerInfo(pollResult, data.priority_answer_id || '')?.image || '')"
          class="bg-cover max-h-full border-2 rounded-sm"
          defaultImage />
      </div>
      <div class="text-palette-base-white font-medium text-3xl">
        {{ answerInfo(pollResult, data.priority_answer_id || '')?.name | uppercase }}
      </div>
    </div>
    <p-button
      checkAuthentication
      (openLoginPopup)="isOpenPopupLogin = $event"
      [disabled]="
        !!answerInfo(pollResult, data.priority_answer_id || '')?.is_voted && data.voting_type !== votingType.COINS
      "
      (onClick)="votePresentationMode(data.priority_answer_id || '')"
      styleClass="w-max bg-[#886ea3] text-3xl font-semibold text-palette-base-white border-palette-base-white rounded-full px-6"
      [label]="
        (!!answerInfo(pollResult, data.priority_answer_id || '')?.is_voted && data.voting_type !== votingType.COINS
          ? 'POLL.VOTED'
          : 'POLL.VOTE'
        )
          | translate
          | uppercase
      "></p-button>
  </div>
</ng-template>

<ng-template #endVoteView>
  <div class="bg-palette-base-white rounded-lg w-full py-2 flex flex-col gap-2 z-[1]">
    <div class="font-semibold text-lg px-5" [ngClass]="{ 'text-center': data.background_image }">{{ data.title }}</div>
    <div *ngIf="data.description" class="px-5">{{ data.description }}</div>
    <div class="flex flex-col max-h-[325px] overflow-y-auto gap-2">
      <div class="flex gap-3 w-full px-5 items-center" *ngFor="let item of withoutTop3List(); let i = index">
        <div class="bg-palette-gray-100 rounded-full justify-center w-7 h-7 flex items-center">
          {{ backgroundStyle ? i + 4 : i + 1 }}
        </div>
        <div *ngIf="isAnyImageInList()" class="w-10 h-10">
          <img [src]="validUrl(item?.image || '')" class="w-full h-full" defaultImage />
        </div>
        <div id="answer-info-container" class="flex justify-between w-full items-center">
          <span class="font-medium">{{ item.name }}</span>

          <div class="flex gap-1">
            <div class="flex gap-3" *ngIf="data.voting_type === votingType.NORMAL">
              <div class="flex">
                <ng-container *ngIf="isAllowViewVoteResult">
                  <span class="font-normal text-palette-gray-500 text-sm">{{
                    (item.votes > 1 ? 'POLL.NUMBER_VOTES' : 'POLL.NUMBER_VOTE') | translate: { number: item.votes }
                  }}</span>
                  <span class="px-1 text-palette-gray-500">&#x2022;</span>
                </ng-container>
                <span class="font-normal text-palette-gray-500 text-sm"
                  >{{ item.rate * 100 | roundedDecimalNumber: 1 }}%</span
                >
              </div>
              <div class="flex" *ngIf="item.latest_votes.length">
                <ng-container *ngFor="let latestVote of item.latest_votes">
                  <div class="-ml-2">
                    <img
                      class="w-5 h-5 rounded-full border border-palette-gray-50"
                      [src]="validUrl(latestVote.user_data ? latestVote.user_data.avatar_thumbnail_url : '')"
                      (error)="handleImageError($event)" />
                  </div>
                </ng-container>
              </div>
            </div>
            <span class="flex gap-[1px] items-center" *ngIf="data.voting_type === votingType.COINS">
              <ng-container *ngIf="isAllowViewVoteResult">
                <img src="/assets/icons/coin.svg" class="w-4 h-4" />
                <span class="font-normal text-palette-gray-500 text-sm">{{
                  'XU.NUMBER_XU' | translate: { number: item.value }
                }}</span>
                <span class="px-1 text-palette-gray-500">&#x2022;</span>
              </ng-container>
              <span class="text-sm font-medium w-10 text-end">{{ item.rate * 100 | roundedDecimalNumber: 1 }}%</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="visibleDetail">
  <poll-details-dialog
    [visible]="visibleDetail"
    (visibleChange)="onVisibleChange($event)"
    [pollData]="data"
    [isPollOwner]="isPollOwner"></poll-details-dialog>
</ng-container>

<p-dialog
  [(visible)]="isShowEndPollDialog"
  [modal]="true"
  (onHide)="isShowEndPollDialog = false"
  [dismissableMask]="true">
  <ng-template pTemplate="header">
    {{ 'POLL.CONFIRM_END_POLL_HEADER' | translate }}
  </ng-template>
  <div>{{ 'POLL.CONFIRM_END_POLL_DESCRIPTION' | translate }}</div>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
        (click)="isShowEndPollDialog = false"
        class="text-palette-base-black bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200 w-max"></button>
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
        (click)="endPoll()"
        class="text-palette-base-white bg-palette-blue-600 border-palette-blue-300 w-max"></button>
    </div>
  </ng-template>
</p-dialog>

<post-content-poll-qr-code [pollId]="postData.id" [(visible)]="isShowDownLoadQrCode"></post-content-poll-qr-code>

<p-dialog
  class="poll-noti-popup"
  [(visible)]="isShowNotiPopup"
  [modal]="true"
  (onHide)="isShowNotiPopup = false"
  [dismissableMask]="true">
  <ng-template pTemplate="header">
    <div class="text-palette-base-white">
      {{
        (infoType === infoTypeList.pollRules
          ? 'POLL.POLL_RULES'
          : infoType === infoTypeList.notStart
          ? 'POLL.POLL_COMING_SOON'
          : 'ENTITY.NOTIFICATION'
        ) | translate
      }}
    </div>
  </ng-template>
  <div class="text-base text-palette-base-black">{{ errorText }}</div>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <p-button
        label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
        (onClick)="isShowNotiPopup = false"
        styleClass="text-palette-base-white bg-palette-blue-600 border-palette-blue-300"></p-button>
    </div>
  </ng-template>
</p-dialog>

<manage-coins-dialog
  *ngIf="showManageCoinDialog"
  [(visible)]="showManageCoinDialog"
  [title]="'POLL.VOTE_BY_COINS'"
  [coinUsagePurpose]="'VOTE'"
  [coinRecipientData]="selectedAnswerData"
  [pollData]="data"
  (onSendCoinSuccessEvent)="onUpdateValue($event)"></manage-coins-dialog>

<div *ngIf="isOpenPopupLogin">
  <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
</div>

<ng-template #intervalStartTime>
  <ng-container *ngIf="countdownStartTime.days > 1">
    {{
      'POLL.START_IN'
        | translate
          : {
              time:
                ('POLL.DAYS' | translate: { number: countdownStartTime.days }) +
                ' ' +
                (countdownStartTime.time || '...')
            }
    }}
  </ng-container>
  <ng-container *ngIf="countdownStartTime.days === 1">
    {{
      'POLL.START_IN'
        | translate
          : {
              time:
                ('POLL.DAY' | translate: { number: countdownStartTime.days }) + ' ' + (countdownStartTime.time || '...')
            }
    }}
  </ng-container>
  <ng-container *ngIf="countdownStartTime.days === 0">
    {{ 'POLL.START_IN' | translate: { time: countdownStartTime.time || '...' } }}
  </ng-container>
</ng-template>

<ng-template #intervalEndTime>
  <ng-container *ngIf="countdownEndTime.days > 1">
    {{
      'POLL.END_IN'
        | translate
          : {
              time:
                ('POLL.DAYS' | translate: { number: countdownEndTime.days }) + ' ' + (countdownEndTime.time || '...')
            }
    }}
  </ng-container>
  <ng-container *ngIf="countdownEndTime.days === 1">
    {{
      'POLL.END_IN'
        | translate
          : {
              time: ('POLL.DAY' | translate: { number: countdownEndTime.days }) + ' ' + (countdownEndTime.time || '...')
            }
    }}
  </ng-container>
  <ng-container *ngIf="countdownEndTime.days === 0">
    {{ 'POLL.END_IN' | translate: { time: countdownEndTime.time || '...' } }}
  </ng-container>
</ng-template>

<p-dialog
  [(visible)]="visiblePresentationControl"
  [position]="'bottom-right'"
  [header]="'POLL.PRESENTATION_CONTROLLER' | translate"
  styleClass="w-[360px]">
  <div class="flex flex-col gap-4">
    <div class="grid grid-cols-2 gap-2 max-h-[352px] overflow-y-auto" *ngIf="isStartPresentationPoll">
      <div
        class="w-[156px] h-[172px] rounded-lg border cursor-pointer relative"
        [ngClass]="
          data.priority_answer_id === item.answer_id
            ? 'bg-palette-blue-50 border-palette-blue-600'
            : 'border-palette-gray-400'
        "
        *ngFor="let item of pollResult"
        (click)="updatePriorityAnswer(item.answer_id)">
        <div class="absolute w-[156px] justify-center flex pt-2" *ngIf="data.priority_answer_id === item.answer_id">
          <div class="rounded-2xl bg-palette-blue-600 text-palette-base-white w-max px-2 py-1">
            {{ 'POLL.DISPLAYING' | translate }}
          </div>
        </div>
        <div class="h-[116px] flex justify-center items-center rounded-t-lg bg-palette-base-black">
          <img defaultImage [src]="validUrl(item.image || '')" class="bg-cover max-h-full" />
        </div>
        <div
          class="flex justify-center items-center"
          [ngClass]="data.priority_answer_id === item.answer_id ? 'text-palette-blue-600' : ''">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <ng-container *ngIf="isStartPresentationPoll">
        <p-button
          (onClick)="turnToWaitingScreen()"
          class="w-full"
          styleClass="w-full bg-palette-base-white text-palette-base-black border-palette-gray-400"
          [label]="'POLL.TURN_TO_WAITING_SCREEN' | translate"></p-button>
        <p-button
          class="w-full"
          (onClick)="isShowEndPollDialog = true"
          styleClass="w-full bg-palette-red-600 text-palette-base-white border-palette-red-600"
          [label]="'POLL.END_POLL' | translate"></p-button>
      </ng-container>
      <p-button
        *ngIf="!isStartPresentationPoll"
        class="w-full"
        (onClick)="startPresentationPoll()"
        styleClass="w-full bg-palette-blue-600 text-palette-base-white border-palette-blue-600"
        [label]="'POLL.START_POLL' | translate"></p-button>
    </div>
  </div>
</p-dialog>

<p-dialog
  class="confirm-poll-popup"
  [(visible)]="isShowConfirmPollPopup"
  [modal]="true"
  (onHide)="isShowConfirmPollPopup = false"
  [dismissableMask]="true">
  <ng-template pTemplate="header">
    <div>{{ 'POLL.YOU_ARE_VOTING_FOR' | translate }}</div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-col">
      <div class="bg-palette-gray-100 rounded-lg flex flex-col justify-center gap-3">
        <ng-container *ngFor="let item of currentVotesList">
          <div
            *ngIf="answerInfo(pollResult, item.answerId) && item.quantity !== 0"
            class="ml-5 my-1 font-semibold truncate text-base">
            {{ answerInfo(pollResult, item.answerId)?.name }} -
            {{
              (item.quantity === 1 ? 'POLL.NUMBER_VOTE' : 'POLL.NUMBER_VOTES') | translate: { number: item.quantity }
            }}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <p-button
        label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
        (onClick)="isShowConfirmPollPopup = false"
        styleClass="text-palette-base-black bg-palette-base-white border-palette-gray-300"></p-button>
      <p-button
        label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
        (onClick)="votePoll()"
        styleClass="text-palette-base-white bg-palette-blue-600 border-palette-blue-300"></p-button>
    </div>
  </ng-template>
</p-dialog>
