import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface TimeContext {
  value: number;
  label: string;
  type: string;
}

const TIME_TYPES = {
  hour: 'HOUR',
  minute: 'MINUTE',
  period: 'PERIOD'
};

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() setMinutes = 5;
  @Input() disabled = false;
  @Input() visibleTimePicker = false;
  @Input() prefillTime: Date | null;
  @Input() isShowNowBtn = true;
  @Output() visibleTimePickerChange = new EventEmitter();
  @Output() pickedTime = new EventEmitter();

  formattedSelectedTime = '';
  pickedHour: TimeContext;
  pickedMinute: TimeContext;
  pickedPeriod: TimeContext;
  pickedHourTemp: TimeContext;
  pickedMinuteTemp: TimeContext;
  pickedPeriodTemp: TimeContext;

  // Mảng các giờ từ 01 đến 12
  hours: TimeContext[] = Array.from({ length: 12 }, (_, i) => {
    return { value: i, label: i ? i.toString().padStart(2, '0') : '12', type: TIME_TYPES.hour };
  });

  // Mảng các phút từ 00 đến 59
  minutes: TimeContext[] = Array.from({ length: 60 }, (_, i) => {
    return { value: i, label: i.toString().padStart(2, '0'), type: TIME_TYPES.minute };
  });

  periods: TimeContext[] = [
    { value: 0, label: 'COMMON.DATE_TIME.PERIOD.AM', type: TIME_TYPES.period },
    { value: 1, label: 'COMMON.DATE_TIME.PERIOD.PM', type: TIME_TYPES.period }
  ];

  timeTypes = TIME_TYPES;

  get pickedValidTime(): boolean {
    return this.pickedHour !== undefined && this.pickedMinute !== undefined && this.pickedPeriod !== undefined;
  }

  constructor(private translateService: TranslateService){
  }

  ngOnInit(): void {
    if (this.prefillTime) {
      this.setTime(this.prefillTime);
    }
  }

  onVisibleChange(value: boolean): void {
    this.visibleTimePicker = value;
    this.visibleTimePickerChange.emit(value);
    !value && this.cancelPickTime();
  }

  onSelectTime(timeContext: TimeContext): void {
    switch (timeContext.type) {
      case TIME_TYPES.hour:
        this.pickedHour = timeContext;
        break;
      case TIME_TYPES.minute:
        this.pickedMinute = timeContext;
        break;
      default:
        this.pickedPeriod = timeContext;
        break;
    }
    this.handlePickedTime();
  }

  handlePickedTime(): void {
    if (this.pickedValidTime) {
      this.formattedSelectedTime = `${this.pickedHour.label}:${this.pickedMinute.label} ${this.translateService.instant(this.pickedPeriod.label)}`;
      const selectedDateTime = new Date();
      selectedDateTime.setHours(!!this.pickedPeriod.value ? this.pickedHour.value + 12 : this.pickedHour.value);
      selectedDateTime.setMinutes(this.pickedMinute.value);
      selectedDateTime.setSeconds(0);
      this.pickedTime.emit(selectedDateTime);
      if (
        this.pickedHourTemp !== this.pickedHour &&
        this.pickedMinuteTemp !== this.pickedMinute &&
        this.pickedPeriodTemp !== this.pickedPeriod
      ) {
        this.visibleTimePicker = false;
        this.setTemporaryTimePicker();
      }
    }
  }

  setTemporaryTimePicker(): void {
    this.pickedHourTemp = this.pickedHour;
    this.pickedMinuteTemp = this.pickedMinute;
    this.pickedPeriodTemp = this.pickedPeriod;
  }

  cancelPickTime(): void {
    this.pickedHour = this.pickedHourTemp;
    this.pickedMinute = this.pickedMinuteTemp;
    this.pickedPeriod = this.pickedPeriodTemp;
    this.handlePickedTime();
    this.visibleTimePicker = false;
  }

  setCurrentTime(): void {
    const currentTime = new Date();
    this.setTime(currentTime);
  }

  setTime(customTime: Date): void {
    const customHour = customTime.getHours();
    const customMinute = customTime.getMinutes();
    this.pickedHour =
      this.hours.find(time => time.value === (customHour >= 12 ? customHour - 12 : customHour)) || this.pickedHour;
    this.pickedMinute = this.minutes.find(time => time.value === customMinute) || this.pickedMinute;
    this.pickedPeriod = customHour < 12 ? this.periods[0] : this.periods[1];
    this.handlePickedTime();
  }
}
