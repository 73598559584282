<div class="mx-auto h-full p-0.5 sm:mx-0 sm:shrink-0 flex justify-between">
  <a class="flex flex-1 items-center">
    <div class="flex cursor-pointer">
      <avatar *ngIf="isPeopleItem()" [avatarSize]="32" [userInfo]="userAvatar" />
      <img
        *ngIf="!isPeopleItem() && customData.avatarUrl"
        [src]="handleGetUrl()"
        alt="place-image"
        class="object-cover"
        [ngClass]="
          customData.type.name !== 'Places' ? 'w-12 h-12 rounded-full' : 'w-[60px] h-[60px] object-cover rounded-lg'
        "
        defaultImage />
      <ng-container *ngIf="!customData.avatarUrl">
        <p-avatar
          styleClass="w-12 h-12 {{
            customData.type.name === tags.FANPAGE
              ? 'text-palette-blue-600 bg-palette-blue-50'
              : 'bg-palette-amber-50 text-palette-amber-600'
          }} text-sm font-bold"
          shape="circle"
          [label]="customData.name | abbreviate: 'group'"></p-avatar>
      </ng-container>
    </div>
    <div class="ml-3 flex justify-center flex-col md:max-w-[400px]">
      <div class="flex flex-col">
        <p
          (click)="handleNavigate()"
          *ngIf="customData.name"
          class="three-dots font-semibold text-sm text-palette-gray-700 overflow-hidden overflow-ellipsis max-h-5 flex-nowrap cursor-pointer">
          {{ customData.name }}
          <span
            class="inline-flex translate-y-[3px] justify-center items-center relative w-[18px] h-[18px] mx-0.5"
            *ngIf="customData.is_verified_user || customData.is_verified_page">
            <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
            <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/4"></i>
          </span>
        </p>
        <ng-container [ngSwitch]="customData.type.name">
          <ng-container *ngSwitchCase="tags.GROUP">
            <p class="flex gap-1 items-center text-xs text-palette-gray-500 font-medium">
              <i
                class="text-palette-gray-500"
                [ngClass]="customData.privacy === PRIVACIES.public ? 'sctr-icon-globe-04' : 'sctr-icon-lock-02'"></i>
              <span>{{ customData.privacy }}</span>
              <span class="border-[3px] border-palette-gray-500 rounded-full"></span>
              <span
                >{{ customData.number_of_members }}
                {{
                  (customData.number_of_members === 1
                    ? 'SEARCH_CONTAINER.GROUP.MEMBER'
                    : 'SEARCH_CONTAINER.GROUP.MEMBERS'
                  ) | translate
                }}</span
              >
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="tags.FANPAGE">
            <p class="flex gap-1 items-center text-xs text-palette-gray-500 font-medium">
              <span>{{ customData.followers }}</span>
              <span>{{ customData.number_of_members }} {{ 'SEARCH_CONTAINER.FANPAGE.FOLLOWERS' | translate }}</span>
            </p>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="customData.type.name === tags.PEOPLE">
          <div
            class="md:flex text-palette-gray-500 items-center hidden"
            *ngFor="let item of userAvatar?.medal?.split(', ')">
            <p>&nbsp;•&nbsp;</p>
            <div class="text-sm">{{ item }}</div>
          </div>
        </ng-container>
      </div>
      <small class="text-palette-gray-500" *ngIf="customData.description">
        {{ customData.description }}
      </small>
    </div>
  </a>
  <button
    *ngIf="customData.type.name !== tags.PEOPLE && customData.type.name !== tags.PLACE && !isHiddenCtaButton"
    [disabled]="isLoadingFlow || isLoadingJoin"
    [icon]="isLoadingJoin || isLoadingFlow ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
    pButton
    (click)="handleActionCTAButton(customData.type, $event)"
    class="p-button-sm whitespace-nowrap border-none h-fit text-palette-blue-700 bg-palette-blue-50 font-semibold"
    [ngClass]="{
      'text-palette-gray-700 w-[160px] bg-palette-gray-100': !!customData.pending_request,
      'pointer-events-none cursor-default': isLoadingJoin
    }">
    <span>{{ handleGenerateStatus() }}</span>
  </button>
  <ng-content select="[badge-button]"></ng-content>
</div>
