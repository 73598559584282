import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { CommonService } from '@app/core/services/common.service';
import { EventService } from '@app/core/services/event.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UtilityService } from '@app/core/services/utility.service';
import { FanpageService } from '@app/modules/main/fanpage/service/fanpage.service';
import { selectFanpageFollower, selectFanpageRole } from '@app/modules/main/fanpage/store/selector';
import { selectMemberGroup } from '@app/modules/main/group/store/selectors';
import {
  FANPAGES,
  GROUP_VIEW_TABS,
  MAX_TRUNCATED_TEXT_HEIGHT,
  ROLES,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS,
  USER_ROLE
} from '@app/shared';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'event-detail',
  templateUrl: './event-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  isShowQrOverlay: boolean = false;
  @Input() private: boolean = true;
  @Input() user: boolean = true;
  @Input() isGroupEventDetail: boolean = false;
  @ViewChild('eventDetail', { static: false }) eventDetail: ElementRef;

  position: string;
  dialogStyle: object;
  eventPublic: string | null;
  eventUser: string | null;
  isButtonClickedAttend = false;
  isButtonClickedInterest = false;
  dataEventDetail: any;
  baseUrl = environment.baseURL;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  contentHeight = 0;
  isExpanded: boolean = false;
  today = new Date().getTime();
  isRoleFanpageView = localStorage.getItem('fanpageSelectedType') === FANPAGES.view;
  USER = USER_ROLE;
  eventIdDetail: string | null = null;
  linkToCopy = '';
  memberList: UserInfo[];
  visible: boolean = false;
  allFieldsFilled: boolean = false;
  objectId = '';
  showInviteFriendsDialog = false;
  isSubmitting = false;
  translatedStartMonth: string;
  translatedEndMonth: string;
  ownerOptions: any[];
  userOptions: any[];
  fanpageUserRole = '';
  showLoginPopup = false;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private fanpageService: FanpageService,
    private TranslationService: TranslationService,
    private toastMessageService: ToastMessageService,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private authService: AuthService
  ) {
    this.getMemberList();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.eventIdDetail = params.get('id');
    });
    this.setDialogStyle();
    window.addEventListener('resize', this.onResize.bind(this));
    if (this.eventIdDetail) {
      this.getEventDetail(this.eventIdDetail);
    }
    this.linkToCopy = `${environment.SOCIAL_APP_URL}${this.router.url}`;
    this.onLanguageChanged();
    this.initMenuOptions();
    this.store.select(selectFanpageRole).subscribe(res => {
      this.fanpageUserRole = res && res.user_role ? res.user_role : '';
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.eventDetail) {
        this.contentHeight = this.eventDetail.nativeElement.offsetHeight;
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  get isGroupEvent(): boolean {
    return this.router.url.startsWith('/group');
  }

  get isModeratorView(): boolean {
    return this.isGroupEventDetail
      ? this.dataEventDetail?.is_owner
      : [ROLES.OWNER, ROLES.ADMIN].includes(this.fanpageUserRole) ||
          (this.fanpageUserRole === ROLES.EDITOR && this.dataEventDetail?.is_owner);
  }

  initMenuOptions(): void {
    const baseOptions = [
      {
        icon: 'sctr-icon-copy-01 text-gray-700 text-lg',
        label: 'COMMON.COPY_LINK',
        action: () => {
          this.copyToClipboard();
        }
      }
    ];
    this.ownerOptions = baseOptions;
    this.userOptions = [
      {
        icon: 'sctr-icon-user-plus-02 text-gray-700 text-lg',
        label: 'COMMON.INVITE_FRIENDS',
        action: () => {
          this.authService.isLoggedIn ? this.onInviteFriend() : null;
          this.showLoginPopup = true;
        }
      },
      ...baseOptions
    ];
  }

  onLanguageChanged() {
    this.TranslationService.language$.subscribe(() => {
      this.translationStartMonth();
      this.translationEndMonth();
    });
  }

  loadEventImg(avatar: string): string {
    return this.baseUrl + this.fanpageService.addMediaExtension(avatar);
  }

  getEventDetail(eventIdDetail: string): void {
    this.eventService.getEventDetails(eventIdDetail).subscribe(res => {
      this.dataEventDetail = res;
    });
  }

  getMemberList(): void {
    this.store
      .pipe(
        select(selectMemberGroup),
        filter(item => item !== undefined),
        switchMap(item => {
          if (item !== undefined) {
            return this.store.pipe(select(selectMemberGroup));
          } else {
            return this.store.pipe(select(selectFanpageFollower));
          }
        })
      )
      .subscribe(res => {
        if (res?.data) {
          //group
          this.memberList = res?.data;
        } else {
          //follower
          this.memberList = res;
        }
      });
  }

  setDialogStyle(): void {
    if (window.innerWidth >= 768) {
      this.position = 'center';
      this.dialogStyle = { 'width': '488px' };
    } else {
      this.position = 'bottom';
      this.dialogStyle = { 'width': '100%', 'top': '82px', 'height': '100%', 'margin': '0px' };
    }
  }

  onResize(event: any) {
    this.setDialogStyle();
  }

  handleActionAttend(event: any): void {
    this.eventService.updateAttendEvent(event).subscribe((res: any) => {
      if (res.is_attended) {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_ENJOYED')
        );
      } else {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_LEFT_EVENT')
        );
      }
      if (this.eventIdDetail) {
        this.eventService.getEventDetails(this.eventIdDetail).subscribe(res => {
          this.dataEventDetail = res;
        });
      }
    });
  }

  handleInterest(event: any): void {
    this.eventService.updateInterestEvent(event).subscribe((res: any) => {
      if (res.is_interested) {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_ENJOYED')
        );
      } else {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_UNLIKE')
        );
      }
      if (this.eventIdDetail) {
        this.eventService.getEventDetails(this.eventIdDetail).subscribe(res => {
          this.dataEventDetail = res;
        });
      }
    });
  }

  handleEditEvent(event: any): void {
    const ownId = this.dataEventDetail.owner_id;
    const targetType = this.dataEventDetail.target_type;
    if (ownId) {
      const newData = { ...event, owner_id: ownId, target_type: targetType };
      this.isSubmitting = true;
      this.eventService.editEvent(newData).subscribe(res => {
        this.isSubmitting = false;
        if (res.success) {
          this.allFieldsFilled = false;
          this.visible = false;
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.success,
            'EVENT.MESSAGE.EDIT_EVENT_SUCCESSFULLY'
          );
          if (this.eventIdDetail) {
            this.getEventDetail(this.eventIdDetail);
          }
        } else {
          this.allFieldsFilled = true;
          if (res.error) {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.error,
              this.TranslationService.getTranslation(res.error.message)
            );
          }
        }
      });
    }
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.linkToCopy);
    this.toastMessageService.addToastMessage(
      TOAST_MESSAGE_SEVERITY_LEVELS.success,
      'GROUP.GROUP_LINK_COPIED',
      TOAST_MESSAGE_LIFETIME_DURATIONS.short
    );
  }

  onInviteFriend(): void {
    this.objectId = this.isGroupEvent ? this.router.url.split('/')[2] : localStorage.getItem('fanPageId') || '';
    this.showInviteFriendsDialog = true;
  }

  onBackToEventList(): void {
    const urlEventTab = GROUP_VIEW_TABS.events.label;
    if (this.isGroupEvent) {
      const groupId = this.router.url.split('/')[2];
      this.router.navigateByUrl(`group/${groupId}?Tab=${urlEventTab}`);
      localStorage.removeItem('groupDetailEvent');
    } else {
      const fanpageId = localStorage.getItem('fanPageId');
      this.router.navigateByUrl(`fanpage/${fanpageId}?Tab=${urlEventTab}`);
    }
  }

  translationStartMonth(): void {
    const month = new Intl.DateTimeFormat('en', { month: 'long' })
      .format(this.dataEventDetail?.start_date_time)
      .toUpperCase()
      .substring(0, 3);
    this.translatedStartMonth = this.TranslationService.getTranslation(`COMMON.DATE_TIME.MONTH.${month}`);
  }

  translationEndMonth(): void {
    const month = new Intl.DateTimeFormat('en', { month: 'long' })
      .format(this.dataEventDetail?.end_date_time)
      .toUpperCase()
      .substring(0, 3);
    this.translatedEndMonth = this.TranslationService.getTranslation(`COMMON.DATE_TIME.MONTH.${month}`);
  }

  navigateToProfile(member: any): void {
    const username = member?.user_info?.username || '';
    const userId = member?.user_info?.id || '';
    this.router.navigate([`/personal-profile/${username || userId}`]);
  }
  formatUrl(link: string | null): string {
    if (!link || typeof link !== 'string') return '#';
    const formattedUrl = this.utilityService.ensureURLProtocol(link);
    return this.utilityService.isWebsite(formattedUrl) ? formattedUrl : '#';
  }
}
