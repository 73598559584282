import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const LoyaltyActions = createActionGroup({
    source: 'loyalty',
    events: {
        'On Get Membership Profiles': emptyProps(),
        'On Get Default Loyalty List': emptyProps(),
        'On Get Loyalty List By Program Id': props<{ id: string }>()
    }
});

export const LoyaltyApiActions = createActionGroup({
    source: 'loyalty Api',
    events: {
        'On Get Membership Profiles Success': props<{ data: any }>(),
        'On Get Membership Profiles Fail': props<{ message: any }>(),
        'On Get List Rank Success': props<{ loyaltyList: any[] }>(),
        'On Get List Rank Fail': props<{ message: any }>()
    }
});