<p-dialog
  class="poll-details-dialog"
  header="{{ 'POLL.POLL_DETAILS' | translate }}"
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [style]="{ width: '1000px', maxHeight: '900px' }"
  (onShow)="onShowDialog()"
  (onHide)="onHideDialog()">
  <div id="poll-details-container" *ngIf="pollOverview">
    <p-tabView class="overview-poll-tabview">
      <p-tabPanel header="{{ 'POLL.OVERVIEW' | translate }}">
        <div class="w-full flex flex-col gap-4">
          <ng-container *ngFor="let answer of pollOverview">
            <ng-template
              [ngTemplateOutlet]="answerInfo"
              [ngTemplateOutletContext]="{
                data: answer
              }"></ng-template>
          </ng-container>
        </div>
      </p-tabPanel>
      <p-tabPanel header="{{ 'POLL.ACTIVITIES' | translate }}" *ngIf="isAllowViewActivitiesTab">
        <div class="w-full flex flex-col gap-6">
          <ng-container *ngFor="let item of pollActivitiesArray">
            <ng-template
              [ngTemplateOutlet]="answerActivities"
              [ngTemplateOutletContext]="{
                data: item
              }"></ng-template>
          </ng-container>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-dialog>

<ng-template #answerInfo let-data="data">
  <div class="flex gap-3 w-full">
    <img
      *ngIf="data?.image"
      [src]="validUrl(data.image)"
      alt="Answer image"
      class="w-20 h-20 rounded aspect-square object-cover" />
    <div id="answer-info-container" class="flex flex-col gap-[2px] w-full">
      <span class="font-medium">{{ data?.name }}</span>
      <div class="flex">
        <div id="answer-progress-container" class="flex justify-between items-center w-full">
          <span class="answer-progress-bar">
            <p-progressBar [showValue]="false" [value]="data?.rate * 100" />
          </span>
        </div>
        <span class="text-sm font-medium w-10 text-end">{{ data?.rate * 100 | roundedDecimalNumber }}%</span>
      </div>

      <div id="vote-coin-container" class="flex gap-2 items-center" *ngIf="isAllowViewVoteResult">
        <span class="font-normal text-palette-gray-500 text-sm"
          >{{ data?.votes }} {{ 'POLL.VOTES' | translate | lowercase }}</span
        >
        <ng-container *ngIf="pollData.voting_type === votingType.COINS">
          <i class="sctr-icon-solid-circle font-normal text-palette-gray-300 text-[8px]"></i>
          <span class="flex gap-[1px] items-center">
            <img src="/assets/icons/coin.svg" class="w-4 h-4" />
            <span class="font-normal text-palette-gray-500 text-sm">{{ data?.value }}</span>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #answerActivities let-data="data">
  <div class="w-full flex flex-col gap-3">
    <div id="answer-activities-header" class="flex justify-between items-center">
      <div
        id="answer-activities-header-info-section"
        class="flex gap-2 items-center cursor-pointer"
        (click)="onChangeDetailsSectionVisible(data?.answer_id, data?.showDetails)">
        <i
          class="text-sm text-palette-gray-500 sctr-icon-chevron-right activities-show-detail-button"
          [ngClass]="{
            'rotate-0': !data?.showDetails,
            'rotate-90': data?.showDetails
          }"></i>

        <span class="text-sm font-semibold text-palette-gray-900">{{ data?.name }}</span>
        <i class="sctr-icon-solid-circle font-normal text-palette-gray-300 text-[6px]"></i>
        <span class="activities-normal-text">{{ data?.votes }} {{ 'POLL.VOTES' | translate | lowercase }}</span>
        <ng-container *ngIf="pollData.voting_type === votingType.COINS">
          <i class="sctr-icon-solid-circle font-normal text-palette-gray-300 text-[6px]"></i>
          <img src="/assets/icons/coin.svg" class="w-4 h-4" />
          <span class="activities-normal-text">{{ data?.value }}</span>
        </ng-container>
      </div>
      <span class="text-sm text-palette-blue-600 font-normal hidden">
        {{ 'ADVERTISING.CAMPAIGN_LIST_SECTION.EXPORT' | translate }}
      </span>
    </div>
    <p-table
      *ngIf="data?.showDetails"
      class="activities-table"
      [alwaysShowPaginator]="false"
      [value]="data?.details"
      [paginator]="true"
      [rows]="5"
      [totalRecords]="data?.totalElement"
      (onPage)="onPagingChange($event, data?.answer_id)">
      <ng-template pTemplate="header">
        <tr class="bg-palette-gray-100 h-10">
          <th *ngFor="let header of tableHeaders">
            <span class="text-palette-gray-900 font-medium text-sm"
              >{{ 'POLL.TABLE_HEADERS.' + header | translate }}
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr>
          <td *ngFor="let key of tableKeys; let i = index">
            <span *ngIf="key === 'created_at'">{{ item[key] | dateFormat: true }}</span>
            <span *ngIf="key !== 'created_at'" class="activities-normal-text">{{ item[key] }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>
