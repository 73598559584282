import { Component, HostListener, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { showDownloadPopup } from '@soctrip-common/route-transform-lib';
import { AppConnectMessageType } from './core/enums/app-connect.enum';
import { AuthService } from './core/services/auth.service';
import { BillingService } from './core/services/billing.service';
import { DeviceService } from './core/services/device.service';
import { PixelAnalyticsService } from './core/services/pixel-analytics.service';
import { TranslationService } from './core/services/translation.service';
import { UserInfoActions } from './modules/main/states/users/users.actions';
@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  show: any;
  isEmptyLayout: boolean;
  isInitTranslate = false;

  constructor(
    private authService: AuthService,
    private billingService: BillingService,
    private readonly pixelAnalyticsService: PixelAnalyticsService,
    private store: Store,
    public deviceService: DeviceService,
    private ngZone: NgZone,
    private translateService: TranslateService,
    private translationService: TranslationService
  ) {
    // Open soctrip app when user uses mobile browser.
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
    //   if (event instanceof NavigationEnd && this.deviceService.isMobile()) {
    //     const currentUrl = window.location.href;
    //     transform(currentUrl, environment.baseURL).then(universalRoute => {
    //       this.deviceService.tryOpenApp(universalRoute ?? environment.SOCIAL_APP_DEEPLINK);
    //     });
    //   }
    // });
    if (this.deviceService.isMobile()) {
      let lang = environment.languages[0];
      this.translateService.addLangs(environment.languages);
      const browserLang = this.translateService.getBrowserLang() || lang;
      const selectedLang = this.translationService.getKeyLocalUserProfile('language')?.toLowerCase();
      if (selectedLang && environment.languages.includes(selectedLang)) {
        lang = selectedLang;
      } else if (!selectedLang && environment.languages.includes(browserLang)) {
        lang = localStorage.getItem('unauthLang') || (environment.languages.includes(browserLang) ? browserLang : lang);
      }
      const currentUrl = window.location.href;
      showDownloadPopup(lang, currentUrl, environment.baseURL);
    }

    this.ngZone.runOutsideAngular(() => {
      this.pixelAnalyticsService.initialize();
    });
    // Set currency and language for anonymous when init
    this.authService.getDeviceInfo().subscribe(res => {
      this.store.dispatch(UserInfoActions.onGetCurrentRegion({ countryCode: res?.country_code || '' }));
      const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
      if (!isLoggedIn) {
        const currency = localStorage.getItem('currency-conversation-data');
        const language = localStorage.getItem('unauthLang');
        if (!currency || !language) {
          if (!currency) {
            this.billingService.getExchangeRateFromUSD(res.currency).subscribe(r => {
              if (res) {
                const currencyData = {
                  rate: r.exchange_rate,
                  currency: res.currency,
                  expiry: r.expiry_timestamp,
                  hash: res.currency + r.exchange_rate + r.expiry_timestamp
                };
                localStorage.setItem('currency-conversation-data', JSON.stringify(currencyData));
              }
            });
          }
          if (!language) localStorage.setItem('unauthLang', res.language_code.toLowerCase());
        }
      }
    });
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (event.origin === environment.CONTENT_APPROVAL_URL) {
      switch (event.data.type) {
        case AppConnectMessageType.ADD_OWNER_USER_ID:
          if (!event.data.ownerUserId || !event.data.tokenToLoadIframe) {
            event.source.postMessage(
              {
                type: AppConnectMessageType.ADD_OWNER_USER_ID_FAIL
              },
              { targetOrigin: event.origin }
            );
          } else {
            sessionStorage.setItem(environment.OWNER_USER_ID, event.data.ownerUserId);
            sessionStorage.setItem(
              environment.TOKEN_KEY,
              JSON.stringify({ accessToken: event.data.tokenToLoadIframe })
            );
            event.source.postMessage(
              {
                type: AppConnectMessageType.ADD_OWNER_USER_ID_SUCCESS
              },
              { targetOrigin: event.origin }
            );
          }
          break;
        default:
          break;
      }
    }
  }
}
