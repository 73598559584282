import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { LoyaltyService } from '@app/core/services/loyalty.service';
import { PostsService } from '@app/core/services/posts.service';
import { UserBadgeService } from '@app/core/services/user-badge.service';
import { LoyaltyActions } from '@app/modules/main/states/loyalty-hub/loyalty-hub.action';
import { selectLoyaltyList } from '@app/modules/main/states/loyalty-hub/loyalty-hub.selector';
import { DEFAULT_QUICK_REPORT_TOTAL } from '@app/shared/constant';
import { QuickReportModel } from '@app/shared/models/post';
import { ListQuickSummary } from '@app/shared/models/quick-summary';
import { UserInfo } from '@app/shared/models/user';
import { UserBadge } from '@app/shared/models/user-badge.model';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as htmlToImage from 'html-to-image';
import * as moment from 'moment';
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnChanges {
  avatarUrl: string = 'assets/images/default_user_avatar.png';
  frameUrl: string = '';
  overlayVisible = false;
  listQuickSummary = ListQuickSummary;
  updateSizeNeeded: boolean = false;
  dateLabel: string;
  Object = Object;
  moment = moment;
  isLoggedIn = false;
  isConverting = false;
  quickReportTotal = DEFAULT_QUICK_REPORT_TOTAL;
  userBadge: UserBadge[];
  iconRank: string;
  colorRing: string;
  loyaltyId: string;
  programId: string;

  @Input() userInfo: UserInfo;
  @Input() dialogVisible = false;
  @Input() selectedReport: QuickReportModel;
  @Output() selectedReportChange = new EventEmitter();
  @Output() dialogVisibleChange = new EventEmitter();
  @ViewChild('container') container: ElementRef;
  @ViewChild('child') child: ElementRef;
  @ViewChild('captureContainer') captureContainer: ElementRef;
  @Output() capture = new EventEmitter<string>();
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private store: Store,
    private userBadgeService: UserBadgeService,
    private commonService: CommonService,
    private loyaltyService: LoyaltyService,
    private postsService: PostsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['userInfo'] && changes['userInfo'].currentValue) {
      const userInfo = changes['userInfo'].currentValue;
      this.avatarUrl = this.commonService.getImageUrl(userInfo && userInfo.avatar_thumbnail_url);
      this.frameUrl = this.commonService.getImageUrl(userInfo && userInfo.frame_url);
      this.getUserBadge();
    }
    if (changes && changes['customReport'] && changes['customReport'].currentValue) {
      this.getQuickReport();
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('quickReportTotal')) {
      this.quickReportTotal = JSON.parse(localStorage.getItem('quickReportTotal') || DEFAULT_QUICK_REPORT_TOTAL.toString());
    }
    this.avatarUrl = this.commonService.getImageUrl(this.userInfo && this.userInfo.avatar_thumbnail_url);
    this.frameUrl = this.commonService.getImageUrl(this.userInfo && this.userInfo.frame_url);
    const authStatus = JSON.parse(localStorage.getItem('auth_status') || '[]');
    this.isLoggedIn = authStatus.isLoggedIn;
    this.getQuickReport();
    this.store.select(selectLoyaltyList).subscribe((res) => {
      if (!res.length && (this.iconRank || this.colorRing)) {
        return;
      }
      const currentLoyalty = res.find((el: any) => el.id === this.loyaltyId);
      if (currentLoyalty) {
        this.iconRank = currentLoyalty.image;
        this.colorRing = currentLoyalty.avatar_outline_stroke;
      }
    });

  }

  getImgUrl(id: string | undefined): string {
    return this.commonService.getImageUrl(id);
  }

  getQuickReport() {
    this.postsService.getQuickReport().subscribe((res: QuickReportModel) => {
      this.selectedReport = res;
      this.selectedReportChange.emit(this.selectedReport);
    });
  }

  navigateToProfile(): void {
    this.router.navigate(['/personal-profile', this.userInfo?.username || this.userInfo?.id]);
  }

  ngAfterViewChecked(): void {
    this.resizeCaptureContainer();
  }

  getUserBadge() {
    const userId = this.userInfo?.username || this.userInfo?.id;
    if (userId) {
      this.userBadgeService.getBrand(userId).subscribe((item) => {
        if (!item?.success) {
          return;
        } 
        const loyaltyProfile = JSON.parse(localStorage.getItem('loyaltyProfile')|| '[]');
        
        this.iconRank = loyaltyProfile.data?.user_rank?.image;
        this.colorRing = loyaltyProfile.data?.user_rank?.avatar_outline_stroke;
        this.userBadge = this.userBadgeService.getUserBadge(item.data);
      });
    }
  }

  getAvatar(userInfo: any): void {
    const hasAvatarUrl = userInfo && (userInfo.avatar_url || userInfo.avatar_thumbnail_url);
    this.avatarUrl = hasAvatarUrl
      ? `${environment.baseURL}${userInfo.avatar_thumbnail_url ? userInfo.avatar_thumbnail_url : userInfo.avatar_url}`
      : this.avatarUrl;
  }

  openOverlayCustomSummary(): void {
    this.overlayVisible = !this.overlayVisible;
  }

  openDialogCustomSummary(): void {
    this.overlayVisible = false;
    this.dialogVisibleChange.emit(true);
  }

  resizeCaptureContainer(): void {
    const childHeight = this.child.nativeElement.offsetHeight;
    //This is the initial height of the parent div tag
    const initialHeightContainer = 215;
    const newContainerHeight = childHeight + initialHeightContainer;
    this.renderer.setStyle(this.captureContainer.nativeElement, 'height', newContainerHeight + 'px');
  }

  screenShot(): void {
    const options = {
      canvasWidth: this.captureContainer.nativeElement.offsetWidth * 5,
      canvasHeight: this.captureContainer.nativeElement.offsetHeight * 5
    };
    this.isConverting = true;
    htmlToImage.toPng(this.captureContainer.nativeElement, options).then(dataUrl => {
      if (dataUrl !== '') {
        this.capture.emit(dataUrl);
        this.isConverting = false;
      }
    });
  }
}
