<div class="otp-form">
  <div
    class="rounded-lg border border-solid px-3 py-3.5 w-full border-system-error-300 mb-4 flex gap-2 items-center"
    *ngIf="errorMessage">
    <i class="sctr-icon-alert-circle text-system-error-700 font-semibold text-sm"></i>
    <span class="font-semibold text-sm text-system-error-700">{{ errorMessage }}</span>
  </div>
  <div class="flex justify-between mb-3 text-sm w-full items-center">
    <span class="font-medium text-gray-700">{{ 'SIGNIN_SIGNUP.SIGNUP.VERIFICATION_CODE' | translate }}</span>
    <span *ngIf="countdown !== 0"
      >{{ 'SIGNIN_SIGNUP.FORGOT_PASSWORD.RESEND_CODE_IN' | translate }} <b>{{ countdown | countdownFormat }}</b></span
    >
    <p-button
      *ngIf="countdown === 0"
      styleClass="p-button-text"
      (click)="onResendCode()"
      [label]="'SIGNIN_SIGNUP.SIGNUP.RESEND_CODE' | translate"></p-button>
  </div>
  <form [formGroup]="otpForm" class="verification-form">
    <input
      *ngFor="let digit of otpDigits; let i = index"
      pInputText
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      class="verification-input"
      [id]="'verification-code-input-' + (i + 1)"
      [formControlName]="'digit' + (i + 1)"
      [readonly]="shouldDisableInputCode(i + 1)"
      maxlength="1"
      placeholder="-"
      [appOtpPaste]="otpForm"
      (input)="onInputChange(i + 1)" />
  </form>

  <span class="flex justify-center font-normal gap-1 text-sm items-center mt-6"
    >{{ 'SIGNIN_SIGNUP.SIGNUP.BACK_TO' | translate }}
    <a routerLink="/login" class="no-underline font-semibold text-branding-primary-700 text-base cursor-pointer">{{
      'SIGNIN_SIGNUP.SIGN_IN' | translate
    }}</a></span
  >
</div>
