import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {
  transform(value: number | null, includeMilliseconds = false): string {
    if (value == null || value < 0) return '';

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);
    let milliseconds = Math.floor((value % 1) * 1000);

    if (includeMilliseconds) {
      milliseconds = Math.round(milliseconds);
    }

    const minutesStr = `${minutes < 10 ? '0' : ''}${minutes}`;
    const secondsStr = `${seconds < 10 ? '0' : ''}${seconds}`;

    const timeStr = hours > 0 ? `${hours}:${minutesStr}:${secondsStr}` : `${minutesStr}:${secondsStr}`;

    return includeMilliseconds && milliseconds > 0 ? `${timeStr}.${milliseconds.toString().padStart(3, '0')}` : timeStr;
  }
}
