<div
  class="flex flex-col justify-center items-center my-4"
  [ngClass]="isNotFoundInSearchHeader?'py-20': ''">
  <div class="rounded-full bg-palette-blue-50 mb-3" [ngClass]="isNotFoundInSearchHeader ? 'py-3 px-3' : 'py-[14px] px-4'">
    <div class="rounded-full bg-palette-blue-100" [ngClass]="isNotFoundInSearchHeader ? 'py-[5px] px-2' : 'py-[14px] px-4'">
      <i class="sctr-icon-search-lg cursor-pointer leading-9 text-palette-blue-600" [ngClass]="isNotFoundInSearchHeader ? 'text-3xl leading-[30px]' : 'text-4xl'"></i>
    </div>
  </div>
  <p [ngClass]="isNotFoundInSearchHeader ? 'text-base' : 'text-xs'">{{ message |  translate }}</p>
</div>
