<div class="max-h-full overflow-y-auto scroll-bar" #eventsView (scroll)="onScroll()">
  <div *ngFor="let items of pointHistory; let i = index">
    <div class="flex justify-between my-2 mr-[10px]">
      <div class="flex flex-col gap-2">
        <p class="text-gray-900 font-medium text-[14px] leading-5">{{ items.description }}</p>
        <p class="text-gray-400 font-regular text-sm">{{ items.created_at | dateFormatPipe: true }}</p>
      </div>
      <span class="flex items-center {{ items.points > 0 ? 'text-palette-blue-600' : 'text-palette-red-600'}}">
        {{ (items.points > 0 ? '+' : '') + items.points}}
      </span>
    </div>
    <hr class="{{ pointHistory.length - 1 !== i ? '': 'invisible' }}" />
  </div>
  <ng-container *ngIf="isLoading">
    <div *ngFor="let _ of [].constructor(5)">
      <p-skeleton width="30rem" height="3.5rem" styleClass="mb-2" />
    </div>
  </ng-container>
</div>