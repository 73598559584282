<p-dialog
  id="manage-coins-dialog"
  header="{{ title | translate }}"
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [style]="{ width: dialogWidth }"
  (onHide)="visibleChange.emit(false)">
  <ng-template pTemplate="header">
    <ng-container *ngIf="paymentStep === paymentProcessStep.selectPaymentMethod; else header">
      <div class="flex items-center gap-2">
        <i
          (click)="paymentStep = paymentProcessStep.selectCoinAmount"
          class="sctr-icon-chevron-left text-lg text-palette-gray-900 cursor-pointer"></i>
        <span class="text-lg text-palette-gray-900 font-semibold">{{ 'POLL.CHECKOUT_VIA_SOCTRIP' | translate }}</span>
      </div>
    </ng-container>
    <ng-template #header>
      <span class="text-lg text-palette-gray-900 font-semibold">{{ title | translate }}</span>
    </ng-template>
  </ng-template>
  <ng-container [ngSwitch]="coinUsagePurpose" id="manage-coins-dialog-content">
    <ng-container *ngIf="paymentStep === paymentProcessStep.selectCoinAmount">
      <!-- Vote by coins content dialog-->
      <div *ngSwitchCase="'VOTE'" class="flex flex-col gap-3">
        <ng-container
          id="recipient-info-section"
          [ngTemplateOutlet]="voteCoinsRecipientInfo"
          [ngTemplateOutletContext]="{
            data: coinRecipientData
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="totalCoinsSection"></ng-container>
        <div
          *ngIf="availableCoinAmounts"
          id="coin-amount-selections"
          class="flex gap-3 items-center flex-wrap w-full justify-between">
          <ng-container
            *ngFor="let item of availableCoinAmounts"
            [ngTemplateOutlet]="voteCoinAmountSelection"
            [ngTemplateOutletContext]="{ data: item }">
          </ng-container>
        </div>
        <div *ngIf="!availableCoinAmounts" class="flex items-center justify-between gap-3 flex-wrap">
          <ng-container *ngFor="let item of [].constructor(6)">
            <p-skeleton class="w-[102px] rounded-lg border" height="2.75rem"></p-skeleton>
          </ng-container>
        </div>
        <ng-container
          [ngTemplateOutlet]="coinNeedToTopUp"
          [ngTemplateOutletContext]="{
            title: 'POLL.COINS_NEEDS_TO_TOP_UP_VOTING'
          }"></ng-container>
      </div>

      <!-- Topup content dialog-->
      <div *ngSwitchCase="'TOPUP'" class="grid gap-3 p-2">
        <ng-container [ngTemplateOutlet]="totalCoinsSection"></ng-container>
        <div class="w-full h-11 px-4 py-3 flex items-center gap-[10px] bg-palette-blue-50 rounded-lg">
          <i class="sctr-icon-solid-star-06 text-lg text-palette-blue-600"></i>
          <span class="font-medium text-palette-gray-900 text-sm">
            {{ 'POLL.ADD_XU_DESCRIPTION' | translate }}
          </span>
        </div>
        <div *ngIf="availableCoinAmounts" class="grid grid-cols-3 gap-3 w-full mx-auto">
          <ng-container
            *ngFor="let item of availableCoinAmounts"
            [ngTemplateOutlet]="topUpCoinAmountSelection"
            [ngTemplateOutletContext]="{ data: item }">
          </ng-container>
        </div>
        <div *ngIf="!availableCoinAmounts" class="grid grid-cols-3 gap-3">
          <ng-container *ngFor="let item of [].constructor(6)">
            <p-skeleton class="w-[102px] h-[88px] rounded-lg border"></p-skeleton>
          </ng-container>
        </div>
      </div>

      <!--Sending coin content dialog-->
      <div *ngSwitchCase="'SEND'" class="flex flex-col gap-3">
        <ng-container [ngTemplateOutlet]="sendCoinsRecipientInfo"></ng-container>
        <ng-container [ngTemplateOutlet]="totalCoinsSection"></ng-container>
        <div
          *ngIf="availableCoinAmounts"
          id="coin-amount-selections"
          class="flex gap-3 items-center flex-wrap w-full justify-between">
          <ng-container
            *ngFor="let item of availableCoinAmounts"
            [ngTemplateOutlet]="voteCoinAmountSelection"
            [ngTemplateOutletContext]="{ data: item }">
          </ng-container>
        </div>
        <div *ngIf="!availableCoinAmounts" class="flex items-center justify-between gap-3 flex-wrap">
          <ng-container *ngFor="let item of [].constructor(6)">
            <p-skeleton class="w-[102px] rounded-lg border" height="2.75rem"></p-skeleton>
          </ng-container>
        </div>
        <ng-container
          [ngTemplateOutlet]="coinNeedToTopUp"
          [ngTemplateOutletContext]="{
            title: 'POLL.COINS_NEEDS_TO_TOP_UP_SENDING'
          }"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="paymentStep === paymentProcessStep.selectPaymentMethod">
      <div class="flex flex-col gap-6">
        <div id="coins-order-summary-section" class="flex flex-col gap-3">
          <span class="text-palette-gray-900 font-semibold text-base"> {{ 'POLL.ORDER_SUMMARY' | translate }}</span>
          <span class="w-full h-14 px-[10px] py-3 rounded-lg bg-palette-gray-100 flex justify-between items-center">
            <div class="flex gap-3 items-center">
              <img src="/assets/icons/coin.svg" alt="" class="w-8 h-8" />
              <span class="text-sm font-semibold text-palette-gray-900">
                {{ 'POLL.NUMBER_OF_XU' | translate: { number: coinAmountNeedToTopUp?.amountCoin } }}</span
              >
            </div>
            <span class="text-sm font-medium text-palette-gray-500">
              {{ coinAmountNeedToTopUp?.price | currencyConversion }}</span
            >
          </span>
        </div>
        <div *ngIf="isWaitingPaymentProgress" class="flex gap-2">
          <div class="text-base">{{ 'COIN.PROCESSING_PAYMENT' | translate }}</div>
          <p-progressSpinner
            class="self-end justify-self-center"
            styleClass="w-[24px] h-[24px]"
            fill="var(--surface-ground)"
            animationDuration=".5s" />
        </div>
        <div *ngIf="!isWaitingPaymentProgress" id="payment-section" class="flex flex-col gap-3">
          <span class="text-base font-semibold text-palette-gray-900">
            {{ 'POLL.LOCAL_PAYMENT_GATEWAY' | translate }}</span
          >
          <iframe id="payment-iframe" [src]="paymentUrl" class="payment-section" allowfullscreen> </iframe>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template pTemplate="footer">
    <ng-container *ngIf="paymentStep === paymentProcessStep.selectCoinAmount">
      <ng-container [ngSwitch]="coinUsagePurpose">
        <ng-container *ngSwitchCase="'VOTE'">
          <ng-container [ngTemplateOutlet]="voteByCoinsButtons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'TOPUP'">
          <ng-container [ngTemplateOutlet]="topUpButtons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SEND'">
          <ng-container [ngTemplateOutlet]="sendCoinsButtons"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</p-dialog>

<ng-template #voteCoinsRecipientInfo let-data="data">
  <div class="flex flex-col">
    <div class="bg-palette-blue-600 h-10 w-full flex justify-center rounded-t-lg items-center">
      <span *ngIf="getVotingCoin()" class="text-sm text-palette-base-white font-medium">
        {{ 'POLL.NUMBER_VOTING_COINS' | translate: { number: getVotingCoin() } }}
      </span>
    </div>
    <div class="bg-palette-gray-100 rounded-b-lg flex flex-col items-center justify-center h-24 gap-3">
      <img [src]="validUrl(data?.image)" class="w-10 h-10 rounded" (error)="handleImageError($event)" />
      <span class="text-sm text-palette-gray-900 font-medium">{{ data?.name }}</span>
    </div>
  </div>
</ng-template>

<ng-template #voteCoinAmountSelection let-data="data">
  <div
    id="coin-amount-option"
    class="w-[102px] h-11 rounded-lg border flex items-center justify-center gap-1 cursor-pointer"
    [ngClass]="selectedCoinsData === data ? 'bg-palette-blue-50 border-palette-blue-600 ' : 'border-palette-gray-300'"
    (click)="onSelectCoinOption(data)">
    <img src="/assets/icons/coin.svg" class="w-4 h-4" />
    <span class="text-sm text-palette-gray-900 font-medium">{{ data?.amountCoin }}</span>
  </div>
</ng-template>

<ng-template #voteByCoinsButtons>
  <div class="flex gap-2 items-center justify-end">
    <p-button
      (onClick)="visibleChange.emit(false)"
      class="normal-btn"
      [disabled]="isLoading"
      [label]="'COMMON.BUTTON_ACTION.CANCEL' | translate"></p-button>
    <p-button
      *ngIf="!needTopUpCoin"
      (onClick)="visibleConfirm = true"
      class="active-btn"
      [label]="'COMMON.BUTTON_ACTION.VOTE' | translate"
      [icon]="isLoading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      [disabled]="isLoading || !selectedCoinsData"></p-button>
    <p-button
      *ngIf="needTopUpCoin"
      (onClick)="onTopUpCoins()"
      class="active-btn"
      [label]="'POLL.PAY' | translate"
      [icon]="isLoading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      [disabled]="isLoading || !selectedCoinsData"></p-button>
  </div>
</ng-template>

<ng-template #topUpCoinAmountSelection let-data="data">
  <div
    class="w-[170px] h-[184px] rounded-lg border flex flex-col items-center justify-center gap-5 cursor-pointer"
    [ngClass]="selectedCoinsData === data ? 'bg-palette-blue-50 border-palette-blue-600 ' : 'border-palette-gray-300'"
    (click)="onSelectCoinOption(data)">
    <img [src]="'/assets/icons/coin.svg'" class="w-12 h-12" />
    <div class="flex flex-col gap-2 item-center">
      <span class="text-base text-palette-gray-900 font-semibold text-center">{{
        'XU.NUMBER_XU' | translate: { number: data?.amountCoin }
      }}</span>
      <span class="font-normal text-sm text-palette-gray-500 text-center"> {{ data?.price | currencyConversion }}</span>
    </div>
  </div>
</ng-template>

<ng-template #topUpButtons>
  <div class="flex gap-2 items-center justify-end">
    <p-button
      (onClick)="visibleChange.emit(false)"
      class="normal-btn"
      [disabled]="isLoading"
      [label]="'COMMON.BUTTON_ACTION.CANCEL' | translate"></p-button>
    <p-button
      (onClick)="onTopUpCoins()"
      class="active-btn"
      [label]="'COMMON.BUTTON_ACTION.CONFIRM' | translate"
      [icon]="isLoading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      [disabled]="isLoading || !selectedCoinsData"></p-button>
  </div>
</ng-template>

<ng-template #sendCoinsRecipientInfo>
  <div class="flex flex-col gap-2 w-full">
    <span class="font-normal text-sm text-palette-gray-900">{{ 'POLL.SENDING_XU_TO' | translate }}</span>
    <div
      id="receiver-info-section"
      class="flex gap-3 items-center py-3 px-4 w-full h-[72px] bg-cover bg-[url('/assets/icons/coins/receiver-background.png')] rounded-lg">
      <ng-container *ngIf="!showAvatarDefault(); else defaultAvatar">
        <img class="inline-block rounded-full w-12 h-12 mr-3" [src]="getAvatarUrl()" alt="avatar" />
      </ng-container>
      <ng-template #defaultAvatar>
        <p-avatar
          *ngIf="coinRecipientData && coinRecipientData?.page_object"
          styleClass="rounded-full w-12 h-12 mr-3 bg-palette-blue-50 text-palette-blue-600 font-bold text-xs"
          [label]="(coinRecipientData?.page_object?.page_name | abbreviate: 'fanpage') || ''"></p-avatar>
      </ng-template>
      <div class="flex flex-col gap-2">
        <span class="text-base text-palette-gray-900 font-semibold">{{ getReceiverName() }}</span>
        <span *ngIf="!data?.page_id" class="text-xs text-palette-gray-500 font-normal">{{
          'COMMON.DEFAULT_BADGE' | translate
        }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sendCoinsButtons>
  <div class="flex gap-2 items-center justify-end">
    <p-button
      (onClick)="visibleChange.emit(false)"
      class="normal-btn"
      [disabled]="isLoading"
      [label]="'COMMON.BUTTON_ACTION.CANCEL' | translate"></p-button>
    <p-button
      *ngIf="!needTopUpCoin"
      (onClick)="visibleConfirm = true"
      class="active-btn"
      [label]="'COMMON.BUTTON_ACTION.SEND' | translate"
      [icon]="isLoading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      [disabled]="isLoading || !selectedCoinsData"></p-button>
    <p-button
      *ngIf="needTopUpCoin"
      (onClick)="onTopUpCoins()"
      class="active-btn"
      [label]="'POLL.PAY' | translate"
      [icon]="isLoading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      [disabled]="isLoading || !selectedCoinsData"></p-button>
  </div>
</ng-template>

<ng-template #coinNeedToTopUp let-title="title">
  <div *ngIf="needTopUpCoin" id="coins-need-topup-section" class="flex flex-col gap-1">
    <span class="text-palette-gray-500 font-normal text-xs">
      {{ title | translate: { number: coinAmountNeedToTopUp?.amountCoin } }}</span
    >
    <span class="w-full h-10 px-[10px] py-3 rounded-lg bg-palette-gray-100 flex justify-between items-center">
      <span class="text-sm font-semibold text-palette-gray-900">
        {{ 'POLL.NUMBER_OF_XU' | translate: { number: coinAmountNeedToTopUp?.amountCoin } }}</span
      >
      <span class="text-sm font-medium text-palette-blue-600">
        {{ coinAmountNeedToTopUp?.price | currencyConversion }}</span
      >
    </span>
  </div>
</ng-template>

<ng-template #totalCoinsSection>
  <div id="current-coins-section" class="w-full h-11 flex justify-between py-3 items-center rounded-lg">
    <span class="text-sm text-palette-gray-900 font-normal">{{ 'POLL.YOUR_CURRENT_XU' | translate }}</span>
    <div id="coin-container" class="flex items-center gap-1">
      <img src="/assets/icons/coin.svg" class="w-5 h-5" />
      <span class="text-lg font-semibold text-palette-base-black">
        {{ myCoin?.total_balance || 0 | number: '1.0-0' }}</span
      >
    </div>
  </div>
</ng-template>

<p-dialog
  [appendTo]="'body'"
  class="confirm-sending z-10"
  styleClass="z-[1111]"
  [(visible)]="visibleConfirm"
  [modal]="true"
  (onHide)="visibleConfirm = false"
  [dismissableMask]="true">
  <ng-template pTemplate="header">
    {{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}
  </ng-template>
  <div
    *ngIf="coinUsagePurpose === 'VOTE'"
    [innerHTML]="
      'POLL.CONFIRM_VOTE_MESSAGE'
        | translate: { target: coinRecipientData?.name || '', number: selectedCoinsData?.amountCoin || 0 }
    "></div>
  <div
    *ngIf="coinUsagePurpose === 'SEND'"
    [innerHTML]="
      'XU.CONFIRM_SEND_XU_MESSAGE'
        | translate: { target: getReceiverName(), number: selectedCoinsData?.amountCoin || 0 }
    "></div>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
        (click)="visibleConfirm = false"
        [disabled]="isLoading"
        class="text-palette-base-black bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200 w-max"></button>
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
        (click)="coinUsagePurpose === 'VOTE' ? voteByCoin() : onSendCoins()"
        class="text-palette-base-white bg-palette-blue-600 border-palette-blue-300 w-max"></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="isShowNotiPopup"
  class="noti-popup"
  [(visible)]="isShowNotiPopup"
  [modal]="true"
  (onHide)="isShowNotiPopup = false; visibleConfirm = false"
  [dismissableMask]="true">
  <div>{{ reachMaxVoteMess() }}</div>
  <ng-template pTemplate="footer">
    <div class="flex justify-end">
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
        (click)="closeManageCoinsDialog()"
        class="text-palette-base-white bg-palette-blue-600 border-palette-blue-300 w-max"></button>
    </div>
  </ng-template>
</p-dialog>
