<div
  *ngIf="!isLoading"
  (click)="handleClickPost(post)"
  [ngClass]="[
    isSharedPost && !isDisabledPost ? 'mx-4 mt-1 border border-b-0 mb-2 border-palette-gray-200 rounded-md' : '',
    isDisabledPost ? 'max-h-[540px] scroll-bar overscroll-contain overflow-hidden hover:overflow-auto' : '',
    isHidePost ? 'hidden' : ''
  ]">
  <div [ngClass]="isDisabledPost ? 'pointer-events-none' : ''">
    <p-card
      #postCard
      class="p-0"
      [styleClass]="isSharedPost ? 'bg-palette-gray-50' : ''"
      [styleClass]="isPostReview ? 'shadow-none rounded-t-md' : 'shadow-sm rounded-md'">
      <ng-template pTemplate="title">
        <post-header
          [data]="headerPost"
          [isUpdatedPost]="(selectIsUpdatedPost$ | async)!"
          [isPinnedPost]="isPinnedPost"
          [isPostInProfile]="isPostInProfile"
          [isShowActualPoster]="isShowActualPoster"
          [userInfo]="post.user_object"
          [isLoggedUser]="isLoggedUser"
          [dataInit]="post"
          [isSharedPost]="isSharedPost"
          [isPostGroup]="isPostGroup"
          [groupPrivacy]="groupPrivacy"
          [isFanpagePost]="isFanpagePost"
          [fanpageInfo]="fanpageInfo"
          [groupId]="groupId"
          [configType]="configType"
          [isShowConfig]="!isPostReview"
          (postEditInNewsFeed)="handleEditPost($event)"
          (deletePost)="deleteElement($event)"
          (pinPostEmit)="pinPostEmit.emit($event)"
          (unPinEmit)="unPinEmit()"
          (hidePost)="handleHidePost($event)"
          (archivePost)="archiveElement($event)"
          (download_image_or_video)="download_image_or_video.emit()"
          [isShowHideBtn]="isShowHideBtn"
          [isPostSearching]="isPostSearching"
          [isNewsfeed]="isNewsfeed"
          [isShowDetailPost]="isShowView"
          [isWatchScreen]="isWatchScreen"
          [isPostDetail]="isPostDetail"
          [isPostView]="isPostView"
          [isPhotoView]="isPhotoView"
          [isShowCloseButton]="isShowCloseButton"
          (onClickCloseButton)="onClickHeaderCloseButton.emit()"
          [parentPostId]="parentPostId"></post-header>
      </ng-template>
      <ng-template pTemplate="content" class="pt-0">
        <post-content
          [data]="post"
          [isPostView]="isPostView"
          [isAdPreview]="isAdPreview"
          [isAutoPlayAdPreview]="isAutoPlayAdPreview"
          (deletePost)="deleteElement($event)"
          (archivePost)="archiveElement($event)"
          (hidePost)="handleHidePost($event)"
          [isWatchScreen]="isWatchScreen"
          [userInfo]="(userInfo$ | async) || {}"
          [isSharedPost]="isSharedPost"
          [isPostDetail]="isPostDetail"></post-content>
      </ng-template>

      <ng-template *ngIf="!isSharedPost && !isModerate" pTemplate="footer">
        <post-footer
          [isWatchScreen]="isWatchScreen"
          [isShowComment]="isShowComment"
          [isPostView]="isPostView"
          [isPhotoView]="isPhotoView"
          [data]="post.post_info || post"
          [userInfo]="(userInfo$ | async) || {}"
          (shareClicked)="executeShare($event)"
          (switchProfileEvent)="onSwitchProfile($event)"
          [isShowCreateAdsBtn]="isShowCreateAdsBtn"
          (isShowGiftCoin)="showGiftCoin($event)"
          [groupsJoined]="(groupsJoined$ | async)!"
          [myGroup]="(myGroup$ | async)!"
          [isPostDetail]="isPostDetail"
          [isPostViewComment]="isPostViewComment"
          [isPostReview]="isPostReview"
          [showSwitchProfileOption]="showSwitchProfileOption"></post-footer>
      </ng-template>
    </p-card>
  </div>
</div>
<share-dialog
  [sharedPost]="post"
  [isWatchScreen]="isWatchScreen"
  [friendList]="friendList"
  [isPostInProfile]="isPostInProfile"
  [(visible)]="visibleShareToGroupPopup"
  [(visiblePostDialog)]="visibleCreatePostDialog"
  [(shareType)]="shareType"
  [selectedProfile]="selectedProfile"></share-dialog>

<ng-content select="[suggest-friend]"></ng-content>

<ng-content select="[loops-list]"></ng-content>

<ng-content select="[loops-list]"></ng-content>

<p-dialog
  [styleClass]="'w-[400px] report-dialog'"
  [(visible)]="isShowHideMessage"
  [modal]="isShowHideMessage"
  [dismissableMask]="true"
  (onHide)="onHideDialog()"
  header="{{ 'COMMON.REPORT' | translate }}">
  <span class="text-sm text-palette-gray-700">
    {{ 'POST.SUCCESSFUL_MESSAGE.REPORT_POST_SUCCESSFULLY' | translate }}
  </span>
  <ng-template pTemplate="footer">
    <div class="w-full flex gap-3 justify-end">
      <button
        (click)="isShowHideMessage = false"
        class="text-white bg-palette-blue-600 border-palette-blue-600 hover:bg-palette-blue-700 hover:border-palette-blue-700 py-1.5 px-2 rounded-md">
        {{ 'COMMON.BUTTON_ACTION.CLOSE' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<manage-coins-dialog
  *ngIf="showManageCoinDialog"
  [(visible)]="showManageCoinDialog"
  [data]="headerPost"
  [title]="'XU.POPUP.SEND_XU'"
  [coinUsagePurpose]="'SEND'"
  [coinRecipientData]="coinRecipientDataObject()"
  [sendingType]="'POST'"
  (onSendCoinSuccessEvent)="handleUpdateStatusGiftCoin($event)"></manage-coins-dialog>
