<p-dialog
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [style]="{ width: '500px' }"
  [dismissableMask]="true"
  (onHide)="onHide()"
  [styleClass]="'custom-popup-login'">
  <ng-container>
    <div class="w-full min-h-fit items-center justify-center">
      <div class="w-full min-h-fit flex flex-col p-8 justify-center items-center" *ngIf="!moveToVerification">
        <img src="/assets/images/soctrip-branding/soctrip-logo-blue.svg" alt="logo" class="w-16 h-16" />
        <h2 class="text-2xl font-bold mt-3 text-palette-gray-900">{{ 'SIGNIN_SIGNUP.SIGN_IN' | translate }}</h2>
        <div
          class="rounded-lg border border-solid px-3 py-3.5 w-full border-system-error-300 mt-4 flex gap-2 items-center"
          *ngIf="errorMessage">
          <i class="sctr-icon-alert-circle text-system-error-700 font-semibold text-sm"></i>
          <span class="font-semibold text-sm text-system-error-700">{{ errorMessage }}</span>
        </div>
        <form [formGroup]="loginForm" class="w-full">
          <span class="flex flex-col gap-1" *ngIf="signInMethod === 'email'">
            <label htmlFor="login-username-input" class="text-sm text-palette-gray-700 font-medium">{{
              'SIGNIN_SIGNUP.EMAIL' | translate
            }}</label>
            <input
              pInputText
              id="login-email-input"
              placeholder="{{ 'SIGNIN_SIGNUP.EMAIL_PLACEHOLDER' | translate }}"
              formControlName="email"
              class="rounded-xl h-12" />
          </span>
          <span class="flex flex-col mt-2 gap-[2px] relative">
            <label htmlFor="login-password-input" class="text-sm text-palette-gray-700 font-medium">{{
              'SIGNIN_SIGNUP.PASSWORD' | translate
            }}</label>
            <input
              pInputText
              class="text-base tracking-wider rounded-xl text-palette-gray-900"
              id="login-password-input"
              formControlName="password"
              placeholder="••••••••"
              [type]="showPassword ? 'text' : 'password'" />
            <img
              *ngIf="loginForm.get('password')?.value"
              [src]="showPassword ? 'assets/icons/eye-closed.svg' : 'assets/icons/eye.svg'"
              alt="eye icon"
              (click)="onShowPassword()"
              class="absolute cursor-pointer text-xl right-4 top-[34px]" />
          </span>
          <p-button
            label="{{ 'SIGNIN_SIGNUP.FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}"
            class="flex justify-end"
            styleClass="p-button-secondary p-button-secondary p-button-text text-sm font-semibold my-2 py-2 px-3 "
            [style]="{ color: '#1570ef', height: '20px' }"
            (click)="onForgotPassword()"></p-button>
          <div class="flex flex-col gap-5">
            <p-button
              label="{{ 'SIGNIN_SIGNUP.SIGN_IN' | translate }}"
              type="submit"
              styleClass="sign-in-btn w-full bg-palette-blue-600"
              (click)="onLoginSubmit()"></p-button>
            <div class="flex flex-col">
              <div class="flex gap-[18px] justify-between items-center">
                <div class="h-[1px] w-full bg-palette-gray-200"></div>
                <span class="text-center text-sm text-palette-gray-500 min-w-fit">{{
                  ('SIGNIN_SIGNUP.OR_CONTINUE_WITH' | translate).toUpperCase()
                }}</span>
                <div class="h-[1px] w-full bg-palette-gray-200"></div>
              </div>
              <thirdparty-sign-up
                [isPopupLogin]="true"
                (moveToVerificationChanged)="moveToVerification = $event"
                (userInformation)="setUserInfoThirdparty($event)"
                [(credentials)]="credentialsGoogle"
                (loginTypeChange)="loginType = $event"></thirdparty-sign-up>
            </div>
          </div>
          <span class="flex justify-center items-center gap-1 text-sm text-gray-600 h-5"
            >{{ 'SIGNIN_SIGNUP.SIGNIN.DO_NOT_HAVE_AN_ACCOUNT' | translate }}
            <a
              routerLink="/sign-up"
              class="text-sm font-semibold text-branding-primary-700 cursor-pointer no-underline">
              {{ 'SIGNIN_SIGNUP.SIGN_UP' | translate }}</a
            ></span
          >
        </form>
      </div>
      <div class="w-full min-h-fit flex flex-col p-8 justify-center gap-3" *ngIf="moveToVerification">
        <ng-container>
          <div class="mb-4">
            <h2 class="text-2xl font-bold mt-3">{{ 'ACCOUNT_SETTING.SECURITY_SETTING.TWO_FA.TITLE' | translate }}</h2>
          </div>
          <otp-twofa-form
            [loginType]="loginType"
            [credentialsSoctrip]="credentials"
            [credentialsGoogle]="credentialsGoogle"
            (changeMoveToVerification)="moveToVerification = $event"
            (verifyCodeEvent)="handleVerifyCodeEvent($event)"></otp-twofa-form>
        </ng-container>
      </div>
    </div>
  </ng-container>
</p-dialog>
