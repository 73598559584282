<p-dialog
  #elDialog
  styleClass="detailPostView"
  [(visible)]="isShowView"
  [style]="{ width: '100vw', height: '100vh', 'max-height': 'unset' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closePostView()">
  <div class="flex h-screen">
    <div class="relative bg-palette-gray-800" [ngClass]="displayFullImage ? 'w-full' : 'w-3/4 max-md:w-full'">
      <div class="absolute w-full z-[1]">
        <div class="m-6 flex justify-end gap-3">
          <div
            class="flex items-center justify-center h-10 w-10 p-2.5 rounded-full bg-icon cursor-pointer max-md:hidden"
            (click)="handleFullScreen()">
            <i
              class="text-base text-palette-base-white"
              [ngClass]="displayFullImage ? 'sctr-icon-minimize-01' : 'sctr-icon-maximize-01'"></i>
          </div>
          <div
            *ngIf="!isDetailPostView"
            class="flex items-center justify-center h-10 w-10 p-2.5 rounded-full bg-icon cursor-pointer"
            (click)="closePostView()">
            <i class="sctr-icon-x-close text-base text-palette-base-white"></i>
          </div>
        </div>
      </div>
      <div class="relative w-full h-full" *ngIf="mediaList.length">
        <div *ngIf="mediaList.length !== 1" class="absolute top-2/4 w-full z-[1]">
          <div class="flex justify-between px-4">
            <div
              (click)="changeImage(actionType.previous)"
              class="flex items-center h-10 w-10 p-2.5 rounded-full bg-palette-base-white cursor-pointer">
              <i class="sctr-icon-chevron-left text-base"></i>
            </div>
            <div
              (click)="changeImage(actionType.next)"
              class="flex items-center justify-center h-10 w-10 p-2.5 rounded-full bg-palette-base-white cursor-pointer">
              <i class="sctr-icon-chevron-right text-base"></i>
            </div>
          </div>
        </div>
        <div class="w-full h-full px-6">
          <img
            *ngIf="mediaList[activeIndex]?.type === MEDIA_TYPE.image"
            [src]="validUrl(mediaList[activeIndex].original)"
            class="bg-no-repeat bg-center w-full h-full object-center object-contain seo-image"
            defaultImage />
          <ng-template
            custom-video-directive
            *ngIf="mediaList[activeIndex].type === MEDIA_TYPE.video"
            [item]="mediaList[activeIndex]"
            [isPostView]="isShowView"></ng-template>
        </div>
      </div>
    </div>
    <div
      class="max-md:hidden min-w-[496px] overflow-auto"
      [ngClass]="{
        'hidden': displayFullImage,
        'w-1/4': !displayFullImage
      }">
      <div *ngIf="post" class="h-full">
        <post
          *ngIf="!isLoading"
          id="postView"
          [isPostGroup]="isPostGroup"
          [isPostDetail]="true"
          [isPostView]="true"
          [isPhotoView]="true"
          class="mt-2"
          [post]="post"
          [isShowView]="isShowView"
          [parentPostId]="parentPostId"
          [configType]="
            isComment
              ? DROPDOWN_POST.download
              : post.user_object.id === (userInfo$ | async).id
              ? DROPDOWN_POST.timeline
              : DROPDOWN_POST.default
          "
          [isPostViewComment]="isComment"
          (deletePost)="onDeletePost($event)"
          (hidePostEvent)="closePostView(true)"
          (archivePost)="confirmArchive($event)"
          (download_image_or_video)="handleDownload()">
        </post>
        <div class="p-4" *ngIf="isLoading">
          <loading-skeleton></loading-skeleton>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
