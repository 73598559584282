const SECTION = 'ERROR.AUTH';

export const USER_ERROR_MESSAGE = new Map<number, string>([
  [46027, `${SECTION}.SET_SAME_OLD_PASSWORD`],
  [46025, `${SECTION}.INVALID_INVITE_CODE`],
  [46017, `${SECTION}.INVALID_USER_INFO`],
  [46015, `${SECTION}.INVALID_USER_INFO`],
  [46151, `${SECTION}.INVALID_CURRENT_PASSWORD`],
  [69001, `${SECTION}.INCORRECT_USERNAME_PASSWORD`],
  [69008, `${SECTION}.LOCKOUT_5_MINUTES`],
  [69007, `${SECTION}.LOCKOUT_1_HOURS`],
  [69006, `${SECTION}.BLOCK_ACCOUNT`],
  [46048, `${SECTION}.SAME_OLD_PASSWORD`],
  [46082, `${SECTION}.OTP_IS_INCORRECT`],
  [60013, `${SECTION}.UNSUPPORTED_DOMAIN`],
]);
