import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { LoginService } from '@app/core/services/login.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UserService } from '@app/core/services/user.service';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { USER_ERROR_MESSAGE } from '@app/lib/api/user/error.user';
import { SendCode, VerifyCode } from '@app/modules/sign-up/models/sign-up.model';
import { OTP_EXPIRATION_TIME } from '@app/shared/constant';
import { ErrorPipe } from '@app/shared/pipes/error.pipe';
import { debounceTime, filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'otp-form',
  templateUrl: './otp-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./otp-form.component.scss']
})
export class OtpFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() isSignUpAccount = true;
  @Output() verifyCodeEvent = new EventEmitter();

  otpForm: FormGroup;
  errorMessage = '';
  countdown = OTP_EXPIRATION_TIME;
  translateCommonResult: any;
  otpDigits: number[] = [1, 2, 3, 4, 5, 6];
  deviceInfo: AuthAPI.DeviceInfo;

  constructor(
    private fb: FormBuilder,
    private readonly userService: UserService,
    public readonly loginService: LoginService,
    private translateService: TranslationService,
    private readonly authService: AuthService,
    private errorPipe: ErrorPipe
  ) {}

  ngOnInit(): void {
    this.createOtpForm();
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
    this.authService.getDeviceInfo().subscribe(data => {
      this.deviceInfo = {
        ...data,
        device_type: 'W'
      };
    });
    this.otpForm.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
        filter(() => this.otpForm.valid)
      )
      .subscribe(() => {
        this.onVerifyCode();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createOtpForm(): void {
    const formControls: any = {};
    for (let digit of this.otpDigits) {
      formControls[`digit${digit}`] = ['', Validators.required];
    }
    this.otpForm = this.fb.group(formControls);
    this.startCountDown();
  }

  startCountDown(): void {
    const duration = OTP_EXPIRATION_TIME;
    this.loginService.startCountDown(duration).subscribe(value => (this.countdown = value));
  }

  onInputChange(currentInput: number): void {
    const inputElement = document.getElementById(`verification-code-input-${currentInput}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.addEventListener('keydown', event => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          inputElement.value = '';
          this.otpForm.get(`digit${currentInput}`)?.setValue('');
          const previousInput = document.getElementById(`verification-code-input-${currentInput - 1}`);
          if (previousInput) {
            previousInput.focus();
          }
          event.preventDefault();
        } else {
          const nextInput = document.getElementById(`verification-code-input-${currentInput + 1}`);
          if (this.otpForm.get(`digit${currentInput}`)?.value && nextInput) {
            nextInput.focus();
          }
        }
      });
      inputElement.focus();
    }
  }

  onResendCode(): void {
    const sendCodedData: SendCode = {
      phone_or_email: this.userService.authData.phone_or_email,
      is_check_phone_or_email: this.isSignUpAccount,
      area_code: this.userService.authData.area_code
    };
    this.userService.sendCode(sendCodedData).subscribe({
      next: response => {
        if (!response.success) {
          this.startCountDown();
          this.errorMessage = '';
        } else {
          this.errorPipe
            .transform({
              error: response?.error,
              errorMap: USER_ERROR_MESSAGE,
              defaultMessage: 'ERROR.AUTH.FAILED_TO_RESEND_CODE'
            })
            .then((errorMessage: string) => (this.errorMessage = errorMessage));
        }
      },
      error: () => {
        this.errorMessage = this.translateService.getTranslation('ERROR.AUTH.FAILED_TO_RESEND_CODE');
      }
    });
  }

  shouldDisableInputCode(currentInput: number): boolean {
    if (currentInput === 1) {
      return !!this.otpForm.get('digit1')?.value;
    }
    return !this.otpForm.get(`digit${currentInput - 1}`)?.value || this.otpForm.get(`digit${currentInput}`)?.value;
  }

  onVerifyCode(): void {
    const result = Object.values(this.otpForm.value).join('');
    const verifyCode: VerifyCode = {
      phone_or_email: this.userService.authData.phone_or_email,
      area_code: this.userService.authData.area_code,
      get_code_verification: result,
      ...this.deviceInfo
    };
    this.userService.verifyCode(verifyCode).subscribe({
      next: response => {
        if (response.success) {
          this.errorMessage = '';
          this.userService.authData.token = response.data.token;
          this.verifyCodeEvent.emit(response);
        } else {
          const firstInputElement = document.getElementById('verification-code-input-1');
          this.otpDigits.forEach(index => this.otpForm.get(`digit${index}`)?.setValue(''));
          firstInputElement?.focus();
          this.verifyCodeEvent.emit(response);
          this.errorPipe
            .transform({
              error: response?.error,
              errorMap: USER_ERROR_MESSAGE,
              defaultMessage: 'ERROR.AUTH.FAILED_TO_VERIFY_CODE'
            })
            .then((errorMessage: string) => {
              this.errorMessage = errorMessage;
            });
        }
      },
      error: () => {
        this.errorMessage = this.translateService.getTranslation('ERROR.AUTH.FAILED_TO_VERIFY_CODE');
        this.createOtpForm();
      }
    });
  }
}
